// TODO remove spec (use info)

/**
 * A Collection represents a subsection of a Repo defined by collection ID
 *
 * - subset is identified by a collection id (currently property `@dbid`)
 * - collections have policy and permissions controlling access & update
 * - collections allow creating/managing views (live subsets)
 * - supports the datasource protocol for compatibility with Views
 * - collections have membership (a list of members), each with roles and other metadata
 *
 * @class Collection
 * @property {Repo} repo - the Repo this is tied to
 * @returns {Object}
 */
export default class Collection {
  /**
   * Setup to access a collection.
   * Must call open() to actually open/init the collection.
   * Must call close() when done to release resources.
   *
   * @param {Repo} repo Repo to use (must be open)
   * @param {String} id CollectionID to access, or the special "$den" for the repo user's den
   * @memberof Collection
   */

  constructor (repo, collectionID) {
    this.repo = repo
    if (collectionID === '$den') {
      this.policy = true
      this.isDen = true
      this.collectionID = Collection.denCollectionIDFor(repo.userID)
    } else {
      this.isDen = false
      this.collectionID = collectionID
    }
    this.isOpen = false
  }

  static denCollectionIDFor (userID) {
    let denID = 'userdb-'
    for (let i = 0; i < userID.length; i++) {
      denID += userID.charCodeAt(i).toString(16)
    }
    return denID
  }

  /**
   * Open the collection
   *
   * Always opens a "local copy" of the collection, starting sync to backfill
   * the collection.   In order to verify permissions, if the local collection
   * doesn't have a copy of the collection object, then it does an http request
   * directly of the server to decide if the lack of collection object
   * permissions problem or a missing collection object.
   *
   * Promise Rejections:
   *
   * - 403 if online, not sync'd, and the user does not have permissions to sync this collection.
   * - 404 exception if the collection does not exist.
   * - "Offline" if collection not recently sync'd and offline
   *
   * WARNING: assumes the collectioninfo and membership info for the
   * current user is already sync'd to get permiissions setup properly.
   *
   * @memberof Collection
   * @returns {Promise(Collection)}
   * @async
   * @throws Error if collection is not trustworthy
   *
   */

  async open () {
    if (this.isOpen) throw new Error('open called on open collection')
    await this.repo.sync.request(this, ['.cl/' + this.collectionID]) // collection items
    if (!this.isDen) await this._openRegularCollection()

    this.deps = new Set()
    this.ids = new Set()
    this.repo.deps.add(this)
    this.isOpen = true
    return this
  }

  /**
   * opens a collection that is NOT a special (eg den) collection
   */

  async _openRegularCollection () {
    const cdocID = `.collection.${this.collectionID}`

    // should already have access to collectioninfo loaded by the repo
    this.info = await this.repo.db.get(cdocID)
    this.policy = this.policyMapFromInfo(this.info)
    console.log('resulting policy: ', this.policy)

    // set `membership` to membership doc if current user has membership in this collection
    if (!this.repo.userID) return

    const result = await this.repo.db.find({
      selector: {
        '@dbid': this.collectionID,
        type: 'membership',
        name: this.repo.userID
      }
    })

    if (result.warning) console.warn(result.warning)
    if (!result.docs || result.docs.length === 0) {
      console.log('no membership found')
      return
    }
    if (result.docs.length > 1) {
      console.error('found', result.docs.length, 'memberships (duplicated memberships)')
    }
    this.membership = result.docs[0]
    console.log('found membership', this.membership)
  }

  async find (finder) {
    const found = await this.repo.find(finder)
    if (found.warning) {
      console.warn(found.warning, finder)
    }
    found.docs.forEach(doc => { this.ids.add(doc._id) })
    return found
  }

  /**
   * Processes changes from the Repo.
   *
   * If the item is part of this collection, remember its id and inform
   * If the item was here but is being deleted, inform
   * Otherwise do nothing
   *
   * @param {*} change
   * @memberof Collection
   */

  onChange (change) {
    // console.debug("change", change)
    if (change.doc['@dbid'] === this.collectionID) {
      this.ids.add(change.id)
    } else if (change.deleted || this.ids.has(change.id)) {
    // console.debug('Collection onChange delete', change)
      this.ids.delete(change.id)
    } else {
      return
    }
    for (const dep of this.deps) {
      dep.onChange(change)
    }
  }

  policyMapFromInfo (info) {
    const defaultPolicy = { access: 'member', comment: 'member', update: 'admin' }
    const policyMaps = {
      public: { access: true, comment: 'known', update: 'admin' },
      private: { access: 'member', comment: 'member', update: 'admin' }
    }

    if (info.policy && typeof info.policy === 'object') {
      return info.policy
    }
    if (info.policy && typeof info.policy === 'string') {
      return policyMaps[info.policy] || defaultPolicy
    }

    const policy = info.private === false ? 'public' : 'private'
    return policyMaps[policy]
  }

  /**
   * Close the collection, and terminating any synchronization & changefeeds.
   * closing all views.
   *
   * @memberof Collection
   * @async
   */

  async close () {
    console.debug('collection close', this.collectionID)
    if (!this.isOpen) throw new Error('close() called on closed collection')
    this.isOpen = false
    this.repo.deps.delete(this)
    for (const dep of this.deps) await dep.close()
    delete this.deps
    delete this.ids
    await this.repo.sync.release(this)
  }

  /**
   * Return document ID for this colleciton
   *
   * @static
   * @param {*} collectionID
   * @returns
   * @memberof Collection
   */
  static docIDFor (collectionID) {
    return '.collection.' + collectionID
  }

  static async create (id, params) {
    enforceABC(id)
    if (!this.repo.userID) throw new Error('Must be authenticated')
    throw new Error('not yet implemented')
  }

  static async update (id, params) {
    // create actually does update also for now
    return this.create(id, params)
  }

  static async delete (repo, id) {
    enforceABC(id)
    const db = repo.db
    if (!db) throw new Error('db must be valid and open')
    if (!repo.userID) throw new Error('must be authenticated')
    const result = await repo.db.find({
      selector: { '@dbid': id }
    })
    if (result.warning) {
      console.warn(result.warning)
    }
    const docs = result.docs
    console.log('deleting', docs.length, 'items')
    let i
    for (i in docs) {
      docs[i]._deleted = true
    }
    await db.bulkDocs(docs)
    console.log('deleted collection', id)
  }

  get selector () {
    return { '@dbid': this.collectionID }
  }

  /**
   * Get an item from the collection
   *
   * @param {*} itemID
   * @returns
   * @memberof Collection
   */

  async get (itemID, ...options) {
    return this.repo.db.get(itemID, ...options)
  }

  /**
  * an item to a collection, setting its _id if neccesary, and updating
  * the timestamps and revision.
  *
  * - If the item does not exist, it is added to the collection
  * - If the item exists with the same _id, it is updated
  * - If the item does not already have _id, one is assigned automatically
  * - The "@dbid" attribute of the item is set to this collection (which will remove it from any other one)
  * - Timestamps for created/updated are set
  *
  * @param {Object} item
  * @typedef {{id: string, rev: string, ok: true}} ChangeResult
  * @returns {Promise<Confirmation>} OK and New Revision
  */

  async put (item) {
    if (!this.authorizesPut(item)) {
      throw new Error('put not authorized for item', item)
    }
    item['@dbid'] = this.collectionID
    return this.repo.put(item)
  }

  authorizesPut (item) {
    const currentUser = this.repo.userID
    const existing = (!!item._id) && (!!item._rev)
    if (!currentUser) return false
    if (existing && item.createdBy === currentUser) return true
    if (this.authorizes('update')) return true
    if (item.type === 'comment' && (!existing) && this.authorizes('comment', item)) return true
    return false
  }

  /**
   * A very thin layer on top of db.put() that just sets the dbid
   * @param {*} item
   */

  async putRaw (item) {
    item['@dbid'] = this.collectionID
    return this.repo.putRaw(item)
  }

  /**
   * Remove an item from the collection.
   * IMPORTANT:  This does not update the passed item.
   * Also - this is deprecated in favor of confirmed-recursive delete like repo.removeItem()
   * @param {*} item
   * @memberof Collection
   * @async
   * @deprecated
   *
   */
  async remove (item) {
    throw new Error('API Deprecated')
  }

  /**
   * Attach something to an Item in this collection, modifying the item,
   * and updating its _rev.   Returns a promise that resolves to a
   * ChangeResult.
   *
   * @memberof Collection
   *
   * @param {*} item
   * @param {*} attachmentId  eg 'foo.jpg'
   * @param {*} attachment    Either Blob, Buffer, or Base64
   * @param {*} contentType   eg 'text/plain' or 'image/jpeg'
   *
   * @returns {Promise<Revision>} New Revision & New Revision
   */

  async putAttachment (item, attachmentId, attachment, contentType) {
    this.enforce('update') // REVIEW deprecate
    const result = await this.repo.db.putAttachment(
      item._id,
      attachmentId,
      item._rev,
      attachment,
      contentType
    )

    // get the item again so we can update the attachment on the original
    const updatedItem = await this.repo.db.get(item._id)
    const newAttachment = updatedItem._attachments[attachmentId]
    if (newAttachment) {
      if (!item._attachments) item._attachments = {}
      // REVIEW should this be Vue.set?
      item._attachments[attachmentId] = newAttachment
    }
    item._rev = result.rev
    return result.rev
  }

  /**
   * Get an attachment from an item or item id
   *
   * @param {string|object} itemOrId  Either an Item or and Item ID
   * @param {*} attachmentId eg "test.txt" or "thumbnail.img"
   * @returns {Promise<Blob|Buffer>} The attachment as Blob or Buffer
   * @memberof Collection
   */
  async getAttachment (itemOrId, attachmentId) {
    const id = (typeof (itemOrId) === 'object' ? itemOrId._id : itemOrId)
    return this.repo.db.getAttachment(id, attachmentId)
  }

  /**
   * Remove the attachment from the item, modifying the item, and updating
   * its revision (_rev).   The attachment is removed from the item.
   *
   * @param {*} item
   * @param {*} attachmentId
   * @memberof Collection
   * @returns {Promise<Revision} OK and new Item Revision
   */
  async removeAttachment (item, attachmentId) {
    const result = await this.repo.db.removeAttachment(
      item._id,
      attachmentId,
      item._rev
    )
    // REVIEW should following be Vue.delete?
    delete item._attachments[attachmentId]
    item._rev = result.rev
    return result.rev
  }

  /**
   * Returns true if the given action is authorized on this collection.
   * If no item is specified, permissions are computed on collection not item.
   * Note that item-based permissions are not yet implemented so item is ignored.
   *
   * Note that a 'true' policy means all actions are permitted
   *
   * @param {*} action
   * @param {*} item An item on which the action is to be applied
   * @memberof Collection
   *
   */
  authorizes (action, item = null) {
    if (this.policy === true) return true
    if (!this.policy) return false
    if (item) console.warn('item-based auth not yet implemented')

    // always authorize an action if the current userID is the repo owner
    if (this.repo.userID === this.info.account) return true

    // use policy to determine what role requiredfor this action
    const requiredRole = this.policy[action]

    // true and false roles simply always or never allow
    if (requiredRole === true) return true
    if (requiredRole === false) return false

    // known role means user has a signed-in identity
    if (requiredRole === 'known') return !!this.repo.userID

    // all that is left are roles based on memberships, so deny if no membership
    if (!this.membership) return false

    // allow or deny based on membership and membership role
    if (requiredRole === 'member') return true

    // see if there is role list
    const roles = this.membership.roles
    if (!Array.isArray(roles)) return false
    return this.membership.roles.includes(requiredRole)
  }

  /**
   * Checks to make sure action is authorized and throws error otherwise
   * REVIEW deprecate in favor of authorizesPut(item), etc
   * @param {string} action
   */
  enforce (action) {
    if (!this.authorizes(action)) {
      throw new Error(`${action} not authorized`)
    }
  }
}

function enforceABC (id) {
  if (id.search('_') === -1) throw new Error('Can only create or delete account-based collections')
}
