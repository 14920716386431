var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-item-container"},[_c('div',{staticClass:"list-item-head py-1 font-normal",class:[{ over: _vm.over, 'selected-collection': _vm.selectedParent }]},[_c('drop',{key:'dropview' + _vm.item._rev,staticClass:"drop list",on:{"dragover":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDragover.apply(void 0, [ _vm.item ].concat( argsArray ))},"dragleave":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleLeave.apply(void 0, [ _vm.item ].concat( argsArray ))},"drop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDrop.apply(void 0, [ _vm.item ].concat( argsArray ))}}},[_c('div',{staticClass:"mx-5 d-flex"},[_c('router-link',{staticClass:"router-link toggle item-title div-left",attrs:{"exact":"","to":{
            name: 'list',
            params: {
              account: _vm.utils.splitdbid(_vm.item['@dbid'])[0],
              collectionID: _vm.utils.splitdbid(_vm.item['@dbid'])[1],
            },
          }}},[_c('span',{staticClass:"font-normal",class:{ 'primary--text': _vm.selectedCollection },on:{"click":function($event){return _vm.switchItem(_vm.item)}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.status == 'bookmark'),expression:"item.status == 'bookmark'"}],attrs:{"small":"","color":"primary"}},[_vm._v("mdi-bookmark")]),_vm._v(_vm._s(_vm.item.title ? _vm.item.title : _vm.item["@dbid"]))],1)]),(_vm.addnew)?_c('span',{staticClass:"toggle div-right addnew",on:{"click":function($event){return _vm.addNewView(_vm.item)}}},[_c('v-btn',{attrs:{"icon":"","x-small":"","title":"Add new collection"}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)],1):_vm._e(),_c('span',{staticClass:"toggle div-right",on:{"click":function($event){_vm.visible = !_vm.visible;
            _vm.switchParentItem(_vm.item);}}},[_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.toggletText[_vm.visible * 1]))])],1)],1)],1)])],1),(_vm.visible)?_c('div',{staticClass:"list-item-body"},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }