<template>
  <div class="d-inline mr-1" :title="formatType">
    <v-icon small>${{ formatType }}</v-icon>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    type: {
      type: String,
      default: 'unknown'
    }
  },
  components: {},
  created () {},
  mounted () {},
  methods: {
    // go to plugins/vuetify.js set icon as you variable name
  },
  computed: {
    formatType () {
      return this.type.replace('$', '')
    }
  },
  watch: {}
}
</script>
<style scoped>
</style>
