<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" style="width:100%">
        <slot> </slot>
      </span>
    </template>
    <span>Title: {{ item.title }}</span
    ><br />
    <span v-show="item.type">Type: {{ item.type }}</span
    ><br />
    <span v-show="item.createdBy">Author: {{ item.createdBy }}</span>
  </v-tooltip>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    item: Object
  },
  components: {},
  created () {},
  mounted () {},
  methods: {},
  computed: {},
  watch: {}
}
</script>
<style scoped>
</style>
