/**
 * Creates a dynamically created/updated "view" on a DataSource.
 * For now, supports Repo and Collection datasources.
 *
 *  + views manage a reactive list of items, which dynamically update in response
 *    to changes in the datasource.
 *
 *  + views support ordered lists of items (now view.items)
 *    + the ordering can be automatic (ordered by time.updated for instance)
 *    + the ordering can be manual (set by a drag/drop UI for instance)
 *
 * The viewspec can be of the following types:
 *
 * 1. A javascript object, in which case the object is taken as the view
 *    specificaiton.
 *
 * 2. A string, in which case the string is considered the ID of the view
 *    spec to load.   If the string matches a set of stock views, then the
 *    associated stock view spec is loaded, otherwise the datasource is searched
 *    for a view specificaiton that matches that string.
 *
 * @class View
 *
 * Properties
 *
 * view.items         # list of all items in the view, in order
 * view.itemMap       # unordered items indexed by key
 *
 *
 * Views follow mango query syntax (see doc/lists.md)
 * Views automatically update when items change
 */

import { matchesSelector } from 'pouchdb-selector-core'
import Vue from 'vue'
import stockViews from './stockviews.js'

export default class View {
  /**
     * Creates a new instance of View on the specified datasource
     *
     * @param {Collection} collection
     * @memberof View
     * @constructor
     */

  constructor (dataSource) {
    this.items = []
    this.itemMap = {}
    this.dataSource = dataSource
    if (!dataSource.isOpen) {
      console.error('View constructor called with closed dataSource', dataSource)
      throw new Error('View constructed on closed dataSource')
    }
  }

  /**
   * Return the list of stock views
   *
   * @readonly
   * @static
   * @memberof View
   */

  static get stockViews () { return stockViews }

  /**
     * (re-) initialize the view with a new viewspec  This will flush and
     * reload the View entirely, and query all items for the View, tracking
     * changes moving forward.
     *
     * If filter is specified, it is a selector which is used to further restrict
     * the items in the view.
     *
     * @async
     * @param {*} vsid ViewSpec and/or ViewSpec ID
     * @param {*} filter Additional selectors to be used as a filter
     * @memberof View
     */

  async init (vsid, filter) {
    return this.open(vsid, filter)
  }

  async open (vsid, filter) {
    // console.debug("view open", vsid, filter, this.dataSource)
    if (!this.dataSource) throw new Error('View opened on null dataSource', vsid, this.dataSource)
    if (!this.dataSource.isOpen) throw new Error('view opened on closed datasource', vsid, this.dataSource)
    if (this.isOpen) throw new Error('open() called on open view')
    if (vsid === undefined) throw new Error('open() called without vsid')
    await this._initViewSpec(vsid)
    if (!this.dataSource.isOpen) {
      console.error('view open - failed open due to collection closing during view open', vsid, this.dataSource)
      throw new Error('view open - datasource closed unexpectedly during view init - abandoning view')
    }
    this.filter = filter
    this.selector = this._selector()
    //    console.debug('view open ', vsid, filter)
    //    console.debug('view dataSource', this.dataSource)
    if (!this.dataSource.deps) {
      console.error('View open called with null datasource.deps', vsid, this.dataSource)
      throw new Error('bad datasource deps')
    }
    this.dataSource.deps.add(this)
    await this._initItemState()
    this.isOpen = true
    return this
  }

  /**
  * Close a view, releasing resoruces
  *
  * The close() method must be called when the view is no longer needed,
  * and will dispose of all event handlers and remove closure references.
  *
  * @memberof View
  * @async
  */

  async close () {
    if (!this.isOpen) {
      console.warn('called close() on view already closed')
      return this
    }
    //    console.log('closing view')
    if (!this.dataSource) throw new Error(`View close() called with null datasource`) // DEBUG
    if (!this.dataSource.deps) throw new Error(`View close() called with null datasource deps!!!`) // DEBUG
    this.dataSource.deps.delete(this)
    this.isOpen = false
    return this
  }

  /**
   * For compatibility with old collections library
   *
   * @readonly
   * @memberof View
   */

  get collection () { return this.dataSource }

  /**
   * Called by datasource with a change that _might_ impact the view
   *
   * @param {*} change
   * @memberof View
   */
  onChange (change) {
    if (this.vs._debug) {
      console.log('onChange', this.vs._debug, change)
    }

    // process changes to orderings if we're in an orderable list
    if ((change.id === this.orderingsId) && this.isOrderable) {
      //      console.debug('ordering changed for', change.id)
      if (change.deleted) {
        this.orderings = null
      } else {
        this.orderings = change.doc.orderings
        this._refreshItemsInOrder()
      }
    }

    // if it's our viewspec that changed, then set the new viewspec doc.
    // REVIEW BUGBUG doesn't look like it would force reaload of view
    if (change.id === this.vsid) {
      if (change.deleted) {
        Vue.set(this, 'vs', null)
        // console.error('active viewspec deleted!', this)
      } else {
        Vue.set(this, 'vs', change.doc)
      }
    }

    if (change.deleted || (this.selector && !matchesSelector(change.doc, this.selector))) {
      this._removeByID(change.id)
    } else {
      this._addOrReplace(change.doc)
    }
  }

  /**
   * Update the given document in the view or add it
   *
   * @param {*} doc
   * @memberof View
   * @private
   */
  _addOrReplace (doc) {
    const oldDoc = this.itemMap[doc._id]
    Vue.set(this.itemMap, doc._id, doc)
    if (oldDoc) {
      Vue.set(this.items, this.items.indexOf(oldDoc), doc)
    } else if (this.isOrderable) {
      this._refreshItemsInOrder()
    } else {
      this.items.push(doc)
    }
  }

  /**
   * Remove a document with the given ID from the view
   *
   * @param {*} id
   * @memberof View
   * @private
   */
  _removeByID (id) {
    const item = this.itemMap[id]
    if (item) {
      Vue.delete(this.itemMap, id)
      const index = this.items.indexOf(item)
      if (index > -1) Vue.delete(this.items, index)
    }
  }

  /**
     * Returns a new Item (not yet saved) appropriate for this view.
     * The document for the item is computed from the view template.
     *
     * @@returns Item
     */

  newItem () {
    return this._interpolate({ ...this.vs.of, ...this.vs.insert })
  }

  /**
   * "put" an item "in" a view
   *
   * Note that unlike <source>.put(), calling <view>.put() assigns the
   * properties from the view's default properties (newItem()) to any undefined
   * atttributes.   It will not override any defined properties.
   *
   * REVIEW: this should be maybe deprecated in favor of app calling view.newItem()
   *         to initialize object explicitly, or merged with put2(...)
   *
   * Side Effects:  <item> will have _id, _rev
   *
   * @param {*} item
   * @memberof View
   * @async
   */

  async put (item, options = {}) {
    const merge = this.newItem()
    Object.keys(merge).forEach(key => {
      if (item[key] === undefined) {
        item[key] = merge[key]
      }
    })
    const result = await this.dataSource.put(item, options)
    for (let i = 0; i < 9; i++) {
      const imap = this.itemMap[result.id]
      if (imap && (imap._rev === result.rev)) return result
      await new Promise((resolve) => setTimeout(resolve, 5))
    }
    console.error('view put() timed out waiting for change notification')
    return result
  }

  /**
   * "drops" an item "in" this view, but unlike put(), overrides attributes
   * already defined intended to be used during drag/drop
   *
   * Side Effects:  <item> will have _id, _rev
   *
   * @param {*} item
   * @memberof View
   * @async
   */

  async drop (item, options = {}) {
    return this.dataSource.put({ ...item, ...this.newItem() })
  }

  // /** DEPRECATED
  //  * COPY or MOVE an item from one colleciton to another.
  //  *
  //  * This copies an item in a special way to preserve its revision history, but
  //  * requires the source dataSource so the history can be retrieved.
  //  *
  //  * Writes a tombstone for the item in the original dataSource unless copy: true i
  //  * is specified in options.
  //  *
  //  * REVIEW: should use better strategy for putting revisions than putting twice
  //  * REVIEW: should enforce priveleges?
  //  * REVIEW: this doesn't update timestamps, should it?
  //  *
  //  * some sample code to better handle revisions?
  //  *  function parseRevisionInfo(rev) {
  //  *    if (!/^\d+-/.test(rev)) {
  //  *      return createError(INVALID_REV);
  //  *    }
  //  *    var idx = rev.indexOf('-');
  //  *    var left = rev.substring(0, idx);
  //  *    var right = rev.substring(idx + 1);
  //  *    return {
  //  *      prefix: parseInt(left, 10),
  //  *      id: right
  //  *    };
  //  *  }
  //  *
  //  * @param {String} id
  //  * @param {dataSource} sourceCollection
  //  * @param {Object} optional only current valid option is { copy: true }
  //  * @memberof View
  //  */
  // async transfer (id, sourceCollection, options = {}) {
  //   const sourceWithRevs = await sourceCollection.repo.db.get(id, { revs: true })
  //   const newDoc = { ...sourceWithRevs, ...this.newItem() }
  //   console.log('transfer', sourceWithRevs, 'as', newDoc)
  //   try {
  //     const result = await this.dataSource.repo.db.bulkDocs([sourceWithRevs], { new_edits: false })
  //     console.log('result of transfer', result)
  //     delete newDoc._revisions
  //     console.log('reput', (await this.dataSource.putRaw(newDoc)))
  //     if (!options.copy) {
  //       await sourceCollection.repo.db.put({ ...sourceWithRevs, ...{ _deleted: true } })
  //     }
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  /**
   * Remove an item from the view.   Really just removes it from the collection,
   * for now.
   *
   * NOTE:  USE confirming tree removal instead -- repo.delete(...)
   *
   * @param {*} item
   * @memberof View
   * @deprecated Doesn't handle containment, and deletes items from perspectives
  *
   */

  remove (item) {
    throw new Error('API Deprecated')
    // this.dataSource.remove(item)
  }

  /**
   * Manually sets an order for an item
   *
   * @param {*} itemOrId an Item or Item ID to move to a new index
   * @param {*} newIndex The new 0-based index of the item
   * @memberof View
   */

  async setOrder (itemOrId, newIndex) {
    if (!this.isOrderable) throw new Error('Not orderable')
    const id = (typeof itemOrId === 'object' ? itemOrId._id : itemOrId)
    const ids = this.items.map(i => i._id)
    const oldIndex = ids.indexOf(id)
    if ((oldIndex === -1) || (!this.itemMap[id])) throw new Error('Item not in view')
    ids.splice(oldIndex, 1)
    ids.splice(newIndex, 0, id)
    await this._setArrayOrder(ids)
  }

  /**
   * Given an array of object IDs, create an orderings object that represents those IDs for this view.
   *
   * @param {*} ids
   * @memberof View
   */

  async _setArrayOrder (ids) {
    const db = this.dataSource.repo.db
    let doc = {}
    // get the orderings doc if it exists or prep one if not
    try {
      doc = await db.get(this.orderingsId)
      // console.debug(`updating orderings for ${this.orderableKey}`)
    } catch {
      // console.debug(`creating first-time orderings for ${this.orderableKey}`)
      doc = { _id: this.orderingsId }
    }
    // update and write the updated orderings doc
    doc.orderings = ids
    return this.dataSource.putRaw(doc)
  }

  get orderable () { return this.isOrderable } // DEPRECATED
  get isOrderable () { return !!this.vs.orderable }

  /**
   * Returns the key used for ordering, if the view is orderable,
   *
   * @readonly
   * @memberof View
   */

  get orderableKey () {
    return (this.vs.orderable === true) ? this.vsid : this.vs.orderable
  }

  get orderingsId () {
    return '@orderings:' + this.orderableKey
  }

  // setup the viewspec to be assuming this view has a valid vsid, load the proper viewspec
  // if it comes from the db, then watch it for changes
  async _initViewSpec (vsid) {
    this.vsid = vsid
    if (typeof vsid === 'object') {
      this.vs = vsid
    } else if (stockViews[this.vsid]) {
      this.vs = stockViews[this.vsid]
    } else {
      this.vs = await this.dataSource.repo.db.get(vsid)
    }

    // load orderings if possible, and init orderings change handler
    if (this.isOrderable) {
      try {
        const oDoc = await this.dataSource.repo.db.get(this.orderingsId)
        this.orderings = oDoc.orderings
        // console.log("orderings found and loaded", this, oDoc)
      } catch {
        this.orderings = null
        // console.log("no orderings found", this)
      }
      //      this._initOrderableChangeHandler(this.orderingsId)
    }
  }

  /**
   * Set the order of `items` to reflect `this.orderings`
   *
   * @memberof View
   */

  _refreshItemsInOrder () {
    const processed = {}
    let i = 0
    const itemKeys = Object.keys(this.itemMap)
    const nItems = itemKeys.length

    if (!this.isOrderable) throw new Error('not orderable') // DEBUG only call me on orderable views

    // DEBUG
    // console.log(`refreshItemsInOrder key=${this.orderableKey}`, this)

    if (this.orderings) {
      this.orderings.forEach(id => {
        if (this.itemMap[id]) {
          this._setIfDifferent(i++, id)
          processed[id] = true
        }
      })
    }

    itemKeys.forEach(id => {
      if (!processed[id]) {
        this._setIfDifferent(i++, id)
      }
    })

    // DEBUG
    if (i !== nItems) {
      console.error('algorithm error - counts dont match!')
      console.error('itemMap contained', nItems, 'items')
      console.error('resulting items = ', this.items.length)
      console.error('count of processed = ', i)
      console.error('view = ', this)
    }
  }

  _setIfDifferent (i, id) {
    // console.log('setIfDifferent', i, id)
    if (this.items[i] !== this.itemMap[id]) {
      Vue.set(this.items, i, this.itemMap[id])
    }
  }

  async _initItemState () {
    const found = await this._find()
    Vue.set(this, 'items', found.docs)
    Vue.set(this, 'itemMap', this._mapFromItems(found.docs))
    if (this.isOrderable) this._refreshItemsInOrder()
  }

  _mapFromItems (items) {
    const itemMap = {}
    items.forEach(doc => { itemMap[doc._id] = doc })
    return itemMap
  }

  /**
   * Do a find on the current dataSource based on the current computed selector
   * and sorting options, and return the list of documents.
   *
   * @private
   * @returns {Promise} pouchdb-result-type  // REVIEW
   * @memberof View
   * @async
   */

  async _find () {
    const finder = { selector: this.selector }
    if (this.vs.sort) {
      finder.sort = this.vs.sort
    }
    const found = this.dataSource.find(finder)
    if (found.warning) {
      console.warn(found.warning, finder)
    }
    return found
  }

  // merge all selectors
  _selector () {
    const selector = { ...this.vs.of, ...this.vs.select }
    const selectors = []
    if ((selector && (selector !== {}))) selectors.push(selector)
    if (this.dataSource.selector) selectors.push(this.dataSource.selector)
    if (this.filter) selectors.push(this.filter)
    switch (selectors.length) {
      case 0:
        return {}
      case 1:
        return this._interpolate(selectors[0])
      default:
        return { $and: this._interpolate(selectors) }
    }
  }

  // do a deep interpolation of ${expr} in string values of maps
  // REVIEW rewrite with cleaner recursive form to handle arrays
  _interpolate (map) {
    Object.keys(map).forEach(key => {
      const val = map[key]
      if (typeof val === 'string') {
        map[key] = val.replace(/\${(\w+)}/g, (m, p1) => this.vs[p1])
      } else if (typeof val === 'object') {
        map[key] = this._interpolate(val)
      }
    })
    return map
  }

  _sort () {
    return this.vs.sort
  }
}
