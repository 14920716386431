<template>
  <v-dialog v-model="show" persistent max-width="350">
    <!-- login phase content -->
    <v-card v-if="phase == 'login'">
      <v-card-title class="title"
        >Sign In<v-spacer />
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="input"
            label="Phone number, username, or email"
            required
            :error-messages="errors"
          />
          <v-btn class="" block color="primary" @click="requestSignin()"
            >Sign In</v-btn
          >
          <br />
          <span>Don't have an account?</span>
          <v-btn
            text
            x-small
            color="primary"
            class="d-inline-block"
            @click="openCreateAccount"
            >Create Account</v-btn
          >
        </v-container>
      </v-card-text>
    </v-card>

    <!-- create account phase content -->
    <v-card v-if="phase == 'create'">
      <v-card-title class="title">
        Create Account
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-container>
          <div>
            Please give us some basic information to help you setup your
            profile!
          </div>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="user.phone"
                label="Phone Number"
                required
                :rules="phoneRules"
                :error-messages="userErrors['phone']"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.email"
                label="E-mail"
                required
                :rules="emailRules"
                :error-messages="userErrors['email']"
              />
            </v-col>
            <v-col cols="12" class="text-center">
              <span class="text-decoration-underline">
                Warning, Your username can't be changed later
              </span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.username"
                @input="checkUsername"
                label="Username"
                required
                :error-messages="userErrors['username']"
                :rules="usernameRules"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.fullName"
                label="Full Name / Dispaly Name"
                required
                :rules="nameRules"
                :error-messages="userErrors['fullName']"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn color="orange"  @click="signUp()">Help</v-btn> -->
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn text class="mr-34" color="primary" @click="createUser"
          >Create</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- confirm phase content -->
    <v-card v-if="phase == 'confirm'">
      <v-card-title
        class="dialog-heading subtitle-1 font-weight-bold justify-center"
      >
        Enter the Confirmation Code</v-card-title
      >
      <v-card-text>
        <v-container>
          <div>You should have just received an access sent via {{ via }}.</div>
          <div>Enter that code to complete signing in.</div>
          <v-text-field
            v-model="confirmCode"
            label="Enter the access code"
            type="number"
            required
            :error-messages="errors"
            autocomplete="one-time-code"
            autofocus
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn color="orange"  @click="signUp()">Help</v-btn> -->
        <v-spacer></v-spacer>
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn class="mr-34" color="primary" @click="confirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  parsePhoneNumberFromString,
  parsePhoneNumberWithError,
  parsePhoneNumber
} from 'libphonenumber-js/min/'
import { GridAPI, Auth } from '@/collections'

export default {
  props: {
    value: Boolean
  },

  // watch: {
  //   'state'
  // },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        if (value) {
          this.phase = 'login'
        }
        this.$emit('input', value)
      }
    }
  },

  data: () => ({
    input: '',
    rxCode: '',
    rxRequestID: '',
    confirmCode: '',
    errors: '',
    phase: 'login',
    via: '',
    user: { email: '', phone: '', username: '', fullName: '' },
    userErrors: { phone: '', email: '', username: '', fullName: '' },
    nameRules: [(v) => !!v || 'Name is required'],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => isValidEmailAddress(v) || 'E-mail must be valid'
    ],
    usernameRules: [
      (v) => !!v || 'username is required',
      (v) => isValidUserName(v) || 'Username must be valid'
    ],
    phoneRules: [(v) => !!v || 'Phone is required']
  }),

  methods: {
    // "Sign In" button clicked
    async requestSignin () {
      this.errors = ''
      var req = null

      if (isValidUserName(this.input)) {
        req = { login: this.input, type: 'user' }
      } else if (isValidEmailAddress(this.input)) {
        req = { login: this.input, type: 'email' }
      } else {
        // try phone#
        const n = parsePhoneNumberFromString(this.input, 'US')
        if (n) {
          if (!n.isValid()) {
            this.errors = 'invalid phone number'
            return
          }
          req = { login: n.number, type: 'phone' }
        } else {
          this.errors = 'invalid input'
          return  
        }
      }
      const result = await GridAPI.v1('POST', '/access/request', req)
      console.log(result)
      if (result.status != 201) {
        const errorMap = {
          403: 'no account found with this information',
          429: 'too many attempts, please try later'
        }
        this.errors = errorMap[result.status] || result.statusText
      } else {
        const resp = JSON.parse(result.responseText)
        this.request = resp.request
        this.via = resp.via
        this.phase = 'confirm'
      }
    },

    async confirm () {
      if (this.confirmCode == '') {
        this.errors = 'Code is required'
        return
      }
      const req = { code: this.confirmCode, request: this.request }
      const result = await GridAPI.v1('POST', '/access/confirm', req)
      console.log(result)
      if (result.status != 201) {
        const errorMap = {
          403: 'the provided code is expired or invalid',
          429: 'too many attempts, please try later'
        }
        this.errors = errorMap[result.status] || result.statusText
      } else {
        const resp = JSON.parse(result.responseText)
        console.log(resp)
        Auth.set({name: resp.user})
        this.$emit('authenticated')
        this.show = false
        this.reset()
      }
    },

    cancel () {
      this.show = false
      this.phase = 'login'
      this.error = ''
      this.reset()
    },
    reset () {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    openCreateAccount () {
      this.phase = 'create'
    },
    async createUser () {
      this.userErrors = { phone: '', email: '', username: '', fullName: '' }
      if (this.user.phone) {
        const n = parsePhoneNumberFromString(this.user.phone, 'US')
        if (n && !n.isValid()) {
          this.userErrors.phone = 'Invalid Phone number'
          return
        }
      }
      if (!isValidEmailAddress(this.user.email)) {
        this.userErrors.email = 'Invalid Email'
      } else if (!isValidUserName(this.user.username)) {
        this.userErrors.username = 'Invalid Username'
      } else if (await this.checkUsernameExists(this.user.username)) {
        this.userErrors.username = 'Username already taken'
      } else if (this.user.fullName == '') {
        this.userErrors.fullName = 'Invalid Name'
      } else {
        // TODO call user create api function
        this.phase = 'confirm'
      }
    },
    async checkUsername () {
      this.userErrors.username = ''
      if (await this.checkUsernameExists(this.user.username)) {
        this.userErrors.username = 'Username already taken'
      }
    },
    async checkUsernameExists (username) {
      const result = await GridAPI.v1('GET', '/users/' + username)
      console.log(result)
      if (result.status == 200) {
        return true
      }
      return false
    }
  },

  created () {
    const escHandler = (e) => {
      if (e.key === 'Escape' && this.show) {
        this.cancel()
      }
    }
    document.addEventListener('keydown', escHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escHandler)
    })
  }
}

async function requestAccessCode (phone) {
  return {
    error: 'noid',
    reason:
      'This is a silly error.  I am not really sure how long I can deal with this.   This is an amazing error message!!!  \n\n Plesae recheck it'
  }
}

function isValidEmailAddress (s) {
  return matchesRe(
    s,
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  // version that accepts unicode -- const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
}

function isValidUserName (s) {
  // /^[a-zA-Z0-9]+$/ -- doesn't enforce starting
  return matchesRe(s, /^[a-z][a-z0-9]+$/)
}

function matchesRe (s, re) {
  return re.test(String(s).toLowerCase())
}
</script>
