<template>
  <v-list>
    <template v-for="notification in notifications">
      <v-list-item
        :key="notification.id"
        @click="gotoItem(notification.doc, '_id')"
      >
        <visual-tooltip :item="notification.doc">
          <v-list-item-content>
            <v-list-item-title class="itemtext">
              <visual-indicator :type="notification.doc.type" />
              {{ notification.doc.title }}
            </v-list-item-title>
          </v-list-item-content>
        </visual-tooltip>
        <v-list-item-action>
          <v-list-item-action-text>{{
            notification.doc.updatedBy
          }}</v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
      <v-divider :key="'divider' + notification.id" />
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'NotificationTab',
  props: {
    notifications: Array
  },
  data: function () {
    return {}
  },
  components: {},
  created () {},
  methods: {
    gotoItem (item, key) {
      console.debug('item selected', item)
      this.$parent.$parent.$parent.gotoItem(item, key)
    }
  },
  computed: {},
  watch: {}
}
</script>
<style scoped>
</style>
