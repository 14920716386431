var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('drag',{attrs:{"transfer-data":{ item: _vm.item }},on:{"dragstart":_vm.dragStart,"dragend":_vm.dragEnd}},[_c('div',[_c('router-link',{staticClass:"router-link",attrs:{"exact":"","to":{ name: 'list', params: {perspective: _vm.item._id,
        account: _vm.param[0],
        collectionID:_vm.param[1] } }}},[_c('drop',{key:'drop' + _vm.item._rev,staticClass:"drop list",class:[
        {
          'item-active': _vm.isActive,
          over: _vm.over,
        } ],on:{"dragover":function($event){
      var i = arguments.length, argsArray = Array(i);
      while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDragover.apply(void 0, [ _vm.item ].concat( argsArray ))},"dragleave":function($event){
      var i = arguments.length, argsArray = Array(i);
      while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleLeave.apply(void 0, [ _vm.item ].concat( argsArray ))},"drop":function($event){
      var i = arguments.length, argsArray = Array(i);
      while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleDrop.apply(void 0, [ _vm.item ].concat( argsArray ))}}},[_c('div',{staticClass:"pl-3 mx-5 my-1 d-flex itemdiv"},[_c('div',{staticClass:"div-item font-normal"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }