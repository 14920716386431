<template>
  <div v-if="ready">
    <v-img
      v-if="utils.isImage(fileData.type)"
      aspect-ratio="1"
      :src="thumbnail"
      width="100"
    />
    <div v-else-if="utils.isVideo(fileData.type)" class="align-item">Video</div>
    <div v-else-if="utils.isAudio(fileData.type)" class="align-item">Audio</div>
    <div v-else class="align-item">{{ utils.ext(fileData.name) }}</div>
  </div>
  <div v-else style="width: 100px; height: 100px" class="grey text-center">
    <v-progress-circular
      indeterminate
      color="primary"
      class="ma-8"
    ></v-progress-circular>
  </div>
</template>
<script>
import imageFileToBase64 from '@/components/common/imgBase64'
import Utils from '@/components/common/utils.js'
export default {
  name: 'FilePreview',
  data: function () {
    return {
      utils: Utils,
      thumbnail: '',
      ready: false
    }
  },
  props: {
    filethumbData: File,
    fileData: File
  },
  mounted () {
    if (this.utils.isImage(this.fileData.type)) {
      this.generateThumbnail()
    } else {
      this.ready = true
    }
  },
  computed: {},
  methods: {
    generateThumbnail () {
      if (this.filethumbData === null) return
      this.thumbnail = URL.createObjectURL(this.filethumbData)
      this.ready = true
    }
  },
  components: {}
}
</script>
