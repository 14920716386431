<template>
  <div style="width: 100%">
    <input
      :multiple="multiple"
      accept="*"
      type="file"
      ref="file"
      style="display: none"
      v-on:click="clearData()"
      v-on:change="handleFileUpload()"
    />
    <div
      v-cloak
      @dragover="dragOver"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @drop="drop"
      :class="[
        {
          'is-drag-over': isDragging,
          'is-drag-valid': !(hasMultiple && !canAddMore),
        },
      ]"
    >
      <div class="file-input-wrapper">
        <div v-if="files.length > 0">
          <template v-for="(fileitem, index) in files">
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="text-center"
              :key="'filelist' + fileitem.file.name"
            >
              <v-col
                cols="12"
                lg="2"
                md="3"
                sm="3"
                order="2"
                order-lg="1"
                order-md="1"
                order-sm="1"
              >
                <v-avatar tile size="100" class="grey">
                  <FilePreview
                    :key="
                      'filepreview' +
                      (fileitem.small === null ? index : fileitem.file.name)
                    "
                    :filethumbData="fileitem.small"
                    :fileData="fileitem.file"
                  ></FilePreview>
                </v-avatar>
              </v-col>
              <v-col
                cols="12"
                lg="9"
                md="8"
                sm="8"
                class="text-lg-left text-md-left text-sm-left py-2"
                order="3"
                order-lg="2"
                order-md="2"
                order-sm="2"
              >
                <span class="py-2 d-block font-weight-medium">{{
                  fileitem.file.name
                }}</span>
                <span class="py-2"
                  >Size: {{ utils.filesize(fileitem.file.size) }}</span
                >
                <FileOption
                  v-if="utils.isImage(fileitem.file.type)"
                  :key="'fileoption' + fileitem.file.name"
                  :fileData="fileitem.file"
                  :index="index"
                  @option="syncOption"
                  @small="syncSmall"
                  @medium="syncMedium"
                  :sizeCheck="sizeCheck"
                ></FileOption>
                <div v-if="editFileTitle" class="d-block full-width ">
                <span>Title:
                <input  type="text" class="editinput" v-model="fileitem.filenameCopy"/>
                </span>
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="1"
                md="1"
                sm="1"
                order="1"
                order-lg="3"
                order-md="3"
                order-sm="2"
                class="text-lg-center text-md-center text-sm-center text-right"
              >
                <v-btn
                  x-small
                  color="red"
                  icon
                  fab
                  @click="removeFile(fileitem.file)"
                  title="Remove"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
          </template>
        </div>
        <h2
          @click="$refs.file.click()"
          v-if="canAddMore && more"
          class="grey lighten-1 pa-2 text-center"
        >
          <v-icon large>mdi-cloud-upload</v-icon>
          Drop {{ hasMultiple ? "files" : "file" }} or click to upload
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
import FilePreview from '@/components/fileagent/FilePreview.vue'
import FileOption from '@/components/fileagent/FileOption.vue'
import Utils from '@/components/common/utils.js'
var dragCounter = 0
export default {
  name: 'FileAgent',
  data: function () {
    return {
      utils: Utils,
      files: [],
      isDragging: false
    }
  },
  props: {
    sizeCheck: {
      type: Boolean,
      default: true
    },
    more: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    editFileTitle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FilePreview,
    FileOption
  },
  computed: {
    canAddMore () {
      if (!this.hasMultiple) {
        return this.files.length === 0
      }
      return true
    },
    hasMultiple () {
      if (typeof this.multiple === 'string') {
        return this.multiple !== 'false'
      }
      if (this.multiple === undefined) {
        return Array.isArray(this.value)
      }
      return !!this.multiple
    }
  },
  methods: {
    click () {
      this.$refs.file.click()
    },
    hasFile () {
      this.$refs.file.files.length > 0
    },
    async handleFileUpload () {
      var self = this
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        var file = this.$refs.file.files[i]
        var filedata = {
          file: file,
          small: null,
          medium: null,
          fileoption: 'original',
          filenameCopy: file.name
        }
        this.files.push(filedata)
        this.$emit('select', this.files)
      }
    },
    clearData () {
      if (!this.multiple) this.$refs.file.value = ''
    },
    dragEnter (event) {
      if (!event.dataTransfer) {
        return
      }
      this.isDragging = true
      event.stopPropagation()
      event.preventDefault()
      dragCounter++
      event.dataTransfer.dropEffect = 'copy' // Explicitly show this is a copy.
    },
    dragOver (event) {
      if (!event.dataTransfer) {
        return
      }
      this.isDragging = true
      event.stopPropagation()
      event.preventDefault()
      event.dataTransfer.dropEffect = 'copy' // Explicitly show this is a copy.
    },
    dragLeave (event) {
      if (!event.dataTransfer) {
        return
      }
      dragCounter--
      if (dragCounter === 0) {
        this.isDragging = false
      }
    },
    drop (event) {
      var self = this
      event.stopPropagation()
      event.preventDefault()
      dragCounter = 0
      this.isDragging = false
      if (!event.dataTransfer) {
        return
      }
      var droppedFiles = event.dataTransfer.files
      if (!droppedFiles) return
      if (!self.hasMultiple) {
        this.files.pop()
        droppedFiles = [droppedFiles[0]]
      }
      [...droppedFiles].forEach((file) => {
        var filedata = {
          file: file,
          small: null,
          medium: null,
          fileoption: 'original',
          filenameCopy: file.name
        }
        this.files.push(filedata)
        this.$emit('select', this.files)
      })
    },
    onPaste (file, multiple = false) {
      var filedata = {
        file: file[0],
        small: null,
        medium: null,
        fileoption: 'original',
        filenameCopy: file.name
      }
      this.files.push(filedata)
      this.$emit('select', this.files)
    },
    removeFile (file) {
      this.$emit('delete', file)
      this.files = this.files.filter((f) => {
        return f.file != file
      })
      this.$emit('sync', this.files)
    },
    syncOption (value, index) {
      this.files[index].fileoption = value
      this.$emit('sync', this.files)
    },
    syncSmall (value, index) {
      this.files[index].small = value
      this.$emit('sync', this.files)
    },
    syncMedium (value, index) {
      this.files[index].medium = value
      this.$emit('sync', this.files)
      this.$emit('syncThumb', this.files)
    },
    clear () {
      this.files = []
    }
  }
}
</script>

<style scoped>
.is-drag-over * {
  pointer-events: none !important;
}

.is-drag-over::before {
  content: " ";
}

.file-input-wrapper {
  position: relative;
  border: 2px dashed #aaa;
  transition: all 600ms;
}
.file-input-wrapper.is-drag-over,
.is-drag-over .file-input-wrapper {
  border-color: #f61a1a;
  box-shadow: inset 0px 0px 20px 1px #f61a1a;
}
.file-input-wrapper.is-drag-valid.is-drag-over,
.is-drag-valid.is-drag-over .file-input-wrapper {
  border-color: #54d500;
  box-shadow: inset 0px 0px 20px 1px #54d500;
}
@media (min-width: 320px) and (max-width: 480px) {
  .file-input-wrapper {
    padding: 5px !important;
  }
}
.editinput{
    border-radius: 2px !important;
    border-style: solid !important;
    padding-left: 2px;
    width: calc(100% - 40px);
}
</style>
