<template>
  <v-dialog v-model="show" persistent max-width="550">
    <v-card>
      <v-card-title
        class="dialog-heading subtitle-1 font-weight-bold justify-center"
        >Area Details</v-card-title
      >
      <v-row no-gutters class="px-6">
        <v-col cols="12">
          <v-text-field
            v-model="editCollectionObj.title"
            label="Title"
            required
          />
        </v-col>
        <v-col cols="12" class="text-center mt-5">
          <h4>Do you want to allow this area to be seen by others?</h4>
          <span>Make this area private</span>
          <v-switch
            v-model="editCollectionObj.isPrivate"
            class="ml-5 d-inline-block"
            label
          ></v-switch>
          <span class="d-block font-weight-bold"
            >(if public, anybody with the link below can view your area)</span
          >
        </v-col>
        <v-col cols="12" class="text-center mt-5">
          <span>
            Your area url:
            <b class="force-select">{{ editCollectionObj.collectionurl }}</b>
          </span>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            class="mr-2 mb-2"
            @click="
              resetnewCollection(false);
              show = false;
            "
            >Cancel</v-btn
          >
          <v-btn class="mr-2 mb-2" color="warning" @click="deleteCollection"
            >Delete Area</v-btn
          >
          <v-btn class="mr-2 mb-2" color="primary" @click="editCollection()"
            >Update Area</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Collection } from '@/collections'
import Utility from '@/components/common/utils.js'
export default {
  name: 'EditCollectionDialog',
  props: {
    value: Boolean,
    repo: Object,
    currentUser: Object,
    collectionObj: Object
  },
  data: function () {
    return {
      editCollectionObj: {},
      utils: Utility
    }
  },
  components: {},
  created () {},
  methods: {
    async editCollection () {
      var access = await this.utils.checkAccess(
        this.repo,
        this.editCollectionObj.id,
        'update'
      )
      if (access) {
        var params = {
          title: this.editCollectionObj.title,
          private: this.editCollectionObj.isPrivate
        }
        try {
          let collectionDoc = await this.repo.get(
            Collection.docIDFor(this.editCollectionObj.id)
          )
          Object.assign(collectionDoc, params)
          await this.repo.put(collectionDoc)
        } catch (error) {
          console.log(error)
        }
        this.show = false
        this.resetnewCollection()
      } else {
        this.$emit('alert', "You don't have permission to edit this area")
      }
    },
    resetnewCollection () {
      this.editCollectionObj = {
        title: '',
        id: '',
        available: '',
        isPrivate: false
      }
    },
    deleteCollection () {
      this.$emit('delete')
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.editCollectionObj = Object.assign(this.collectionObj)
      }
    }
  }
}
</script>
<style scoped>
</style>
