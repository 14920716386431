<template>
  <div>
    <v-text-field
      ref="searchbox"
      autofocus
      hide-details
      solo
      @input="searchSuggestion"
      v-model="searchText"
      class="ma-2"
    ></v-text-field>
    <div v-show="!dash.isEmpty(suggestions)">
      <div v-for="(items, i) in suggestions" :key="i">
        <v-subheader class="item-subheading">{{
          utils.ucfirst(i)
        }}</v-subheader>
        <v-divider />
        <v-list subheader dense>
          <template v-for="item in items">
            <v-list-item
              link
              :key="item._id"
              @dblclick="gotoExpandItem(item, 'id')"
              @click="highlightItem(item)"
            >
              <visual-tooltip :item="item">
                <v-list-item-content>
                  <v-list-item-title class="itemtext">
                    <visual-indicator :type="item.type" />{{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </visual-tooltip>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import Utility from '@/components/common/utils.js'
import lodash from 'lodash'
export default {
  name: 'SearchTab',
  props: {
    repo: Object
  },
  data: function () {
    return {
      dash: lodash,
      utils: Utility,
      searchText: '',
      suggestions: {}
    }
  },
  components: {},
  created () {},
  methods: {
    resetSearch () {
      this.searchText = ''
      this.$refs.searchbox.focus()
      this.suggestions = {}
    },
    searchSuggestion () {
      const suggestions = this.repo.index.search(this.searchText, {
        prefix: true,
        filter: (result) =>
          result.type !== 'membership' &&
          result.type !== 'subscription2' &&
          result.type !== 'banner'
      })
      console.debug('Get search result', this.searchText, suggestions)
      this.suggestions = lodash.groupBy(suggestions, 'type')
      delete this.suggestions.undefined
      delete this.suggestions['']
    },
    gotoExpandItem (item, key) {
      this.$parent.$parent.$parent.gotoExpandItem(item, key)
    },
    highlightItem (item) {
      localStorage.setItem('selected', item.id)
      let element = document.getElementById(item.id)
      if (element) {
        var els = document.querySelectorAll('.navigate')
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('selected')
        }
        element.classList.add('selected')
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        })
      }
    }
  },
  computed: {},
  watch: {}
}
</script>
<style scoped>
</style>
