import { Collection } from '@/collections'
import spaces from '@/components/services/spaces.js'
import moment from 'moment'
const Utils = {
  /**
     * Convert date time to relative time
     * @param {*} dateTime
     */
  formatTimefromNow (dateTime) {
    return moment(dateTime).fromNow()
  },
  /**
     * convert any datetime to a specific format date time
     * @param {} dateTime
     * @param {*} formatString
     */
  formatDateTime (dateTime, formatString) {
    return moment(dateTime).format(formatString)
  },
  /**
     * generate link for file
     * @param {*} item
     * @param {*} id
     * @param {*} exif
     */
  getLink (item) {
    if (item.type == 'link') {
      return item.url
    } else if (item.type == 'file') {
      return spaces.getPreviewLink(item.space_key)
    }
  },
  /**
     *
     * @param {check content type image} type
     */
  isImage (type) {
    return typeof type !== 'undefined' && type.indexOf('image') != -1
  },
  /**
     *
     * @param {Check content type vido} type
     */
  isVideo (type) {
    return typeof type !== 'undefined' && type.indexOf('video') != -1
  },
  /**
     *
     * @param {check content type auio} type
     */
  isAudio (type) {
    return typeof type !== 'undefined' && type.indexOf('audio') != -1
  },
  /**
     * get file extension
     * @param {*} type
     */
  ext (name) {
    if (typeof name !== 'undefined' && name.indexOf('.') !== -1) {
      return name.split('.').pop().toUpperCase()
    }
    return '?'
  },

  uuidv4 () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  },
  /**
     * set max line in a div return inline style string
     * @param  {} lines
     */
  setMaxLines (lines) {
    if (typeof lines === 'number') {
      return '-webkit-line-clamp:' + lines + ';height:' + lines + '.1em'
    }
  },
  /**
     * upper case first latter to a string
     * @param  {} string
     */
  ucfirst (string) {
    if (typeof string !== 'undefined') { return string.charAt(0).toUpperCase() + string.slice(1) }
    return ''
  },
  /**
     * generate avarat first character
     * @param  {} string
     */
  avatarText (string) {
    if (typeof string !== 'undefined') { return string.charAt(0).toUpperCase() }
    return ''
  },
  /**
     * slice to crop text
     * @param  {} string
     */
  sliceString: function (string) {
    if (string.length >= 15) { return string.slice(0, 14) + '...' }
    return string
  },
  /** Generate image infromation image source
     * @param  {} src
     */
  addImageProcess: function (src) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })
  },
  /**
     * get file form base64
     * @param  {} url
     * @param  {} filename
     * @param  {} mimeType
     */
  urltoFile: function (base64, filename, mimeType) {
    return fetch(base64, { credentials: 'include' })
      .then(function (res) {
        return res.arrayBuffer()
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType })
      })
  },
  /** calculate file size
     * @param  {} num
     */
  filesize: function (num) {
    if (typeof num !== 'number' || isNaN(num)) {
      throw new TypeError('Expected a number')
    }

    var exponent
    var unit
    var neg = num < 0
    var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    if (neg) {
      num = -num
    }

    if (num < 1) {
      return (neg ? '-' : '') + num + ' B'
    }

    exponent = Math.min(
      Math.floor(Math.log(num) / Math.log(1000)),
      units.length - 1
    )
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
    unit = units[exponent]

    return (neg ? '-' : '') + num + ' ' + unit
  },
  /**
     * get user details from user object
     * @param  {} collection
     * @param  {} name
     */
  getUserDetails: async function (collection, name) {
    if (typeof name === 'undefined') return ''
    let userDocID = `org.couchdb.user:${name}`
    let userinfo = { display: name }
    try {
      userinfo = await collection.repo.get(userDocID)
    } catch (error) {
    }
    return userinfo
  },
  /**
     * generate dbid
     * @param  {} account
     * @param  {} collectionID
     */
  getdbid (account, collectionID) {
    return account + '_' + collectionID
  },
  /**
     * split dbid to get account and collectionID
     * @param  {} dbid
     */
  splitdbid (dbid) {
    return dbid ? dbid.split('_'):["",""];
  },
  /**
     * Enable features from user profile now feature value is 'labs' future it may be a array with different features
     * Assignment
     * Tags
     * Bookmarks
     * Filter creation panel
     * @param  {} currentuser
     */
  featureIsEnabled (currentuser) {
    return (currentuser && currentuser.features && currentuser.features == 'labs')
  },
  /**
     * Check collection access
     * @param  {} repo,collectionName, action
     */
  async checkAccess (repo, collectionName, action) {
    const collection = new Collection(repo, collectionName)
    await collection.open()
    var access = collection.authorizes(action)
    if (collection.isOpen) {
      await collection.close()
    }
    return access
  },
  /**
  * Get base64 from url image
  * @param  {} url
  */
  async getImageFromUrl (url) {
    const data = await fetch(url, { credentials: 'include' })
    const blob = await data.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        resolve(base64data)
      }
    })
  },
  /**
  * Get image contenttype from base64
  * @param  {} encoded
  */
  base64MimeType (encoded) {
    var result = null
    if (typeof encoded !== 'string') {
      return result
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
    if (mime && mime.length) {
      result = mime[1]
    }
    return result
  },
  validateUrl (value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    )
  }
}
export default Utils
