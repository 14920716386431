
/**
 * Default configuration options
 *
 * @type {Object}
 */
 var defaults = {


  /**
   * Allowed MIME types
   */
  allowedTypes: [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/gif'
  ],

  /**
   * Text which will be inserted when dropping or pasting a file.
   * Acts as a placeholder which will be replaced when the file is done with uploading
   */
  progressText: '![Uploading file...]()',

  /**
   * When a file has successfully been uploaded the progressText
   * will be replaced by the urlText, the {filename} tag will be replaced
   * by the filename that has been returned by the server
   */
  urlText: "![file]({filename})",

  /**
   * Text which will be used when uploading has failed
   */
  errorText: "Error uploading file",

  /**
   * Before the file is send
   */
  beforeFileUpload: function () {
      return true;
  },

  /**
   * Triggers when a file is dropped or pasted
   */
  onFileReceived: function () { },

  /**
   * Custom upload handler
   *
   * @return {Boolean} when false is returned it will prevent default upload behavior
   */
  onFileUploadResponse: function () {
      return true;
  },

  /**
   * Custom error handler. Runs after removing the placeholder text and before the alert().
   * Return false from this function to prevent the alert dialog.
   *
   * @return {Boolean} when false is returned it will prevent default error behavior
   */
  onFileUploadError: function () {
      return true;
  },

  /**
   * Custom cancel handler. Runs after removing the placeholder text and before the alert().
   * Return false from this function to prevent the alert dialog.
   *
   * @return {Boolean} when false is returned it will prevent default error behavior
   */
  onFileUploadCancel: function () {
      return true;
  },

  /**
   * When a file has succesfully been uploaded
   */
  onFileUploaded: function () { }


};
export default class InlineAttachment {
  constructor(options, instance) {
      this.settings = this.merge(options, defaults);
      this.editor = instance;
      this.filenameTag = '{filename}';
      this.lastValue = null;
  }
  /**
   * Simple function to merge the given objects
   *
   * @param {Object[]} object Multiple object parameters
   * @returns {Object}
   */
  merge() {
      var result = {};
      for (var i = arguments.length - 1; i >= 0; i--) {
          var obj = arguments[i];
          for (var k in obj) {
              if (obj.hasOwnProperty(k)) {
                  result[k] = obj[k];
              }
          }
      }
      return result;
  }

  /**
   * Append a line of text at the bottom, ensuring there aren't unnecessary newlines
   *
   * @param {String} appended Current content
   * @param {String} previous Value which should be appended after the current content
   */
  appendInItsOwnLine(previous, appended) {
      return (previous + "\n\n[[D]]" + appended)
          .replace(/(\n{2,})\[\[D\]\]/, "\n\n")
          .replace(/^(\n*)/, "");
  }

  /**
   * Returns if the given file is allowed to handle
   *
   * @param {File} clipboard data file
   */
  isFileAllowed(file) {
      if (file.kind === 'string') { return false; }
      if (this.settings.allowedTypes.indexOf('*') === 0) {
          return true;
      } else {
          return this.settings.allowedTypes.indexOf(file.type) >= 0;
      }
  }

  /**
   * Handles upload response
   *
   * @param  {XMLHttpRequest} xhr
   * @return {Void}
   */
  onFileUploadResponse(xhr, filename) {
      if (this.settings.onFileUploadResponse.call(this, xhr) !== false) {
          if (filename) {
              var newValue;
              if (typeof this.settings.urlText === 'function') {
                  newValue = this.settings.urlText.call(this, filename, "");
              } else {
                  newValue = this.settings.urlText.replace(this.filenameTag, filename);
              }
              var text = this.editor.getValue().replace(this.lastValue, newValue);
              this.editor.setValue(text);
              this.settings.onFileUploaded.call(this, filename);
          }
      }
  }


  /**
   * Called when a file has failed to upload
   *
   * @param  {XMLHttpRequest} xhr
   * @return {Void}
   */
  onFileUploadError(xhr) {
      if (this.settings.onFileUploadError.call(this, xhr) !== false) {
          var text = this.editor.getValue().replace(this.lastValue, this.settings.errorText);
          this.editor.setValue(text);
      }
  }

  /**
   * Called when a file has canceled to upload
   *
   * @param  {XMLHttpRequest} xhr
   * @return {Void}
*/
  onFileUploadCancel(xhr) {
      if (this.settings.onFileUploadCancel.call(this, xhr) !== false) {
          var text = this.editor.getValue().replace(this.lastValue, "");
          this.editor.setValue(text);
      }
  }

  /**
   * Called when a file has been inserted, either by drop or paste
   *
   * @param  {File} file
   * @return {Void}
   */
  onFileInserted(file) {
      if (this.settings.onFileReceived.call(this, file) !== false) {
          this.lastValue = this.settings.progressText;
          this.editor.insertValue(this.lastValue);
      }
  }
}
export { InlineAttachment }