<template>
  <div style="width: 100%">
    <v-textarea
      :tabindex="tabindex"
      :id="elementid"
      :readonly="!authorization.update"
      placeholder="Add more detail here..."
      class="custom-input"
      :class="autoheight ? 'custom-textarea' : 'custom-textarea-auto'"
      v-show="!isMarkdown"
      v-model="textBody"
      v-outside-click="descriptionBlur"
      auto-grow
      background-color="transparent"
      solo
      hide-details
      flat
      ref="el"
      @keydown="keyDown"
    />
    <div
      :class="
        autoheight ? 'custom-input-markdown' : 'custom-input-markdown-auto'
      "
      v-if="isMarkdown"
      @click.stop="descriptionMarkClick"
    >
      <markdown-it-vue
        class="md-body"
        :content="textBody"
        :collection="collection"
      />
    </div>

    <v-dialog v-model="attachemntDialog" persistent max-width="900px">
      <v-card>
        <v-row class="pa-2" justify="center" no-gutters>
          <div style="width: 100%">
            <div class="file-input-wrapper">
              <div v-if="files.length > 0">
                <template v-for="(fileitem, index) in files">
                  <v-row
                    no-gutters
                    justify="center"
                    align="center"
                    class="text-center"
                    :key="'filelist' + fileitem.file.name"
                  >
                    <v-col
                      cols="12"
                      lg="2"
                      md="3"
                      sm="3"
                      order="2"
                      order-lg="1"
                      order-md="1"
                      order-sm="1"
                    >
                      <v-avatar tile size="100" class="grey">
                        <FilePreview
                          :key="
                            'filepreview' +
                            (fileitem.small === null
                              ? index
                              : fileitem.file.name)
                          "
                          :filethumbData="fileitem.small"
                          :fileData="fileitem.file"
                        ></FilePreview>
                      </v-avatar>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="9"
                      md="8"
                      sm="8"
                      class="text-lg-left text-md-left text-sm-left py-2"
                      order="3"
                      order-lg="2"
                      order-md="2"
                      order-sm="2"
                    >
                      <span class="py-2 d-block font-weight-medium">{{
                        fileitem.file.name
                      }}</span>
                      <span class="py-2"
                        >Size: {{ utils.filesize(fileitem.file.size) }}</span
                      >
                      <FileOption
                        v-if="utils.isImage(fileitem.file.type)"
                        :key="'fileoption' + fileitem.file.name"
                        :fileData="fileitem.file"
                        :index="index"
                        @option="syncOption"
                        @small="syncSmall"
                        @medium="syncMedium"
                      ></FileOption>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="1"
                      md="1"
                      sm="1"
                      order="1"
                      order-lg="3"
                      order-md="3"
                      order-sm="2"
                      class="
                        text-lg-center text-md-center text-sm-center text-right
                      "
                    ></v-col>
                  </v-row>
                </template>
              </div>
            </div>
          </div>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="cancelUpload()">Cancel</v-btn>
          <v-btn color="primary" @click.stop="uploadFiles()" :loading="loading" :disabled="loading">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { InlineAttachment } from '@/components/common/markdownit/inline-attachment.js'
import FilePreview from '@/components/fileagent/FilePreview.vue'
import FileOption from '@/components/fileagent/FileOption.vue'
import Utils from '@/components/common/utils.js'
import Spaces from '@/components/services/spaces.js'
import { OutsideClick } from '@/directives/OutsideClick.js'
import imageFileToBase64 from '@/components/common/imgBase64'
import * as blobUtil from 'blob-util'
export default {
  name: 'text-area',
  props: {
    value: {
      type: String,
      default: ''
    },
    tabindex: {
      type: Number,
      default: -1
    },
    enableMarkdown: {
      type: Boolean,
      default: false
    },
    authorization: Object,
    autoheight: {
      type: Boolean,
      default: true
    },
    generateThumbnail: {
      type: Boolean,
      default: true
    },
    collection: Object
  },
  mounted () {
    if (this.textBody === '' || this.enableMarkdown) {
      this.isMarkdown = false
    }
    this.$nextTick(() => {
      this.attachToInput(document.getElementById(this.elementid))
    })
  },
  watch: {},
  data () {
    return {
      elementid: Utils.uuidv4(),
      inlineattach: null,
      attachemntDialog: false,
      files: [],
      utils: Utils,
      spaces: Spaces,
      isMarkdown: true,
      loading: false,
      autoGrowHack: false
    }
  },
  directives: {
    OutsideClick
  },
  components: { FilePreview, FileOption },
  methods: {
    keyDown (e) {
      if ((e.keyCode == 10 || e.keyCode == 13) && (e.ctrlKey || e.metaKey || e.shiftKey)) {
        console.log(e)
        this.$emit('submitSave')
      }
    },
    inputAdjust () {
    },
    attachToInput: function (input, options) {
      var self = this
      options = options || {}

      var editor = this.createInstance(input)
      this.inlineattach = new InlineAttachment(options, editor)
      this.inlineattach.editor.setValue(this.textBody)

      input.addEventListener(
        'paste',
        function (e) {
          self.onPasteEvt(event)
        },
        false
      )
      input.addEventListener(
        'input',
        function (e) {
          self.updateBody()
        },
        false
      )
      input.addEventListener(
        'drop',
        function (e) {
          e.stopPropagation()
          e.preventDefault()
          self.onDropEvt(event)
        },
        false
      )
      input.addEventListener(
        'dragenter',
        function (e) {
          e.stopPropagation()
          e.preventDefault()
        },
        false
      )
      input.addEventListener(
        'dragover',
        function (e) {
          e.stopPropagation()
          e.preventDefault()
        },
        false
      )
    },
    createInstance (instance) {
      var self = this
      var el = instance
      return {
        getValue: function () {
          return el.value
        },
        insertValue: function (text) {
          var scrollPos = el.scrollTop
          var strPos = 0
          var browser = false
          var range

          if (el.selectionStart || el.selectionStart === '0') {
            browser = 'ff'
          } else if (document.selection) {
            browser = 'ie'
          }

          if (browser === 'ie') {
            el.focus()
            range = document.selection.createRange()
            range.moveStart('character', -el.value.length)
            strPos = range.text.length
          } else if (browser === 'ff') {
            strPos = el.selectionStart
          }
          var front = el.value.substring(0, strPos)
          var back = el.value.substring(strPos, el.value.length)
          el.value = front + text + back
          self.textBody = el.value
          strPos = strPos + text.length
          if (browser === 'ie') {
            el.focus()
            range = document.selection.createRange()
            range.moveStart('character', -el.value.length)
            range.moveStart('character', strPos)
            range.moveEnd('character', 0)
            range.select()
          } else if (browser === 'ff') {
            el.selectionStart = strPos
            el.selectionEnd = strPos
            el.focus()
          }
          el.scrollTop = scrollPos
        },
        setValue: function (val) {
          el.value = val
        }
      }
    },

    /**
     * Called when a drop event occures
     * @param  {Event} e
     * @return {Boolean} if the event was handled
     */
    onDropEvt (e) {
      var result = false
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        var file = e.dataTransfer.files[i]
        if (this.inlineattach.isFileAllowed(file)) {
          result = true
          this.inlineattach.onFileInserted(file)
          var filedata = {
            file: file,
            small: null,
            medium: null,
            fileoption: 'original'
          }
          this.files.push(filedata)
        }
      }

      if (result) {
        this.attachemntDialog = true
      }

      return result
    },
    /**
     * Called when a paste event occured
     * @param  {Event} e
     * @return {Boolean} if the event was handled
     */
    onPasteEvt (e) {
      var result = false
      var clipboardData = e.clipboardData
      var items

      if (typeof clipboardData === 'object') {
        items = clipboardData.items || clipboardData.files || []

        for (var i = 0; i < items.length; i++) {
          var item = items[i]
          if (this.inlineattach.isFileAllowed(item)) {
            result = true
            this.inlineattach.onFileInserted(item.getAsFile())
            var filedata = {
              file: item.getAsFile(),
              small: null,
              medium: null,
              fileoption: 'original'
            }
            this.files.push(filedata)
          }
        }
      }

      if (result) {
        e.preventDefault()
        this.attachemntDialog = true
      }

      return result
    },
    async uploadFiles () {
      this.loading = true
      for (const uploadFile of this.files) {
        var attachment = this.selectedFile(uploadFile)
        var _id = this.utils.uuidv4()
        var space_key = _id + '_' + attachment.name
        uploadFile.attachmentData = {
          type: 'file',
          space_key: space_key,
          title: attachment.name,
          name: attachment.name,
          content_type: attachment.type,
          _id: _id
        }
        var r = await this.spaces.uploadFile(
          attachment,
          uploadFile.attachmentData,
          space_key
        )
        console.debug('File upload in S3', r)
        if (r.status == 201) {
          let imageurl = Spaces.getPreviewLink(space_key)
          this.inlineattach.onFileUploadResponse('', imageurl)
        } else {
          this.inlineattach.onFileUploadError('')
        }
        this.updateTextarea()
      }
      this.$emit('attachment', this.files)
      this.attachemntDialog = false
      this.files = []
      this.loading = false
    },
    syncFileThumb: function (value) {
      var self = this
      var thumbData = value
      thumbData.forEach(function (file, index) {
        if (!file.medium) return
        var thumbnails = []
        var attachment = file.medium
        const mimetype = attachment.type.split('/') // NB: Not supprted by Safari on iOS !??! @todo: TEST!
        const isImage = mimetype[0] === 'image'
        if (isImage) {
          imageFileToBase64(attachment, 600, 600, 1).then(function (base64) {
            thumbnails[0] = {
              attachment: base64,
              attachmentId: 'thumbnail600',
              type: attachment.type
            }
          })
          imageFileToBase64(attachment, 300, 300, 1).then(function (base64) {
            thumbnails[1] = {
              attachment: base64,
              attachmentId: 'thumbnail300',
              type: attachment.type
            }
          })
          imageFileToBase64(attachment, 150, 150, 1).then(function (base64) {
            thumbnails[2] = {
              attachment: base64,
              attachmentId: 'thumbnail150',
              type: attachment.type
            }
          })
          self.files[index].thumbnails = thumbnails
        }
      })
    },
    selectedFile (selFile) {
      var self = this
      var file = selFile.file
      if (self.utils.isImage(file.type)) {
        if (selFile.fileoption === 'small') {
          file = selFile.small
        } else if (selFile.fileoption === 'medium') {
          file = selFile.medium
        }
      }
      return file
    },
    syncOption (value, index) {
      this.files[index].fileoption = value
    },
    syncSmall (value, index) {
      this.files[index].small = value
    },
    syncMedium (value, index) {
      this.files[index].medium = value
      // if (this.generateThumbnail) this.syncFileThumb(this.files)
    },
    descriptionBlur () {
      // very important to check this other wise outside click event fire when click on file agent checkboxes
      if (!this.attachemntDialog) {
        this.isMarkdown = !!(
          typeof this.textBody !== 'undefined' && this.textBody !== ''
        )
      }
      this.$emit('blur', this.isMarkdown)
    },
    descriptionMarkClick () {
      var self = this
      this.isMarkdown = false
      setTimeout(() => {
        this.$refs.el.calculateInputHeight()
      }, 10)
      this.$nextTick(() => {
        this.$refs.el.focus()
        this.inputAdjust()
      })
    },
    updateBody () {
      this.textBody = this.inlineattach.editor.getValue()
      this.inputAdjust()
    },
    cancelUpload () {
      for (var j = 0; j < this.files.length; j++) {
        this.inlineattach.onFileUploadCancel('')
        this.updateTextarea()
      }
      this.attachemntDialog = false
      this.files = []
    },
    updateTextarea () {
      // update textarea valu on every file upload
      const e = new Event('input')
      const element = document.getElementById(this.elementid)
      element.dispatchEvent(e)
    }
  },
  directives: {
    outside: {
      bind: function (el, binding, vnode) {
        el.eventSetDrag = function () {
          el.setAttribute('data-dragging', 'yes')
        }
        el.eventClearDrag = function () {
          el.removeAttribute('data-dragging')
        }
        el.eventOnClick = function (event) {
          var dragging = el.getAttribute('data-dragging')
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
            // call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.addEventListener('touchstart', el.eventClearDrag)
        document.addEventListener('touchmove', el.eventSetDrag)
        document.addEventListener('click', el.eventOnClick)
        document.addEventListener('touchend', el.eventOnClick)
      },
      unbind: function (el) {
        document.removeEventListener('touchstart', el.eventClearDrag)
        document.removeEventListener('touchmove', el.eventSetDrag)
        document.removeEventListener('click', el.eventOnClick)
        document.removeEventListener('touchend', el.eventOnClick)
        el.removeAttribute('data-dragging')
      }
    }
  },
  computed: {
    textBody: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lange="scss">
.file-input-wrapper {
  position: relative;
  border: 2px dashed #aaa;
  transition: all 600ms;
}
.custom-input-markdown {
  min-height: calc(90vh - 158px);
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 15px 10px;
}
.custom-input-markdown-auto {
  min-height: auto;
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 15px 10px;
}
.custom-textarea-auto {
 /*  max-height: 40vh; */
  resize: none;
}
.custom-textarea {
  height: calc(90vh - 158px);
  resize: none;
}
.blogtext textarea {
  max-height: 200px !important;
  min-height: 200px;
}
.blog .custom-input-markdown-auto {
  padding: 0;
}
.custom-input.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
     padding: 0 !important;
}
.custom-input.v-textarea textarea{
  line-height: 1.5 !important;
  margin-top:0 !important;
}
.custom-input.v-input{
  font-size: inherit !important;
}
</style>
