<template>
  <!--snackbar for refresh application if anu update available -->
  <v-snackbar
    v-model="snackWithButtons"
    :timeout="timeout"
    bottom
    left
    class="snack"
  >
    New version available!
    <v-btn small color="primary" class="ml-5" dark @click.native="refreshApp"
      >Refresh</v-btn
    >
    <v-btn icon color="white" class="ml-5" @click="snackWithButtons = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  name: 'VersionSnackbar',
  data: () => ({
    snackWithButtons: false,
    refreshing: false,
    registration: null,
    timeout: -1
  }),
  props: {},
  components: {},
  computed: {},
  created () {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  mounted () {},
  methods: {
    showRefreshUI (e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail
      this.snackWithButtons = true
    },
    refreshApp () {
      this.snackWithButtons = false
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return
      }

      this.registration.waiting.postMessage('skipWaiting')
    }
  },
  watch: {}
}
</script>

<style scoped>
/* Provide better right-edge spacing when using an icon button there. */
.snack >>> .v-snack__content {
  padding-right: 16px;
}
</style>
