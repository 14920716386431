<template>
  <!--  Quick capture modal  -->
  <v-dialog v-model="show" persistent scrollable max-width="800px">
    <v-card>
      <v-card-title
        class="dialog-heading subtitle-1 font-weight-bold justify-center"
        >Quick Capture</v-card-title
      >
      <v-card-text>
        <v-container>
          <input
            autofocus
            tabindex="1"
            type="text"
            ref="itemTitle"
            v-model="item.title"
            placeholder="Title here..."
            class="custom-input-title text-left"
          />
          <text-area
            :tabindex="2"
            ref="textarea"
            :enableMarkdown="true"
            :authorization="authorization"
            v-model="item.body"
            :autoheight="false"
            class="quick item-font"
            @attachment="syncAttachment"
            :collection="collection"
          />
          <v-row justify="center" no-gutters>
            <FileAgent
              @select="filesSelected($event)"
              @delete="fileDeleted($event)"
              :multiple="true"
              ref="fileagent"
            />
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn color="primary" @click="save" :loading="uploading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextArea from '@/components/common/markdownit/text-area.vue'
import FileAgent from '@/components/fileagent/FileAgent.vue'
import Utility from '@/components/common/utils.js'
import Spaces from '@/components/services/spaces.js'
import _attachment from '@/components/common/attachment.js'
export default {
  name: 'QuickCaptureDialog',
  props: {
    value: Boolean,
    collection: Object,
    view: Object,
    authorization: Object
  },
  data: function () {
    return {
      item: {},
      filesDataForUpload: [],
      uploading: false,
      attachmentInfo: []
    }
  },
  components: { TextArea, FileAgent },
  methods: {
    syncAttachment (value) {
      for (const file of value.length) {
        this.attachmentInfo.push(file)
      }
    },
    filesSelected: function (filesDataNewlySelected) {
      var validFilesData = filesDataNewlySelected
      this.filesDataForUpload = validFilesData
    },
    fileDeleted: function (fileData) {
      this.filesDataForUpload = this.filesDataForUpload.filter((f) => {
        return f.file != fileData
      })
    },
    selectedFile (selFile) {
      var file = selFile.file
      if (Utility.isImage(file.type)) {
        if (selFile.fileoption === 'small') {
          file = selFile.small
        } else if (selFile.fileoption === 'medium') {
          file = selFile.medium
        }
      }
      return file
    },
    async save () {
      this.uploading = true
      try {
        await this.view.put(this.item)
        await this.view.setOrder(this.item._id, 0)
        for (const fileData of this.filesDataForUpload) {
          var attachment = this.selectedFile(fileData)
          var space_key = Utility.uuidv4() + '_' + attachment.name

          fileData.attachmentData = {
            type: 'file',
            space_key: space_key,
            title: attachment.name,
            name: attachment.name,
            content_type: attachment.type,
            attached_to: this.item._id,
            _id: Utility.uuidv4()
          }
          var r = await Spaces.uploadFile(
            attachment,
            fileData.attachmentData,
            space_key
          )
          if (r.status == 201) {
            this.attachmentInfo.push(fileData)
          } else {
            this.$root.vtoast.show({
              message: 'Fail to upload image. Try again later.'
            })
          }
        }
      } catch (err) {
        this.$root.vtoast.show({
          message: 'Fail to upload image. Try again later.'
        })
        this.uploading = false
      }
      if (this.attachmentInfo.length > 0) {
        await _attachment.add(this.item, this.attachmentInfo, this.collection)
      }
      this.cancel()
    },
    cancel () {
      this.show = false
      this.item = {}
      this.filesDataForUpload = []
      this.attachmentInfo = []
      this.$refs.fileagent.clear()
      this.reset()
    },
    reset () {
      Object.assign(this.$data, this.$options.data.apply(this))
    }
  },
  computed: {
    show: {
      get () {
        if (this.value) {
          setTimeout(() => {
            this.$refs.itemTitle.focus()
          }, 100)
        }
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {}
}
</script>
<style scoped>
.custom-input-title {
  padding: 0 !important;
}
</style>
