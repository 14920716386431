<template>
  <span v-if="imageerror" class="brown--text body-2">{{
    utils.avatarText(userinfo.display)
  }}</span>
  <img :src="thumbImg" v-else :alt="userinfo.display" />
</template>
<script>
import * as blobUtil from 'blob-util'
import Utils from '@/components/common/utils.js'
export default {
  name: 'AsyncAvatarImage',
  components: {},
  props: {
    collection: Object,
    username: String
  },
  data: function () {
    return {
      thumbImg: '',
      imageerror: false,
      utils: Utils,
      userinfo: Object
    }
  },
  created () {
    this.generateThumbnail()
  },
  mounted () {},
  computed: {},
  methods: {
    async generateThumbnail () {
      await this.collectionStatus('ready', 'collection')
      var self = this
      var userDocID = `org.couchdb.user:${self.username}`
      try {
        self.userinfo = await this.collection.repo.get(userDocID)
      } catch (error) {
        //        console.log('generateThumbnail error', self.username, error)
        self.imageerror = true
        self.userinfo = { display: self.username }
      }
      try {
        var result = await self.collection.getAttachment(
          userDocID,
          `${self.username}.jpg`
        )
        var blobURL = blobUtil.createObjectURL(result)
        self.thumbImg = blobURL
        self.imageerror = false
      } catch (error) {
        self.imageerror = true
      }
    },
    async collectionStatus (status = 'ready', waiter = 'unknown') {
      if (!this.collection) {
        console.debug(waiter, 'is waiting for collection status to be', status)
        while (!this.collection) {
          await new Promise((resolve) => setTimeout(resolve, 100))
        }
        console.debug(waiter, 'resuming -- collection is now', status)
      }
    }
  },
  watch: {
  }
}
</script>
