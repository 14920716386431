<template>
  <div :class="details ? 'mx-5 mt-5' : 'mx-0'"
    :style="details ? 'width:auto' : 'width:100%'">
    <div>
      <v-row v-if="hasItem">
        <v-col class="mt-0">
          <v-icon class="mr-2">mdi-comment-text-outline</v-icon>
          <span>Comments</span><span class="ml-1">({{ hasItem }})</span>
        </v-col>
      </v-row>
      <v-divider v-if="hasItem" />
      <div :id="nameref + 'bottompage' + item._id"></div>
      <v-row
        v-if="showCommentText && authorization.comment && currentUser"
        class="py-0 mt-4"
      >
        <v-col cols="12" class="pl-4 py-2 mb-2 commenttext">
          <comment-text
            :elementid="nameref + 'commentText' + item._id"
            :currentUser="currentUser"
            v-model="commentText"
            @attachment="syncAttachment"
          >
            <v-btn
              color="primary"
              @click="addComment()"
              v-show="toggleCommentButton"
            >
              Comment
            </v-btn>
          </comment-text>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" class="pt-0 mt-0">
          <v-list :three-line="lineThree" class="comments py-0 my-0">
            <template v-for="(item, index) in items">
              <CommentItem
                :collection="collection"
                :item="item"
                :view="view"
                :authorization="authorization"
                :key="'commentItem' + item._rev"
              ></CommentItem>
              <v-divider :key="'divider' + item._id" inset />
            </template>
            <div
              v-if="items && hasItem - items.length > 0"
              @click.stop="showAllComments()"
              class="mt-5 ml-4 mb-3 float-right"
            >
              <v-hover v-slot="{ hover }">
                <span
                  class="more-comments"
                  :class="hover ? 'primary--text' : ''"
                  >View {{ hasItem - items.length }} more comments
                  <v-icon :color="hover ? 'primary' : ''"
                    >mdi-menu-down</v-icon
                  ></span
                >
              </v-hover>
            </div>
          </v-list>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Utility from '@/components/common/utils.js'
import CommentItem from '@/components/CommentItem.vue'
import { View, Auth } from '@/collections'
import CommentText from '@/components/common/markdownit/comment-text.vue'
import _attachment from '@/components/common/attachment.js'

export default {
  name: 'InlineComment',
  props: {
    collection: Object,
    item: Object,
    lineThree: {
      type: Boolean,
      default: true
    },
    authorization: Object,
    showCommentText: {
      type: Boolean,
      default: true
    },
    nameref: {
      type: String,
      default: 'commentComponent'
    },
    details: {
      type: Boolean,
      default: false
    },
    autoScroll:{
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      view: null,
      comments: [],
      utils: Utility,
      editmode: [],
      fav: true,
      menu: false,
      message: false,
      hints: true,
      maxComment: 3,
      showAll: false,
      commentText: '',
      toggleCommentButton: false,
      attachmentInfo: []
    }
  },
  async created () {
    this.view = await new View(this.collection).open({
      of: { container: this.item._id, type: 'comment' }
    })
  },
  methods: {
    async addComment () {
      var text = this.commentText && this.commentText.trim()
      if (text) {
        var item = { text: text }
        await this.view.put(item)
        if (this.attachmentInfo.length > 0) {
          await _attachment.add(item, this.attachmentInfo, this.collection)
          this.attachmentInfo = []
        }
        this.commentText = ''
      }
    },
    showAllComments () {
      this.maxComment = this.hasItem
      this.showAll = true
    },
    focus () {
      if(!this.autoScroll) return
      setTimeout(() => {
        document
          .getElementById(this.nameref + 'bottompage' + this.item._id)
          .scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          })
        document
          .getElementById(this.nameref + 'commentText' + this.item._id)
          .focus()
      }, 100)
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    }
  },
  components: { CommentItem, CommentText },
  computed: {
    currentUser () {
      return Auth.get()
    },
    items () {
      if (this.view === null) return
      var items = [...this.view.items]
      return items
        .sort(function (a, b) {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        })
        .slice(0, this.showAll ? this.hasItem : this.maxComment)
    },
    hasItem () {
      if (this.view !== null) {
        return this.view.items.length
      }
      return 0
    }
  },
  watch: {
    showCommentText: {
      immediate: true,
      handler (val, oldVal) {
        if (val) {
          this.focus()
        }
      }
    },
    commentText (newVal, oldVal) {
      this.toggleCommentButton = newVal && newVal != ''
    }
  }
}
</script>
<style scoped>
.v-list.comments {
  background: transparent !important;
  background-color: transparent !important;
}
.more-comments:hover {
  cursor: pointer;
}

.v-list--three-line .v-list-item,
.v-list-item--three-line {
  min-height: 50px !important;
}
.commenttext >>> .v-text-field .v-input__append-inner {
  align-self: flex-end !important;
  margin-bottom: 4px !important;
  margin-top: 0 !important;
}
</style>
