
const Spaces = {
  uploadFile: async function (file, item, storageKey) {
    var result = await axios.put(`${storageKey}`, file, {
      headers: {
        'X-Item-ID': item._id
      },
      withCredentials: true 
    })
    return result
  },
  getPreviewLink: function (key, width = 0, height = 0) {
    let url = `${axios.defaults.baseURL}${key}`// it return original image file
    if (width !== 0 || height !== 0) {
      url = `${axios.defaults.baseURL}${key}/${width}x${height}.jpg`
    }
    return url
  }
}
export default Spaces
