<template>
  <div
    class="markdown-body"
    ref="markdown-it-vue-container"
    @click="handelClick"
  ></div>
</template>

<script>
import MarkdownIt from 'markdown-it'
import MarkdownItEmoji from 'markdown-it-emoji'
import MarkdownItTasklists from 'markdown-it-task-lists'
import LazyImage from '@/components/common/markdownit/lazy.js'
import 'github-markdown-css'
import * as blobUtil from 'blob-util'
import Utils from '@/components/common/utils.js'
import Spaces from '@/components/services/spaces.js'
const DEFAULT_OPTIONS_TASKLISTS = null
export default {
  name: 'markdown-it-vue',
  props: {
    collection: Object,
    content: {
      type: String
    },
    options: {
      type: Object,
      default () {
        return {
          markdownIt: {
            html: true, // Enable HTML tags in source
            xhtmlOut: false, // Use '/' to close single tags (<br />).
            // This is only for full CommonMark compatibility.
            breaks: true,
            linkify: true
          },
          tasklists: DEFAULT_OPTIONS_TASKLISTS
        }
      }
    }
  },
  watch: {
    content: {
      immediate: true,
      async handler (val) {
        if (val) {
          var self = this
          this.$nextTick(async () => {
            if (!this.$refs['markdown-it-vue-container']) return
            this.$refs['markdown-it-vue-container'].innerHTML =
              this.md.render(val)
            // use native false
            var lazyEls = document.getElementsByClassName(self.selClass)
            for (const lazyEl of lazyEls) {
              var alt=lazyEl.getAttribute('alt')
              console.debug(
                'load blob image attachment',
                alt
              )
              try {
                if(alt=="file") continue;
                var baseImage = Spaces.getPreviewLink(
                  lazyEl.getAttribute('alt'),
                  300,
                  0
                )
                lazyEl.setAttribute('src', baseImage)
                lazyEl.setAttribute('data-src', baseImage)
              } catch (err) {
                console.debug('error no attachment found in markdown', err)
              }
            }
          })
        }
      }
    }
  },
  data () {
    const selClass = Utils.uuidv4()
    const optMarkdownIt = this.options.markdownIt
    const optTasklists = this.options.tasklists || DEFAULT_OPTIONS_TASKLISTS
    let md = new MarkdownIt(optMarkdownIt)
      .use(MarkdownItEmoji)
      .use(MarkdownItTasklists, optTasklists)
      .use(LazyImage, {
        useNative: this.useNative,
        selClass: selClass
      })
    return {
      md: md,
      useNative: false,
      selClass: selClass
    }
  },
  methods: {
    use (plugin, options) {
      this.md.use(plugin, options)
    },
    get () {
      return this.md
    },
    handelClick (e) {
      if (e.target.matches('.lazy')) {
        var alt=e.target.getAttribute('alt')
        console.debug('click on image item id', alt)
        /* if(alt=="file")
         Utils.downloadURI(e.target.getAttribute('src'))
        else
         Utils.downloadURI(Spaces.getPreviewLink(e.target.getAttribute('alt'))) */
      }
    }
  }
}
</script>

<style lange="scss">
</style>
