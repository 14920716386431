/**
 * Generic helpers for items, implemented as static functions
 *
 * Container: any item that contains other items
 * Root: any item that has no container
 *
 * @export
 * @class Item
 */

// eslint-disable-next-line no-unused-vars
const forceExport = 'fake' // to force export

export default class Item {
  constructor (item) {
    return item
  }

  // containers are any item that can contain other items
  static isContainer (item) {
    if (item.containerOf) return true
    // eslint-disable-next-line no-template-curly-in-string
    return item.of && item.of.container === '${_id}'
  }

  static isRoot (item) {
    return !item.container
  }
}
