<template>
  <!--  New collection modal  -->
  <v-dialog v-model="show" persistent max-width="550">
    <v-form v-model="isValid" ref="newcollectionform">
      <v-card>
        <v-card-title class="dialog-heading subtitle-1 font-weight-bold justify-center"
          >Create New Area</v-card-title
        >
        <v-row no-gutters class="px-6">
          <v-col cols="12">
            <v-text-field
              v-model="newCollection.title"
              label="Title"
              required
              :rules="titleRules"
              @keyup="generateCollectionID()"
            />
          </v-col>
          <v-col md="9" sm="7">
            <v-text-field
              v-model="newCollection.id"
              label="ID"
              required
              :rules="idRules"
              @keyup="generateCollectionUrl"
              @change="autogenerated = false"
            />
          </v-col>
          <v-col md="3" sm="5" class="mt-7 text-center">
            <span
              v-if="isValid"
              :class="
                newCollection.available == 'Available'
                  ? 'green--text'
                  : 'red--text'
              "
              >{{ newCollection.available }}</span
            >
          </v-col>
          <v-col cols="12" class="text-center mt-5">
            <h4>Do you want to allow this area to be seen by others?</h4>
            <span>Make this area private</span>
            <v-switch
              v-model="newCollection.isPrivate"
              class="ml-5 d-inline-block"
              label
            ></v-switch>
            <span class="d-block font-weight-bold"
              >(if public, anybody with the link below can view your
              area)</span
            >
          </v-col>
          <v-col cols="12" class="text-center mt-5">
            <span>
              Your area url:
              <b class="force-select">{{ newCollection.collectionurl }}</b>
            </span>
          </v-col>
        </v-row>
        <v-card-actions class="mt-2">
          <v-spacer></v-spacer>
          <v-btn
            @click="
              resetnewCollection(true);
              show = false;
            "
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :disabled="!isValid || this.newCollection.available != 'Available'"
            @click="
              createNewCollection();
              show = false;
            "
            >Create Area</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { Collection } from '@/collections'
import Utility from '@/components/common/utils.js'
export default {
  name: 'NewCollectionDialog',
  props: {
    value: Boolean,
    repo: Object,
    currentUser: Object
  },
  data: function () {
    return {
      isValid: false,
      titleRules: [(v) => !!v || 'Title is required'],
      idRules: [
        (v) => !!v || 'Title required',
        (v) =>
          /^[a-z](?:_?[a-z0-9-]+)*$/.test(v) ||
          'Id must be lowercase characters (a-z), digits (0-9), and single hyphens.It must begin with a letter, and cannot end in a hyphen'
      ],
      newCollection: {
        title: '',
        id: '',
        collectionurl: '',
        available: '',
        isPrivate: false
      },
      autogenerated: true,
      searchtimeout: null,
      utils: Utility
    }
  },
  components: {},
  created () {},
  methods: {
    generateCollectionID () {
      if (this.newCollection.title == '') {
        this.autogenerated = true
      }
      if (this.autogenerated) {
        this.newCollection.id = this.stringToSlug(this.newCollection.title)
        this.newCollection.available = ''
        this.generateCollectionUrl()
      }
    },
    async generateCollectionUrl () {
      clearTimeout(this.searchtimeout)
      var self = this
      this.searchtimeout = setTimeout(async function () {
        if (
          typeof self.newCollection.id !== 'undefined' ||
          self.newCollection.id !== ''
        ) {
          self.newCollection.available = ''
          console.log('testing existence in line 1209') // REVIEW
          var exists = await self.repo.contains(
            Collection.docIDFor(
              self.currentUser.name + '_' + self.newCollection.id
            )
          )
          console.debug('Collection exists', exists)
          self.newCollection.collectionurl =
            window.location.origin +
            '/' +
            self.repo.userID +
            '/' +
            self.newCollection.id
          self.newCollection.available = !exists
            ? 'Available'
            : 'Not Available'
        }
      }, 1000)
    },
    async createNewCollection () {
      const newCollectionID = this.utils.getdbid(
        this.currentUser.name,
        this.newCollection.id
      )
      const newCollectionDoc = {
        _id: Collection.docIDFor(newCollectionID),
        type: 'collection',
        '@dbid': newCollectionID,
        title: this.newCollection.title,
        private: this.newCollection.isPrivate,
        account: this.currentUser.name,
        name: this.newCollection.id
      }
      await this.repo.put(newCollectionDoc)
      this.$emit('createdCollection', newCollectionDoc)
      this.resetnewCollection(true)
    },
    resetnewCollection (resetvalidation = false) {
      if (resetvalidation) {
        this.$refs.newcollectionform.reset()
        this.$refs.newcollectionform.resetValidation()
      }
      this.newCollection = {
        title: '',
        id: '',
        available: '',
        isPrivate: false
      }
    },
    stringToSlug (str) {
      if (typeof str === 'undefined') return ''
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      var from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;'
      var to = 'aaaaaaeeeeiiiioooouuuunc------'

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes
        .replace(/^-+/, '') // trim - from start of text
        .replace(/-+$/, '') // trim - from end of text

      return str
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {}
}
</script>
<style scoped>
</style>
