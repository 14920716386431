<template>
  <v-snackbar :color="color" top v-model="showSnackbar">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" icon v-bind="attrs" @click="showSnackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'vtoast',
  data () {
    return {
      showSnackbar: false,
      message: '',
      timer: 3000,
      color: 'grey darken-2'
    }
  },
  methods: {
    show (data) {
      this.showSnackbar = true
      this.message = data.message || 'missing "message".'
      this.color = data.color || 'grey darken-2'
      this.timer = data.timer || 3000
    }
  }
}
</script>
