<template>
  <v-dialog v-model="show" persistent max-width="550">
    <v-card>
      <v-row no-gutters class="px-6">
        <v-col cols="12" v-if="OSType === 'iOS'">
          <div>
            <h2 class="text-center heading ma-3">Get the Jot iOS App</h2>
            <p>
              In your
              <strong>Safari</strong> web browser, simply tap the
              <v-icon class="mdi-rotate-270 mr-2" color="blue lighten-2"
                >mdi-logout</v-icon
              >button in the tool bar and choose
              <strong>"Add to Home Screen"</strong>.
            </p>
          </div>
        </v-col>
        <v-col cols="12" v-else-if="OSType === 'Android'">
          <div>
            <h2 class="text-center heading ma-3">Get the Jot iOS App</h2>
            <p>
              In your web browser, simply tap the
              <v-icon class="mr-2">mdi-dots-vertical</v-icon>button or the
              <strong>"More"</strong> button in the tool bar and choose
              <strong>"Add to Home screen"</strong>.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn @click="show = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'PWADialog',
  data: () => ({}),
  props: {
    value: Boolean,
    OSType: String
  },
  components: {},
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  created () {},
  mounted () {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
