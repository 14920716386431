<template>
  <div class="text-center galleryholder">
    <div v-if="item.type">
      <div v-if="item.type == 'link'">
        <AsyncImage :item="item" :height="height" :thumbSize="thumbSize" :cover="cover" />
      </div>
      <div v-else-if="item.type == 'file'">
        <div v-if="item.content_type && utils.isImage(item.content_type)">
          <AsyncImage
            :item="item"
            :height="height"
            :thumbSize="thumbSize"
            v-if="thumbnail"
            :borderRadius="borderRadius"
            :bottomRadius="bottomRadius"
            :cover="cover"
            
          />
          <div v-else-if="original" style="min-width: 300px" :id="itemKey">
            <div :id="itemKey + 'canvas'">
              <AsyncImage
                :item="item"
                :thumbSize="thumbSize"
                style="height: inherit; width: inherit"
                :borderRadius="borderRadius"
                :bottomRadius="bottomRadius"
                :cover="cover"
              />
            </div>
          </div>
          <div v-else :style="'height:' + height + ';'" :id="itemKey">
            <AsyncImage :item="item"  :thumbSize="thumbSize" :borderRadius="borderRadius"
            :bottomRadius="bottomRadius" :cover="cover"/>
          </div>
        </div>
        <div
          v-else-if="item.content_type && utils.isVideo(item.content_type)"
        >
           <div
            v-if="thumbnail"
            :borderRadius="borderRadius"
            :bottomRadius="bottomRadius"
          >
          <video :controls="controls" preload="auto" style="width:100%" :height="height" :src="space.getPreviewLink(item.space_key)"></video>
           </div>
          <div v-else-if="original" style="min-width: 300px" :id="itemKey">
           <video :id="itemKey + 'canvas'" :src="space.getPreviewLink(item.space_key)" preload="auto" controls></video>
          </div>
          <div v-else :style="'height:' + height + ';'" :id="itemKey">
            <AsyncImage :item="item"  :thumbSize="thumbSize" :borderRadius="borderRadius"
            :bottomRadius="bottomRadius" :cover="cover"/>
          </div>
        </div>
        <v-avatar
          v-else-if="item.content_type && utils.isAudio(item.content_type)"
          tile
          style="width: 150px; height: 150px"
        >
          <span class="heading">Audio</span>
        </v-avatar>
        <v-avatar v-else tile style="width: 150px; height: 150px">
          <span class="heading" v-if="item.name">{{
            utils.ext(item.name)
          }}</span>
        </v-avatar>
      </div>
      <v-btn
        v-if="downloadable"
        x-small
        fab
        color="grey"
        class="download-button"
        title="Download"
        :href="utils.getLink(item)"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>
    <v-avatar v-else tile style="width: 150px; height: 150px">
      <span class="heading">No Thumbnail</span>
    </v-avatar>
  </div>
</template>

<script>
import Utility from '@/components/common/utils.js'
import * as blobUtil from 'blob-util'
import AsyncImage from '@/components/AsyncImage.vue'
import Spaces from '@/components/services/spaces.js'
export default {
  name: 'GalleryPlaceholder',
  props: {
    item: Object,
    itemKey: String,
    areakey: String,
    parentKey: String,
    thumbSize: {
      type: Object,
      default: function () {
        return { width: 0, height: 0 }
      }
    },
    thumbnail: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    height: {
      type: String,
      default: function () {
        return 'auto'
      }
    },
    original: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    downloadable: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    borderRadius: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    bottomRadius: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    cover: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    controls: {
      type: Boolean,
      default: function () {
        return false
      }
    },
  },
  data: function () {
    return {
      utils: Utility,
      dataId: '',
      space:Spaces
    }
  },
  created () {},
  computed: {
    bgcolor () {
      return this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-1'
    }
  },
  destroyed () {
    window.addEventListener('resize', this.resizeCanvasArea)
    window.addEventListener('orientationchange', this.resizeCanvasArea)
  },
  mounted () {
    if (this.original) this.getoriginalImage(this.item.space_key, this.itemKey)
    window.addEventListener('resize', this.resizeCanvasArea)
    window.addEventListener('orientationchange', this.resizeCanvasArea)
  },
  methods: {
    getoriginalImage (key, id) {
      var self = this
      self.dataId = id + 'canvas'
      self.resizeCanvasArea()
    },
    resizeCanvasArea () {
      var canvas = document.getElementById(this.dataId)
      if (canvas !== null) {
        var canvasArea = document.getElementById(this.areakey)
        var wrh = canvas.width / canvas.height
        var newWidth = canvasArea.offsetWidth
        var newHeight = newWidth / wrh
        if (newHeight > canvasArea.offsetHeight) {
          newHeight = canvasArea.offsetHeight
          newWidth = newHeight * wrh
          canvas.style.width = newWidth + 'px'
          canvas.style.height = newHeight + 'px'
        } else {
          canvas.style.height = canvasArea.offsetHeight + 'px'
          canvas.style.width = 'auto'
        }
      }
    }
  },
  watch: {},
  components: { AsyncImage }
}
</script>

<style scoped>
.download-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
