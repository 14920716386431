<template>
  <div class="vue-lb-container" v-show="uploadDialog">
    <div class="ma-0 pa-0 parentcontainer">
      <v-card>
        <v-row class="pa-2 previewarea" justify="center" no-gutters>
          <FileAgent
            ref="fileagent"
            @sync="syncFileData"
            @syncThumb="syncFileThumb"
            @select="onSelect($event)"
            :multiple="multipleAttachment"
            :more="multipleAttachment"
            :sizeCheck="false"
            :editFileTitle="editFileTitle"
          />
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              :loading="uploading['small']"
              color="primary"
              class="mr-2 mb-2"
              v-show="anyImage"
              @click="upload('small')"
              >Upload Small</v-btn
            >
            <v-btn
              :loading="uploading['medium']"
              color="primary"
              class="mr-2 mb-2"
              v-show="anyImage"
              @click="upload('medium')"
              >Upload Medium</v-btn
            >
            <v-btn
              :loading="uploading['original']"
              color="primary"
              class="mr-2 mb-2"
              @click="upload('original')"
              v-show="anyImage"
              >Upload Original</v-btn
            >
            <v-btn
              :loading="uploading['original']"
              color="primary"
              class="mr-2 mb-2"
              @click="upload('original')"
              v-show="!anyImage"
              >Upload</v-btn
            >
            <v-btn @click.stop="cancel()" class="mr-2 mb-2">Cancel</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import FileAgent from '@/components/fileagent/FileAgent.vue'
import imageFileToBase64 from '@/components/common/imgBase64'
import Spaces from '@/components/services/spaces.js'
import Utils from '@/components/common/utils.js'
import _attachment from '@/components/common/attachment.js'
import Vue from 'vue'
export default {
  name: 'FileUploader',
  data: function () {
    return {
      item: { title: '' },
      fileData: [],
      uploading: [{ small: false }, { medium: false }, { original: false }],
      uploadDialog: false,
      column: null,
      selItem: null,
      isClicked: false,
      failMessage: 'Fail to upload file. Try again later.'
    }
  },
  props: {
    profileImage: {
      type: Boolean,
      default: false
    },
    emptyItem: {
      type: Boolean,
      default: false
    },
    groupBy: {
      type: String,
      default: 'none'
    },
    multipleItem: {
      type: Boolean,
      default: false
    },
    multipleAttachment: {
      type: Boolean,
      default: false
    },
    view: {
      type: Object,
      default: null
    },
    collection: Object,
    editFileTitle: {
      type: Boolean,
      default: false
    },
    manualOrder: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FileAgent
  },
  computed: {
    anyImage () {
      var hasImage = false
      if (this.fileData.length > 0) {
        for (const file of this.fileData) {
          const mimetype = file.file.type.split('/')
          const isImage = mimetype[0] === 'image'
          if (isImage) {
            hasImage = true
            break
          }
        }
      }
      return hasImage
    }
  },
  methods: {
    dragOver (event) {
      this.$nextTick(() => {
        this.$refs.fileagent.clear()
        this.$refs.fileagent.dragOver(event)
      })
    },
    dragEnter (event) {
      this.$nextTick(() => {
        this.$refs.fileagent.dragEnter(event)
      })
    },
    dragLeave (event) {
      this.$nextTick(() => {
        this.$refs.fileagent.dragOver(event)
      })
    },
    // this handel all drop event
    drop (event) {
      this.$nextTick(() => {
        this.$refs.fileagent.drop(event)
      })
    },
    click () {
      this.$nextTick(() => {
        this.$refs.fileagent.click()
      })
    },
    paste (file) {
      this.$nextTick(() => {
        this.$refs.fileagent.onPaste(file)
      })
    },
    syncFileData: function (value) {
      this.fileData = value
    },
    onSelect: function (filesDataNewlySelected) {
      this.uploadDialog = true
      var validFilesData = filesDataNewlySelected
      this.fileData = validFilesData
    },
    syncFileThumb: function (value) {
      var self = this
      var thumbData = value
      thumbData.forEach(function (file, index) {
        if (!file.medium) return
        var thumbnails = []
        var attachment = file.medium
        const mimetype = attachment.type.split('/')
        const filetype = attachment.type
        const isImage = mimetype[0] === 'image'
        if (isImage) {
          imageFileToBase64(attachment, 600, 600, 1).then(function (base64) {
            var i = new Image()
            i.onload = function () {
              thumbnails[0] = {
                attachment: base64,
                attachmentId: 'thumbnail600',
                type: filetype
              }
              if (!self.multipleAttachment) {
                self.item.attachmentSize600 = {
                  height: i.height,
                  width: i.width
                }
              }
            }
            i.src = base64
          })
          imageFileToBase64(attachment, 300, 300, 1).then(function (base64) {
            thumbnails[1] = {
              attachment: base64,
              attachmentId: 'thumbnail300',
              type: filetype
            }
          })
          imageFileToBase64(attachment, 150, 150, 1).then(function (base64) {
            thumbnails[2] = {
              attachment: base64,
              attachmentId: 'thumbnail150',
              type: filetype
            }
          })

          self.fileData[index].thumbnails = thumbnails
        }
      })
    },
    hasFile () {
      this.$nextTick(() => {
        if (!this.$refs.fileagent.hasFile()) {
          this.isClicked = false
        }
      })
    },
    async upload (sizeOption) {
      if (this.profileImage) {
        // upload profile image
        await this.uploadProfileImage(sizeOption)
      } else if (this.emptyItem) {
        // create new item with attachemnts used for blog and list layout create new item
        await this.uploadEmpty(sizeOption)
      } else if (this.selItem !== null) {
        // allow multiple attachemnt to an item because it has item id
        await this.uploadMultipleAttachment(sizeOption)
      } else if (this.multipleItem) {
        // create multiple item in gallery either drag drop or +button
        await this.uploadMultipleItem(sizeOption)
      } else if (this.multipleAttachment) {
        // create item with multiple attachment and item title is multiple file name wit comma seperator
        await this.uploadMultipleAttachment(sizeOption)
      } else {
        // create single item with attachement
        await this.uploadSingleItem(sizeOption)
      }
    },
    async uploadFileToServer (attachment, attachmentData, space_key) {
      var r = await Spaces.uploadFile(attachment, attachmentData, space_key)
      if (r.status == 201) {
        return true
      }

      this.$root.vtoast.show({
        message: this.failMessage
      })
      return false
    },
    async uploadSingleItem (sizeOption) {
      if (this.fileData.length > 0) {
        let success = false
        Vue.set(this.uploading, sizeOption, true)
        var fileUploaddata = this.selectedFile(this.fileData[0], sizeOption)
        var space_key = Utils.uuidv4() + '_' + fileUploaddata.name
        try {
          let item = {
            title: fileUploaddata.name,
            type: 'file',
            space_key: space_key,
            name: fileUploaddata.name,
            content_type: fileUploaddata.type,
            _id: Utils.uuidv4()
          }
          // add feature image as attachment
          this.fileData[0].attachmentData = {
            type: 'file',
            space_key: space_key,
            title: fileUploaddata.name,
            name: fileUploaddata.name,
            content_type: fileUploaddata.type,
            _id: Utils.uuidv4(),
            attached_to: item._id
          }
          success = await this.uploadFileToServer(
            fileUploaddata,
            this.fileData[0].attachmentData,
            space_key
          )
          if (success) {
            await this.view.put(item)
            await this.save(item, [this.fileData[0]])
          }
        } catch (error) {
          console.error(error)
          this.$root.vtoast.show({
            message: this.failMessage
          })
        }
      }
      this.reset()
    },
    generateTitles (fileData) {
      var filenames = ''
      for (const file of fileData) {
        filenames += file.file.name + ' '
      }
      return filenames
    },
    // attach multiple attachments to an item
    async uploadMultipleAttachment (sizeOption) {
      if (this.fileData.length > 0) {
        Vue.set(this.uploading, sizeOption, true)
        // if no item selected means this will create an item then attach multiple files to it
        // if there is selItem then we are trying to upload attament to an existing item
        // one item multiple attachment
        let item = {}
        if (this.selItem === null) {
          item = {
            _id: Utils.uuidv4(),
            title: this.generateTitles(this.fileData)
          }
          if (this.column !== null && this.groupBy !== 'none') {
            item[this.groupBy] = this.column._id
          }
          await this.view.put(item)
          if (this.manualOrder) {
            await this.view.setOrder(item)
          }
        } else {
          item = Object.assign({}, this.selItem)
        }
        try {
          let success = false
          for (const uploadFile of this.fileData) {
            var attachment = this.selectedFile(uploadFile, sizeOption)
            var space_key = Utils.uuidv4() + '_' + attachment.name
            uploadFile.attachmentData = {
              type: 'file',
              space_key: space_key,
              title: attachment.name,
              name: attachment.name,
              content_type: attachment.type,
              attached_to: item._id,
              _id: Utils.uuidv4()
            }
            success = await this.uploadFileToServer(
              attachment,
              uploadFile.attachmentData,
              space_key
            )
            if (!success) break
          }
          if (success) await this.save(item, this.fileData)
        } catch (error) {
          console.error(error)
          this.$root.vtoast.show({
            message: this.failMessage
          })
        }
      }
      this.reset()
    },
    // upload image to gallery by drag drop or click on + button
    async uploadMultipleItem (sizeOption) {
      // create item drag drop file
      if (this.fileData.length > 0) {
        try {
          Vue.set(this.uploading, sizeOption, true)
          let success = false
          for (const uploadFile of this.fileData) {
            let item = {}
            if (this.selItem === null) {
              item = {
                _id: Utils.uuidv4()
              }
            } else {
              item = Object.assign({}, this.selItem)
            }
            var fileUploaddata = this.selectedFile(uploadFile, sizeOption)
            // file name copy it can be editable from fileAgent.vue
            var filenameCopy = uploadFile.filenameCopy
            var space_key = Utils.uuidv4() + '_' + fileUploaddata.name
            // add feature image as attachment
            uploadFile.attachmentData = {
              type: 'file',
              space_key: space_key,
              title: fileUploaddata.name,
              name: fileUploaddata.name,
              content_type: fileUploaddata.type,
              attached_to: item._id,
              _id: Utils.uuidv4()
            }
            success = await this.uploadFileToServer(
              fileUploaddata,
              uploadFile.attachmentData,
              space_key
            )
            if (success) {
              if (this.column !== null && this.groupBy !== 'none') {
                item[this.groupBy] = this.column._id
              }
              if (this.selItem === null) {
                item.type = 'file'
                item.title = filenameCopy
                await this.view.put(item)
                if (this.manualOrder) {
                  await this.view.setOrder(item._id)
                }
                await this.save(item, [uploadFile])
              }
            } else {
              break
            }
          }
          if (this.selItem !== null && success) {
            let item = Object.assign({}, this.selItem)
            await this.save(item, this.fileData)
          }
        } catch (error) {
          console.error(error)
          this.$root.vtoast.show({
            message: this.failMessage
          })
        }
      }
      this.reset()
    },
    // upload image to an inline created item, only upload image information sent to item
    // which is going to be created
    async uploadEmpty (sizeOption) {
      if (this.fileData.length > 0) {
        Vue.set(this.uploading, sizeOption, true)
        try {
          for (const uploadFile of this.fileData) {
            var fileUploaddata = this.selectedFile(uploadFile, sizeOption)
            var space_key = Utils.uuidv4() + '_' + fileUploaddata.name
            // add feature image as attachment
            uploadFile.attachmentData = {
              type: 'file',
              space_key: space_key,
              title: fileUploaddata.name,
              name: fileUploaddata.name,
              content_type: fileUploaddata.type,
              _id: Utils.uuidv4()
            }
            var r = await this.uploadFileToServer(
              fileUploaddata,
              uploadFile.attachmentData,
              space_key
            )
            if (r) {
              this.$emit('syncfileData', {
                fileData: this.fileData,
                sizeOption: sizeOption
              })
            }
          }
        } catch (error) {
          console.error(error)
          this.$root.vtoast.show({
            message: this.failMessage
          })
        }
      }
      this.reset()
    },
    uploadProfileImage (sizeOption) {
      if (this.fileData.length > 0) {
        Vue.set(this.uploading, sizeOption, true)
        this.$emit('syncfileData', {
          fileData: this.fileData,
          sizeOption: sizeOption
        })
      }
      this.reset()
    },
    selectedFile (selFile, sizeOption) {
      var file = selFile.file
      if (Utils.isImage(file.type)) {
        if (sizeOption === 'small') {
          file = selFile.small
        } else if (sizeOption === 'medium') {
          file = selFile.medium
        }
      }
      return file
    },
    reset () {
      this.uploading = [
        { small: false },
        { medium: false },
        { original: false }
      ]
      this.uploadDialog = false
      this.fileData = []
      this.cancel()
    },
    cancel () {
      this.$refs.fileagent.clear()
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    async save (item, fileData) {
      if (this.fileData.length > 0) {
        await _attachment.add(item, fileData, this.collection)
      }
    }
  }
}
</script>
<style scoped>
.vue-lb-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
  -webkit-align-items: center;
  -moz-box-sizing: border-box;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
}
.parentcontainer {
  max-width: 900px;
  width: 900px;
}
.previewarea {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
