import { View } from '@/collections'
import { result } from 'lodash'

const Attachment = {
  /**
    * add any file as an ittachment to an item
    * @param {*} item
    * @param {*} attachment
    * @param {*} collection
    */
  add: async function (item, attachment, collection) {
    console.debug('add image as attachment', item, attachment)
    var attachmentView = await new View(collection).open({
      of: { attached_to: item._id }
    })
    for (var j = 0; j < attachment.length; j++) {
      await attachmentView.put(attachment[j].attachmentData)
    }
  },
  putUrl: async function (view, item, url, vm, showalert = true) {
    var attachmentData = {
      type: 'link',
      url: url,
      attached_to: item._id,
      status: 'request'
    }
    if (showalert) {
      const prompt =
        `Do you want to attach this link?<br/>
    <strong>"${url}"</strong>`
      const res = await vm.$confirm(prompt, {
        title: 'Attachment',
        buttonSaveText: 'Attach Link',
        buttonDiscardText: ''
      })
      if (res != 1) {
        return false
      }
    }
    var result = await view.put(attachmentData)
    return result
  }
}
export default Attachment
