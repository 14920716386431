<template>
  <v-dialog v-model="show" persistent max-width="540">
    <v-card>
      <v-card-title
        class="dialog-heading subtitle-1 font-weight-bold justify-center"
        >Channel Memberships</v-card-title
      >
      <div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Title</th>
                <th class="text-left">Invited By</th>
                <th class="text-left">Role</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="channel in channels" :key="channel._id">
                <td>
                  <v-icon
                    small
                    color="primary"
                    v-show="channel.status == 'bookmark'"
                    >mdi-bookmark</v-icon
                  >
                  {{ channel.title ? channel.title : channel["@dbid"] }}
                </td>
                <td>{{ channel.createdBy }}</td>
                <td>{{ getRole(channel.roles) }}</td>
                <td>
                  <v-btn
                    v-if="channel['@dbid'] != 'jot_info'"
                    fab
                    x-small
                    @click="deleteChannel(channel)"
                    title="Delete"
                    icon
                  >
                    <v-icon small color="red">mdi-close-circle</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="show = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utility from '@/components/common/utils.js'
export default {
  name: 'DeleteChannelsDialog',
  props: {
    value: Boolean,
    channels: Array,
    membershipsView: Object
  },
  data: function () {
    return {
      utils: Utility
    }
  },
  components: {},
  created () {},
  methods: {
    getRole (roles) {
      return roles ? roles.join() : ''
    },
    async deleteChannel (channel) {
      const repo = this.membershipsView.dataSource.repo
      const removed = await repo.removeItem(channel._id, this)
    }
  },
  computed: {
    allChannels () {
      return this.channels
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {}
}
</script>
<style scoped>
</style>
