<template>
  <v-dialog v-model="show" persistent max-width="450">
    <v-card>
      <v-card-title
        class="dialog-heading subtitle-1 font-weight-bold justify-center"
        >Create new collection</v-card-title
      >
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="item.title"
            label="Enter collection title"
            required
            :error-messages="errors"
            autofocus
          />
          <v-select
            label="Layout"
            :items="templates"
            item-text="type"
            v-model="item.default"
          />
          <text-area
            :autoheight="false"
            :authorization="authorization"
            v-model="item.body"
            @attachment="syncAttachment"
            :collection="collection"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn color="primary" @click="createNewView">Create Collection</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { View, Collection } from '@/collections'
import TextArea from '@/components/common/markdownit/text-area.vue'
import templates from '@/components/json/templates.json'
import _attachment from '@/components/common/attachment.js'
export default {
  name: 'NewViewDialog',
  props: {
    collection: Object,
    value: Boolean,
    repo: Object
  },
  data: function () {
    return {
      selectedCollection: null,
      templates: templates,
      item: { default: 'list', body: '', title: '' },
      errors: '',
      newViewRules: [
        (v) => !!v || 'Collection title is required',
        (v) => isValidTitle(v) || 'Collection title not valid'
      ],
      attachmentInfo: [],
      authorization: { update: true }
    }
  },
  components: { TextArea },
  async created () {
    const collectionID = this.collection['@dbid']
    this.selectedCollection = new Collection(this.repo, collectionID)
    await this.selectedCollection.open()
    this.view = await new View(this.selectedCollection).open('views')
    const escHandler = (e) => {
      if (e.key === 'Escape' && this.show) {
        this.cancel()
      }
    }
    document.addEventListener('keydown', escHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escHandler)
    })
  },
  async destroyed () {
    console.debug('Navigation panel collection close', this.selectedCollection)
    if (this.selectedCollection !== null && this.selectedCollection.isOpen) {
      await this.selectedCollection.close()
    }
  },
  methods: {
    cancel () {
      this.show = false
      this.error = ''
      this.item = { default: 'list', body: '', title: '' }
      this.reset()
    },
    reset () {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    },
    async createNewView () {
      var self = this
      if (!isValidTitle(this.item.title)) {
        this.errors = 'Collection title should be more than 1 character'
        return
      }
      // set default option
      this.filterData = {
        container: '${_id}'
      }
      this.item.groupBy = 'none'
      this.item.show_others = 'below'
      this.item.showDescription = false
      this.item.orderable = true
      this.item.thumbnail = false
      var listlayout = this.templates.find(
        (el) => el.type === self.item.default
      )
      this.item.layouts = [listlayout]
      this.item.of = this.filterData
      var result = await this.view.put(this.item)
      if (this.attachmentInfo.length > 0) {
        await _attachment.add(this.item, this.attachmentInfo, this.collection)
      }
      this.cancel()
      this.$emit('created', result)
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {}
}
function isValidTitle (s) {
  // title should be min 2 charecters
  return /^.{2,35}$/.test(String(s).toLowerCase())
}
</script>
<style scoped>
.custom-input-markdown-auto {
  padding: 15px 0 !important;
}
</style>
