import Vue from 'vue'
import Vuetify, {
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VToolbarTitle,
  VSpacer,
  VToolbarItems,
  VBtn,
  VIcon,
  VNavigationDrawer,
  VBadge,
  VDivider,
  VList,
  VTextField,
  VMain,
  VDialog,
  VCard,
  VCardTitle,
  VContainer,
  VSnackbar,
  VRow,
  VCol,
  VListItem,
  VTooltip,
  VListItemAction,
  VListItemActionText,
  VListItemContent,
  VListItemTitle,
  VSpeedDial,
  VMenu,
  VCardText,
  VCardActions,
  VCardSubtitle,
  VAvatar,
  VCheckbox,
  VFlex,
  VSelect,
  VRadioGroup,
  VRadio,
  VChip,
  VForm,
  VSlideYTransition,
  VLayout,
  VTextarea,
  VListItemSubtitle,
  VListItemAvatar,
  VSwitch,
  VHover,
  VExpandTransition,
  VCarousel,
  VCarouselItem,
  VSubheader,
  VSlideGroup,
  VSlideItem,
  VCombobox,
  VProgressCircular,
  VImg,
  VDatePicker,
  VOverlay,
  VSimpleTable
} from 'vuetify/lib'
import colors from 'vuetify/es5/util/colors'
Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VToolbarTitle,
    VSpacer,
    VToolbarItems,
    VBtn,
    VIcon,
    VNavigationDrawer,
    VBadge,
    VDivider,
    VList,
    VTextField,
    VMain,
    VDialog,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VContainer,
    VSnackbar,
    VRow,
    VCol,
    VListItem,
    VTooltip,
    VListItemAction,
    VListItemActionText,
    VListItemContent,
    VListItemTitle,
    VSpeedDial,
    VMenu,
    VCardText,
    VCardActions,
    VAvatar,
    VCheckbox,
    VFlex,
    VSelect,
    VRadioGroup,
    VRadio,
    VChip,
    VForm,
    VSlideYTransition,
    VLayout,
    VTextarea,
    VListItemSubtitle,
    VListItemAvatar,
    VSwitch,
    VHover,
    VExpandTransition,
    VCarousel,
    VCarouselItem,
    VSubheader,
    VSlideGroup,
    VSlideItem,
    VCombobox,
    VProgressCircular,
    VImg,
    VDatePicker,
    VOverlay,
    VSimpleTable
  }
})

export default new Vuetify({
  icons: {
    values: { // set icon as per type
      view: 'mdi-view-stream',
      heading: 'mdi-lifebuoy',
      steam: 'mdi-steam-box',
      task: 'mdi-format-list-checks',
      membership: 'mdi-account-check',
      user: 'mdi-account',
      subscription: 'mdi-bell-ring',
      subscription2: 'mdi-bell-ring-outline',
      comment: 'mdi-comment',
      collection: 'mdi-layers',
      unknown: 'mdi-checkbox-blank-circle',
      link: 'mdi-link',
      banner: 'mdi-flag',
      log: 'mdi-file-document'
    }
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: colors.blue.darken3,
        accent: colors.shades.black
      },
      dark: {
        primary: '#F35902',
        accent: colors.shades.white
      }
    }
  }
})
