import App from '@/App.vue'
import { Auth } from '@/collections'
import VuetifyConfirm from '@/components/common/confirm'
import VuetifyImagePreview from '@/components/common/imagepreview'
import MarkdownItVue from '@/components/common/markdownit'
import VisualIndicator from '@/components/VisualIndicator.vue'
import VisualTooltip from '@/components/VisualTooltip.vue'
import vuetify from '@/plugins/vuetify'
import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js'
import Vue from 'vue'
import VueDragDrop from 'vue-drag-drop'
import Draggable from 'vuedraggable'
import DatetimePicker from 'vuetify-datetime-picker'
import './registerServiceWorker'
import router from './router'

Sortable.mount(new AutoScroll())

Vue.config.productionTip = false
Vue.use(DatetimePicker)
Vue.use(VueDragDrop)
Vue.use(MarkdownItVue)
Vue.component('draggable', Draggable)
Vue.component('visual-indicator', VisualIndicator)
Vue.component('visual-tooltip', VisualTooltip)
Vue.use(VuetifyConfirm, {
  vuetify,
  color: 'primary',
  icon: 'mdi-information',
  buttonSaveText: 'Save',
  buttonDiscardText: 'Discard Changes',
  buttonCancelText: 'Cancel',
  title: 'Confirm dialog',
  property: '$confirm',
  persistent: true
})

Vue.use(VuetifyImagePreview, {
  vuetify,
  title: 'Confirm dialog',
  property: '$imagepreview',
  persistent: false
})

window.axios = require('axios')
axios.defaults.timeout = 0
axios.defaults.baseURL = 'https://file.jot.it/'
axios.interceptors.request.use(
  config => {
    let authSpec = Auth.get()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    return Promise.reject(error.response)
  }
)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  vuetify,
  router,
  render: h => h(App)
})
