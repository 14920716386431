import EXIF from 'exif-js'
import fileToBase64 from './file-to-base64'

const extractFileData = function (file) {
  return new Promise(function (resolve, reject) {
    EXIF.getData(file, function () {
      try {
        resolve(file)
      } catch (e) {
        reject(e)
      }
    })
  })
}

export default function (file) {
  return function (rawImage) {
    return extractFileData(file)
      .then(fixByOrientation)

    function fixByOrientation (file) {
      var orientation = EXIF.getTag(file, 'Orientation')
      if (typeof orientation === 'undefined') return fileToBase64(file)
      var isChrome = !!window.chrome
      if (isChrome) {
        orientation = 0
      }
      var isSafari = window.safari !== undefined
      if (isSafari) {
        orientation = 0
      }
      var iOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream
      if (iOS) {
          orientation = 0
      }

      const canvas = document.createElement('canvas')
      const fixedDimensions = getFixedCanvasDimensions(rawImage, orientation)

      canvas.width = fixedDimensions.width
      canvas.height = fixedDimensions.height

      const ctx = canvas.getContext('2d')
      ctx.translate(canvas.width * 0.5, canvas.height * 0.5)
      handleRotationAndFlip(ctx, orientation)
      ctx.translate(-rawImage.width * 0.5, -rawImage.height * 0.5)
      ctx.drawImage(rawImage, 0, 0)
      return canvas.toDataURL()
    }
  }
}

function getFixedCanvasDimensions (rawImage, orientation) {
  if (orientation >= 5) {
    return {
      width: rawImage.height,
      height: rawImage.width
    }
  }

  return {
    width: rawImage.width,
    height: rawImage.height
  }
}

function handleRotationAndFlip (ctx, orientation) {
  switch (orientation) {
    case 2:
      // horizontal flip
      ctx.scale(-1, 1)
      break
    case 3:
      // 180° rotate left
      ctx.rotate(Math.PI)
      break
    case 4:
      // vertical flip
      ctx.scale(1, -1)
      break
    case 5:
      // vertical flip + 90 rotate right
      ctx.rotate(0.5 * Math.PI)
      ctx.scale(1, -1)
      break
    case 6:
      // 90° rotate right
      ctx.rotate(0.5 * Math.PI)
      break
    case 7:
      // horizontal flip + 90 rotate right
      ctx.rotate(0.5 * Math.PI)
      ctx.scale(-1, 1)
      break
    case 8:
      // 90° rotate left
      ctx.rotate(-0.5 * Math.PI)
      break
  }
}
