<template>
  <v-dialog v-model="show" persistent max-width="450">
    <v-card>
      <v-card-title class="subtitle-1"
        >{{ editSubscriptionCollectionMode ? "Edit" : "Add" }} collection
        {{ editSubscriptionCollectionName }}</v-card-title
      >
      <v-row no-gutters class="px-6">
        <v-col cols="12">
          <v-text-field
            v-model="editSubscriptionCollection.title"
            label="Title"
            required
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="show = false">Cancel</v-btn>
        <v-btn
          color="primary"
          @click="
            saveCollection();
            show = false;
          "
          >{{ editSubscriptionCollectionMode ? "Update" : "Create" }} collection</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'SubscriptionDialog',
  data: () => ({
    editSubscriptionCollection: { title: '' },
    editSubscriptionCollectionName: '',
    editSubscriptionCollectionMode: false,
    dbUrl: 'https://api.grid22.com/'
  }),
  props: {
    value: Boolean,
    account: String,
    collectionID: String,
    subscriptionCollection: Object
  },
  components: {},
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  created () {
    if (this.subscriptionCollection) {
      this.editSubscriptionCollection = Object.assign(
        {},
        this.subscriptionCollection
      )
      this.editSubscriptionCollectionName = this.editSubscriptionCollection.title
      this.editSubscriptionCollectionMode = true
    }
  },
  mounted () {},
  methods: {
    // save new collection in collection menu
    async saveCollection () {
      throw new Error('NYI') // rewrite for memberships
      const subs = this.currentUser.subscriptions
      if (typeof this.editSubscriptionCollection._id === 'undefined') {
        // create a new subscription to put to the subscriptions list
        this.editSubscriptionCollection = {
          _id: '.subscription.' + this.collectionID,
          name: this.account
            ? this.account + '_' + this.collectionID
            : this.collectionID,
          account: this.account ? this.account : null,
          type: 'subscription',
          title: this.editSubscriptionCollection.title,
          url: this.account
            ? this.dbUrl + this.account + '/' + this.collectionID
            : this.dbUrl + this.collectionID
        }
      }
      subs.put(this.editSubscriptionCollection)
    }
  },
  watch: {}
}
</script>

<style scoped>
</style>
