/* VIEW SPECS */

const stockViews = {
  default: {
    title: 'Welcome to Jot',
    select: {
      type: 'banner',
      status: 'published'
    }
  },
  views: {
    select: { type: 'view' },
    // eslint-disable-next-line no-template-curly-in-string
    insert: { type: 'view', of: { container: '${_id}' }, select: {}, insert: {}, system: true },
    orderable: 'views'
  }
}

export default stockViews
