import Vue from 'vue'
export const OutsideClick = {
  bind (el, binding, vnode) {
    var vm = vnode.context
    var callback = binding.value

    el.outsideClickEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        return callback.call(vm, event)
      }
    }
    document.body.addEventListener('click', el.outsideClickEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.outsideClickEvent)
  }
}
Vue.directive('outside-click', OutsideClick)
