/**
 * Manages sync of channels
 */

import PouchDB from 'pouchdb'

export default class SyncCoordinator {
  constructor (db, remoteDB) {
    this.db = db
    this.remoteDB = remoteDB
    this.channelMap = new Map()
    this.channelSet = new Set()
  }

  async init (channels) {
    await this.request('main', channels, 5)
    this.outboundReplicator = PouchDB.replicate(
      this.db,
      this.remoteDB,
      { live: true, retry: true } // checkpoint: 'source', 'target', 'false'
    )
      .on('complete', i => console.debug('sync-out complete', i))
      .on('change', i => console.log('sync-out change', i))
      .on('denied', e => console.error('sync-out denied', e))
      .on('paused', i => console.debug('sync-out paused', i))
      .on('active', i => console.debug('sync-out resumed', i))
      .on('error', e => console.error('sync-out error', e))
  }

  async term () {
    if (this.outboundReplicator) {
      this.outboundReplicator.cancel()
    }
    if (this.inboundReplicator) {
      this.inboundReplicator.cancel()
    }

    this.channelMap.clear()
    this.channelSet.clear()

    delete this.db
    delete this.remoteDB
    delete this.channelMap
    delete this.channelSet
  }

  _startOutboundReplication () {
    return PouchDB.replicate(this.db, this.remoteDB,
      { live: true, retry: true } // checkpoint: 'source', 'target', 'false'
    )
      .on('complete', i => console.debug('sync-out complete', i))
      .on('change', i => console.log('sync-out change', i))
      .on('denied', e => console.error('sync-out denied', e))
      .on('paused', i => console.debug('sync-out paused', i))
      .on('active', i => console.debug('sync-out resumed', i))
      .on('error', e => console.error('sync-out error', e))
  }

  /**
   * - if same list of channels and inbound replication is complete, just return
   * - if inbound replication not yet complete, cancel and restart with new list
   * - if new channels or channels removed, cancel and restart
   *
   * REVIEW priority ignored for now
   *
   * @param {*} requestor
   * @param {*} channels
   * @param {*} priority
   */
  async request (requestor, channels, priority = 10) {
    this.channelMap.set(requestor, channels)
    return this.updateInboundReplication()
  }

  async release (requestor) {
    // if the channel map is gone, then we already destroyed this object
    if (!this.channelMap) return
    this.channelMap.delete(requestor)
    return this.updateInboundReplication()
  }

  /**
   * Called when the channel map is updated.   Based on the new map and sync
   * sync status, decide if we want to cancel and restart the inbound replication
   * or keep it as is.
   *
   * It will be somewhat smart and pull new channels first.
   *
   * @async
   */
  async updateInboundReplication () {
    const newChannelSet = new Set()
    const channelsToPull = new Set()

    // console.log("channelmap values", this.channelMap.values())

    for (const channels of this.channelMap.values()) {
      //      console.log("channels ..", channels)
      for (const channel of channels) {
        newChannelSet.add(channel)
        if (!this.channelSet.has(channel)) {
          channelsToPull.add(channel)
        }
      }
    }

    //    console.log("newchannelSet", newChannelSet)
    //    console.log("channelsToPull", channelsToPull)

    if (eqSet(this.channelSet, newChannelSet)) {
      console.debug('sync channel update computed no changes - returning')
      return
    }
    console.debug('sync channel update changed channels')
    this.channelSet = newChannelSet
    if (this.inboundReplicator) {
      console.info('repo cancelling prior inbound replication')
      let result = this.inboundReplicator.cancel()
      console.log('repo cancel inbound replication result', result)
      await result
    }
    await this.initialInboundReplication(Array.from(channelsToPull.values()))
    this.inboundReplicator = this.startLiveInboundReplicator(Array.from(this.channelSet.values()))
  }

  // initially do a non-live inbound replication for the requested channels

  async initialInboundReplication (channels) {
    if (channels.length === 0) {
      console.debug('sync no channels - not pulling')
      return
    }
    console.debug('sync initialInboundReplicaiton with channels', channels)
    return new Promise((resolve, reject) => {
      const opts = {
        filter: 'sync_gateway/bychannel',
        query_params: { channels: channels },
        retry: true
        //        checkpoints: false
      }
      const repl = PouchDB.replicate(this.remoteDB, this.db, opts)
        .on('complete', i => {
          console.debug('sync pull complete', channels)
          repl.cancel()
          return resolve(i)
        })
        .on('error', e => {
          repl.cancel()
          reject(e)
        })
    })
  }

  startLiveInboundReplicator (channels) {
    console.info('sync startLiveInboundReplicator channels', this, channels)
    if (channels.length === 0) {
      console.debug('sync no channels - no sync')
      return
    }
    const opts = {
      filter: 'sync_gateway/bychannel',
      query_params: { channels: channels },
      live: true,
      retry: true
      //      checkpoints: false,
    }
    return PouchDB.replicate(this.remoteDB, this.db, opts)
      .on('complete', i => console.debug('sync-in complete', i))
      .on('change', i => console.log('sync-in change', i))
      .on('denied', e => console.error('sync-in denied', e))
      .on('paused', i => console.debug('sync-in paused'))
      .on('active', i => console.debug('sync-in resumed', i))
      .on('error', e => console.error('sync-in error', e))
  }
}

function eqSet (as, bs) {
  if (as.size !== bs.size) return false
  for (var a of as) if (!bs.has(a)) return false
  return true
}
