<template>
  <div
    class="text-center"
    v-if="imageerror"
    :class="[{ borderRadius, bottomRadius }]"
  >
    <v-avatar
      tile
      :style="
        'min-height:50px;width:110px;height:' + height + ';line-height:10'
      "
    >
      <span class="heading">No Thumbnail</span>
    </v-avatar>
  </div>
  <v-img
    @error="hasError()"
    v-else
    :src="thumbImg"
    :alt="item.title"
    :class="[{ borderRadius, bottomRadius }]"
    :height="height"
    :contain="!cover"
    :aspect-ratio="cover?1:undefined"
    eager
  >
   <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
  </v-img>
</template>
<script>
import Spaces from '@/components/services/spaces.js'
export default {
  name: 'AsyncImage',
  components: {},
  props: {
    item: Object,
    thumbSize: {
      type: Object,
      default: function () {
        return { width: 0, height: 0 }
      }
    },
    borderRadius: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    bottomRadius: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    height: {
      type: String,
      default: function () {
        return 'auto'
      }
    },
    cover: {
      type: Boolean,
      default: function () {
        return false
      }
    },
  },
  data: function () {
    return {
      loading: true,
      thumbImg:
        'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8DABAAECAEDnPpQlwAAAABJRU5ErkJggg==',
      imageerror: false
    }
  },
  async created () {
    this.generateThumbnail(this.item, this.thumbSize)
  },
  mounted () {},
  computed: {},
  methods: {
    generateThumbnail (item, thumbSize) {
      this.thumbImg=Spaces.getPreviewLink(item.space_key, thumbSize.width, thumbSize.height)
    },
    hasError(){
      this.imageerror=true
    }
  },
  watch: {
    // add watch to check selected item changed and change layout accordingly
    item: function () {
      // this.generateThumbnail(this.item, this.thumbSize)
    }
  }
}
</script>
<style scoped>
.borderRadius {
  border-radius: 5px;
}
.bottomRadius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
</style>
