<template>
  <v-dialog v-model="show" persistent max-width="450">
    <v-card>
      <v-card-title class="dialog-heading subtitle-1 font-weight-bold justify-center"
        >Danger!</v-card-title
      >
      <v-row no-gutters class="px-6">
        <v-col cols="12">
          <div>
            This will remove all items, views, and memberships related to this
            area and delete them forever!
          </div>
          <br />
          <div class="d-block font-weight-bold">
            If you are SURE you want to proceed, copy or type the following
            area ID into the field below.
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <br />
          <span
            class="force-select"
            v-if="currentUser && deleteCollectionObj.id"
            >{{
              deleteCollectionObj.id.replace(currentUser.name + "_", "")
            }}</span
          >
          <v-text-field
            v-model="deletedCollectionId"
            label="Copy or type area ID here"
            required
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="
            deletedCollectionId = '';
            show = false;
          "
          >Cancel</v-btn
        >
        <v-btn color="primary" @click="deleteCollection()">Delete collection</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Collection } from '@/collections'
import Utility from '@/components/common/utils.js'
export default {
  name: 'DeleteCollectionDialog',
  props: {
    value: Boolean,
    repo: Object,
    currentUser: Object,
    collectionObj: Object
  },
  data: function () {
    return {
      deletedCollectionId: '',
      utils: Utility,
      deleteCollectionObj: {}
    }
  },
  components: {},
  created () {},
  methods: {
    async deleteCollection () {
      if (
        this.deleteCollectionObj.id !== '' &&
        this.deletedCollectionId !== '' &&
        this.deleteCollectionObj.id.replace(this.currentUser.name + '_', '') ==
          this.deletedCollectionId
      ) {
        var access = await this.utils.checkAccess(
          this.repo,
          this.deleteCollectionObj.id,
          'update'
        )
        if (access) {
          var result = await Collection.delete(
            this.repo,
            this.currentUser.name + '_' + this.deletedCollectionId
          )
          localStorage.removeItem('lastVisitedUrl')
          if (this.$route.fullPath !== '/') {
            this.$router.push('/')
          }
          this.deletedCollectionId = ''
          this.$emit('deleted')
        } else {
          this.$emit(
            'alert',
            "You don't have permission to delete this area"
          )
        }
      } else {
        this.$emit('alert', 'Please copy area ID to delete')
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.deleteCollectionObj = Object.assign(this.collectionObj)
      }
    }
  }
}
</script>
<style scoped>
</style>
