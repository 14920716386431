<template>
  <v-dialog
    eager
    :scrollable="false"
    @input="change"
    value="2"
    :max-width="width"
    :persistent="persistent"
    @keydown.esc="change(2)"
  >
    <v-card tile>
      <v-toolbar v-if="Boolean(title)" dark :color="color" dense flat>
        <v-icon v-if="Boolean(icon)" left>{{ icon }}</v-icon>
        <v-toolbar-title class="white--text" v-text="title" />
      </v-toolbar>
      <v-card-text class="body-1 py-3 text-center" v-html="message" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="Boolean(buttonCancelText)"
          :color="buttonCancelColor"
          @click.stop="choose(2)"
          >{{ buttonCancelText }}</v-btn
        >
        <v-btn
          v-if="Boolean(buttonDiscardText)"
          :color="buttonDiscardColor"
          @click.stop="choose(0)"
          >{{ buttonDiscardText }}</v-btn
        >
        <v-btn
          v-if="Boolean(buttonSaveText)"
          :color="buttonSaveColor"
          @click.stop="choose(1)"
          >{{ buttonSaveText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  VCard,
  VCardActions,
  VCardText,
  VDialog,
  VIcon,
  VToolbar,
  VToolbarTitle,
  VSpacer,
  VBtn
} from 'vuetify/lib'
export default {
  components: {
    VCard,
    VCardActions,
    VCardText,
    VDialog,
    VIcon,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VBtn
  },
  props: {
    buttonSaveText: {
      type: String,
      default: 'Save'
    },
    buttonCancelText: {
      type: String,
      default: 'Cancel'
    },
    buttonDiscardText: {
      type: String,
      default: 'Discard Changes'
    },
    buttonSaveColor: {
      type: String,
      default: 'primary'
    },
    buttonCancelColor: {
      type: String,
      default: ''
    },
    buttonDiscardColor: {
      type: String,
      default: 'grey'
    },
    color: {
      type: String,
      default: 'warning'
    },
    icon: {
      type: String,
      default () {
        return this.$vuetify.icons.values.warning
      }
    },
    message: {
      type: String,
      required: true
    },
    persistent: Boolean,
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 450
    }
  },
  data () {
    return {
      value: false
    }
  },
  mounted () {
    document.addEventListener('keyup', this.onEnterPressed)
  },
  destroyed () {
    document.removeEventListener('keyup', this.onEnterPressed)
  },
  methods: {
    onEnterPressed (e) {
      if (e.keyCode === 13) {
        e.stopPropagation()
        this.choose(1)
      }
    },
    choose (value) {
      this.$emit('result', value)
      this.value = value
      this.$destroy()
    },
    change (res) {
      this.$destroy()
    }
  }
}
</script>
