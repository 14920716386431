<template>
  <div class="list-item-container">
    <div
      class="list-item-head py-1 font-normal"
      :class="[{ over, 'selected-collection': selectedParent }]"
    >
      <drop
        class="drop list"
        @dragover="handleDragover(item, ...arguments)"
        @dragleave="handleLeave(item, ...arguments)"
        @drop="handleDrop(item, ...arguments)"
        :key="'dropview' + item._rev"
      >
        <div class="mx-5 d-flex">
          <router-link
            class="router-link toggle item-title div-left"
            exact
            :to="{
              name: 'list',
              params: {
                account: utils.splitdbid(item['@dbid'])[0],
                collectionID: utils.splitdbid(item['@dbid'])[1],
              },
            }"
          >
            <span
              @click="switchItem(item)"
              class="font-normal"
              :class="{ 'primary--text': selectedCollection }"
            >
              <v-icon small color="primary" v-show="item.status == 'bookmark'"
                >mdi-bookmark</v-icon
              >{{ item.title ? item.title : item["@dbid"] }}</span
            >
          </router-link>
          <span v-if="addnew"
            class="toggle div-right addnew"
            @click="addNewView(item)"
          >
            <v-btn icon x-small title="Add new collection">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </span>
          <span
            class="toggle div-right"
            @click="
              visible = !visible;
              switchParentItem(item);
            "
          >
            <v-btn icon small>
              <v-icon>{{ toggletText[visible * 1] }}</v-icon>
            </v-btn>
          </span>
        </div>
      </drop>
    </div>
    <div class="list-item-body" v-if="visible">
      <slot />
    </div>
  </div>
</template>
<script>
import Utility from '@/components/common/utils.js'
export default {
  name: 'LeftPanelOrganizer',
  props: ['repo', 'item', 'account', 'collectionID', 'perspective', 'addnew'],
  data () {
    return {
      toggletText: ['mdi-menu-right', 'mdi-menu-down'],
      color: ['nonecolor', 'grey lighten-2'],
      visible: false,
      utils: Utility,
      over: false,
      hoverVisible: false,
      hoverCount: 2000, // this count increase increase expand time
      isHover: false
    }
  },
  methods: {
    switchItem (item) {
      this.$emit('OnswitchCollection', item)
    },
    switchParentItem (item) {
      if (!this.visible && this.selectedCollection) {
        this.switchItem(item)
      }
      if (this.visible) {
        this.$emit('OnExpandCollection', item)
      }
    },
    handleDragover (item, event) {
      if (typeof event.source !== 'undefined') {
        if (this.visible) return
        this.isHover = true
        this.over = true
        setTimeout(() => {
          if (this.isHover) {
            this.visible = true
            this.hoverVisible = true
            this.over = false
          }
        }, this.hoverCount)
      } else if (typeof event.item !== 'undefined') {
        if (
          this.isSameCollection(item['@dbid'], event.item['@dbid']) ||
          this.visible
        ) { return }
        this.over = !this.visible
      }
    },
    async handleDrop (item, event) {
      console.debug('handleDrop', item, event)
      if (
        typeof event.source !== 'undefined'
      ) {
        // dropping an item (event.item) into a set in different collection
        this.over = false
        console.debug('dropping item', event.source, 'into set', item)
      } else if (typeof event.item !== 'undefined') {
        if (
          this.isSameCollection(item['@dbid'], event.item['@dbid']) ||
          this.visible
        ) { return }
        this.over = false

        // dropping an item into a different, closed collection, so prompt
        const sourceItem = event.item
        const destCollection = item
        const children = await this.repo.children(sourceItem._id)
        const childprompt = children.length > 0
          ? ` (and ${children.length} contained items)` : ''
        const prompt = `Moving this set ${childprompt} to a different collection
          (that may have different permissions).
          <br/><br/> <strong>Are you sure?</strong>`
        const res = await this.$confirm(prompt, {
          title: `Move ${children.length + 1} Items`,
          buttonSaveText: `Move Items`,
          buttonDiscardText: ''
        })
        if (res != 1) {
          // let moved = this.viewItems.splice(event.added.newIndex, 1);
          // this.$emit("revertDrag", moved[0]);
          return false
        }

        // user confirmed, so do the move
        const destDBID = destCollection['@dbid']
        const docs = children.concat(sourceItem)
        let i
        for (i in docs) {
          docs[i]['@dbid'] = destDBID
        }
        await this.repo.db.bulkDocs(docs)
      }
    },
    handleLeave (item, event) {
      if (typeof event.source !== 'undefined') {
        this.isHover = false
        this.over = false
      } else if (typeof event.item !== 'undefined') {
        if (
          this.isSameCollection(item['@dbid'], event.item['@dbid']) ||
          this.visible
        ) { return }
        this.over = false
      }
    },
    isSameCollection (itemdb, eventdb) {
      return itemdb == eventdb
    },
    addNewView (item) {
      this.$emit('add', item)
    }
  },
  computed: {
    selectedCollection () {
      if (
        this.item['@dbid'] ==
        this.utils.getdbid(this.account, this.collectionID)
      ) {
        return true
      }
      return false
    },
    selectedParent () {
      if (this.selectedCollection) {
        if (
          this.item.hasOwnProperty('defaultPage') &&
          this.item.defaultPage == this.perspective &&
          typeof this.$route.params.perspective === 'undefined'
        ) {
          return true
        } else if (this.perspective == 'views') {
          return true
        }
      }

      return false
    }
  },
  mounted () {
    if (
      this.item['@dbid'] ==
        this.utils.getdbid(this.account, this.collectionID) &&
      typeof this.$route.params.perspective !== 'undefined'
    ) {
      this.visible = true
    }
  }
}
</script>
<style scoped>
.div-left {
  flex: 1;
  align-items: center;
  vertical-align: middle;
  display: flex;
}

.div-right {
  flex: 0 0 10px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}
.list-item-container {
}
.theme--light .list-item-head:not(.selected-collection):hover {
  background-color: rgba(21, 101, 192, 0.2) !important;
}
.theme--dark .list-item-head:not(.selected-collection):hover {
  background-color: rgba(239, 108, 0, 0.2) !important;
}

.theme--light .selected-collection {
  background-color: rgba(21, 101, 192, 0.8) !important;
}
.theme--dark .selected-collection {
  background-color: rgba(239, 108, 0, 0.8) !important;
}
.selected-collection .primary--text {
  color: white !important;
}

.toggle {
  cursor: pointer;
}
.nonecolor {
}
.item-title {
  text-overflow: ellipsis;
}
/* this required because navigation borderSize is set to 1 */
.list-item-body,
.list-item-head {
  margin-right: 1px;
}
.over {
  background-color: #ccc;
}
.router-link {
  text-decoration: none;
  color: unset;
}
@media (pointer: fine) {
.addnew{
  display: none;
}
.list-item-head:hover .addnew{
  margin-top:2px;
  display: block;
}
}

</style>
