<template>
  <v-list dense class="pa-0 ma-0 small">
    <!--view list -->
    <draggable
      @start="dragStart"
      @end="dragEnd"
      :move="checkMove"
      :list="viewItems"
      :animation="200"
      ghost-class="ghost-card"
      group="list"
      handle=".list"
      @change="orderChange($event)"
      v-bind="dragOptions"
    >
      <template v-for="item in viewItems">
        <CollectionViewItem
          :item="item"
          :key="'droitem' + item._rev"
          :collection="collection"
          :disabledrop="disabledrop"
          :perspective="perspective"
          :ref="item._id"
        />
      </template>
    </draggable>
  </v-list>
</template>
<script>
import { View, Collection } from '@/collections'
import Utility from '@/components/common/utils.js'
import CollectionViewItem from '@/components/CollectionViewItem.vue'
export default {
  data: () => ({
    view: null,
    selectedCollection: null,
    utils: Utility,
    activeClass: '',
    disabledrop: false,
    oldIndex: 0
  }),
  props: [
    'selectedItem',
    'selectedView',
    'repo',
    'collection',
    'user',
    'perspective'
  ],
  components: { CollectionViewItem },
  async created () {
    const collectionID = this.collection['@dbid']
    this.selectedCollection = new Collection(this.repo, collectionID)
    await this.selectedCollection.open()
    this.view = await new View(this.selectedCollection).open({
      of: { type: 'view' },
      orderable: collectionID + '_views'
    })
  },
  async destroyed () {
    console.debug('Navigation panel collection close', this.selectedCollection)
    if (this.selectedCollection !== null && this.selectedCollection.isOpen) { await this.selectedCollection.close() }
  },
  mounted () {},
  methods: {
    dragStart (e) {
      console.debug('drag started')
      this.disabledrop = true
    },
    dragEnd (e) {
      console.debug('drag ended')
      document.body.click()
      this.disabledrop = false
    },
    checkMove: function (evt) {
      this.oldIndex = evt.draggedContext.index
      return true
    },

    // orderChange called when the left nav has drag within or between a collection.
    // within a collection has event.moved, and between generates an added/removed
    async orderChange (event) {
      console.debug('CollectionView orderChange', event)

      // moved is just a in same collection, ust call setOrder
      if (event.moved) {
        console.debug('moving root-item to new order in same collection, reordering')
        const element = event.moved.element
        const newIndex = event.moved.newIndex
        console.debug('setOrder', this, element._id, newIndex)
        this.view.setOrder(element._id, newIndex)
        return
      }

      // added is move into different collection, including children and confirm
      if (event.added) {
        console.debug('moving root-item to different collection (including children) - confirming')
        const element = event.added.element
        const newIndex = event.added.newIndex
        const children = await this.repo.children(element._id)
        const childprompt =
          children.length > 0
            ? ` (and ${children.length} contained items)`
            : ''
        const prompt = `Moving this ${childprompt} to a different collection (that may have different permissions).
        <br/><br/> <strong>Are you sure?</strong>`
        const res = await this.$confirm(prompt, {
          title: `Move ${children.length + 1} Items`,
          buttonSaveText: `Move Items`,
          buttonDiscardText: ''
        })
        if (res != 1) {
          let moved = this.viewItems.splice(event.added.newIndex, 1)
          this.$emit('revertDrag', moved[0])
          return false
        }
        await this.view.put(element) // sets @dbid of main element
        await this.view.setOrder(element._id, newIndex) // set the order
        // move the children over using bulkDocs
        const dbid = this.collection['@dbid']
        let i
        for (i in children) {
          children[i]['@dbid'] = dbid
        }
        await this.repo.db.bulkDocs(children)
      }
    }
  },
  computed: {
    viewItems () {
      return this.view !== null ? this.view.items : null
    },
    dragOptions () {
      return {
        delayOnTouchOnly: true,
        touchStartThreshold: 5,
        delay: 200
      }
    }
  },
  watch: {}
}
</script>
