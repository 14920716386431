<template>
  <div class="d-flex justify-center align-center">
    <div
      @dragover="dragOver"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @drop="drop"
      class="is-drag-valid"
      :class="[{ 'is-drag-over': isDragging }]"
    >
      <div @click="click">
        <v-avatar
          color="orange accent-1 avatar"
          size="200px"
          v-if="currentUser.name && show"
        >
          <AsyncAvatarImage
            :username="currentUser.name"
            :collection="collection"
          />
        </v-avatar>
        <v-avatar color="orange accent-1 avatar" size="200px" v-else
          ><img :src="cropImg" />
        </v-avatar>
        <div class="middle">
          <v-icon color="white" large>mdi-camera</v-icon>
          <small class="d-block white--text"
            >Drag drop image or click here to upload</small
          >
        </div>
      </div>
      <FileUploader
        ref="fileuploader"
        :profileImage="true"
        @syncfileData="syncfileData"
      />
    </div>
    <v-dialog v-model="cropDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="subtitle-1"></v-card-title>
        <v-row no-gutters class="px-6">
          <v-col cols="12" class="text-center">
            <vue-cropper
              v-if="fileData.length === 1"
              :key="'copper' + fileData[0].file.name"
              ref="cropper"
              :aspectRatio="1 / 1"
              :initialAspectRatio="1 / 1"
              :src="fileData[0].thumbnails[0].attachment"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cropDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            @click="
              cropDialog = false;
              cropImage();
              show = false;
            "
            >Crop</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import FileUploader from '@/components/FileUploader.vue'
import AsyncAvatarImage from '@/components/AsyncAvatarImage.vue'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  name: 'ProfileImageUpload',
  data: function () {
    return {
      isDragging: false,
      cropDialog: false,
      fileData: [],
      cropImg: '',
      show: true
    }
  },
  props: { currentUser: Object, collection: Object },
  components: { FileUploader, AsyncAvatarImage, VueCropper },
  computed: {},
  mounted: function () {},
  methods: {
    dragOver (event) {
      this.isDragging = true
      this.$refs.fileuploader.dragOver(event)
    },
    dragEnter (event) {
      this.isDragging = true
      this.$refs.fileuploader.dragEnter(event)
    },
    dragLeave (event) {
      this.isDragging = false
      this.$refs.fileuploader.dragLeave(event)
    },
    drop (event) {
      this.isDragging = false
      this.$refs.fileuploader.drop(event)
    },
    click () {
      this.$refs.fileuploader.click(event)
    },
    syncfileData (fileData) {
      this.fileData = fileData.fileData
      // this.sizeOption = fileData.sizeOption;
      this.cropDialog = true
    },
    cropImage () {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$emit('syncImage', this.cropImg)
    }
  }
}
</script>
<style scoped>
.file-input-wrapper {
  position: relative;
  border: none;
  min-height: 20px;
  padding: 0px;
}
.is-drag-valid {
  cursor: pointer;
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 5px;
  height: 210px !important;
  width: 210px !important;
  border-radius: 50%;
  background: rgba(250, 250, 250, 0.8);
}
.is-drag-valid.is-drag-over {
  box-shadow: inset 0px 0px 20px 1px #b4b6b3;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.is-drag-valid:hover .middle {
  opacity: 1;
}
</style>
