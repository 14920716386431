<template>
  <v-dialog v-model="show" persistent max-width="450">
    <v-card>
      <v-card-title
        class="dialog-heading subtitle-1 font-weight-bold justify-center"
        >User Settings</v-card-title
      >
      <v-row no-gutters class="px-6">
        <v-col cols="12" class="text-center">
          <ProfileImageUpload
            :currentUser="user"
            :collection="collection"
            @syncImage="syncImage"
          />
        <h3>{{user.name}}</h3>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="user.display" label="Full name" />
        </v-col>
        <v-col cols="12">
          <span class="d-block text-left">Bio</span>
          <text-area
            class="bio"
            ref="textarea"
            :enableMarkdown="true"
            v-model="user.bio"
            :autoheight="false"
            :authorization="{ update: true }"
            :generateThumbnail="false"
            :collection="collection"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="user.email"
            label="Email"
            :rules="emailRules"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="user.phone" label="Phone" />
        </v-col>
        <v-col cols="12">
          Features
          <v-switch
            inset
            v-model="features"
            :label="`Labs Mode: ${features ? 'ON' : 'OFF'}`"
          ></v-switch>
        </v-col>
        <v-col cols="12">
          <span class="d-block text-left">Invite device</span>
          <v-text-field label="Send link" color="primary" v-model="sendLink">
            <template v-slot:append>
              <v-btn color="primary" class="ma-0" @click> Send </v-btn>
            </template>
          </v-text-field>

          <div class="text-center">
            <qrcode value="Hello, World!" :options="{ width: 200 }"></qrcode>
          </div>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()">Cancel</v-btn>
        <v-btn
          color="primary"
          @click="
            show = false;
            save();
          "
          >Save settings</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Qrcode from '@chenfengyuan/vue-qrcode'
import ProfileImageUpload from '@/components/fileagent/ProfileImageUpload.vue'
import TextArea from '@/components/common/markdownit/text-area.vue'

export default {
  name: 'UserSettings',
  data: () => ({
    cropimage: '',
    user: {},
    sendLink: '',
    emailRules: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    features: false
  }),
  props: {
    value: Boolean,
    currentUser: Object,
    collection: Object
  },
  components: { Qrcode, ProfileImageUpload, TextArea },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  async created () {
    this.user = Object.assign({}, this.currentUser)
    if (this.user.features) {
      this.features = true
    }
  },
  async mounted () {},
  methods: {
    async save () {
      if (this.features) {
        this.user.features = 'labs'
      } else {
        delete this.user.features
      }
      await this.collection.repo.put(this.user)
      if (this.cropimage != '') {
        await this.collection.repo.putAttachment(
          this.user,
          `${this.user.name}.jpg`,
          this.cropimage.replace(/^data:image\/[a-z]+;base64,/, ''),
          'image/jpg'
        )
      }
      this.cropimage = ''
    },
    syncImage (image) {
      this.cropimage = image
    },
    cancel () {
      this.features = false
      this.show = false
      this.cropimage = ''
    }
  },
  watch: {}
}
</script>

<style scoped>
.custom-input:focus {
  outline: 1px !important;
}
</style>
