
/**
 * API
 *
 * Provides an abstraction layer to access the GRID22 API server.
 *
 * @module GridAPI
 */

export default class GridAPI {
  static async v1 (method, location, args = {}) {
    return new Promise(function (resolve, reject) {
      const xhr = new XMLHttpRequest()
      const url = 'https://api.jot.it' + location
      console.debug(method, url)
      xhr.open(method, url, true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.setRequestHeader('Accept', 'application/json')
      xhr.withCredentials = true
      xhr.onload = function () { resolve(xhr) }
      xhr.onerror = function () {
        reject(Error.new({ status: xhr.status, statusText: xhr.statusText }))
      }
      const jsonArgs = JSON.stringify(args)
      console.log('requesting args', jsonArgs)
      xhr.send(jsonArgs)
    })
  }
}
