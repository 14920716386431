
/**
 * Auth
 *
 * Helper functions (all static) for managing authentication for users.
 *
 * @module Auth
 */

// import GridAPI from './gridapi.js'

const localAuthKey = '__g22_auth__'

export default class Auth {
  /**
   * Retrieves the singleton Auth object, setting one up
   * if it does not exist.
   *
   * @static
   * @memberof User
   * @returns {Object | null} Current value of Auth.current
   */

  static get () {
    const authString = localStorage.getItem(localAuthKey)
    return authString ? JSON.parse(authString) : null
  }

  /**
   * Sets the "persistent" authentication document to have the specified
   * parameters (spec), storing it in the client.
   *
   * @static
   * @param {*} authSpec
   * @memberof Auth
   */

  static set (authSpec) {
    localStorage.setItem(localAuthKey, JSON.stringify(authSpec))
  }

  /**
   * Remove the "current" authentication document from local storage.
   * This is equivalent to "logging out" the current browser.
   *
   * @static
   * @memberof Auth
   */

  static clear () {
    localStorage.removeItem(localAuthKey)
  }
}
