<template>
  <drag :transfer-data="{ item }" @dragstart="dragStart" @dragend="dragEnd">
    <div>
      <router-link class="router-link" exact :to="{ name: 'list', params: {perspective: item._id,
          account: param[0],
          collectionID:param[1] } }">
      <drop
        class="drop list"
        :class="[
          {
            'item-active': isActive,
            over: over,
          },
        ]"
        @dragover="handleDragover(item, ...arguments)"
        @dragleave="handleLeave(item, ...arguments)"
        @drop="handleDrop(item, ...arguments)"
        :key="'drop' + item._rev"
      >
        <div class="pl-3 mx-5 my-1 d-flex itemdiv">
          <div class="div-item font-normal">
            {{ item.title }}
          </div>
        </div>
      </drop>
      </router-link>
    </div>
  </drag>
</template>
<script>
import Utility from '@/components/common/utils.js'
export default {
  name: 'CollectionViewItem',
  data: () => ({
    utils: Utility,
    over: false
  }),
  props: ['item', 'collection', 'disabledrop', 'perspective'],
  components: {},
  async created () {},
  destroyed () {},
  mounted () {},
  methods: {
    dragStart (e) {},
    dragEnd (e) {},
    handleDragover (destinationView, movedItem, event) {
      this.over = !this.disabledrop
      this.$root.$children[0].handleDragover(destinationView, movedItem, event)
    },
    async handleDrop (destinationView, movedItem, event) {
      await this.$root.$children[0].handleDrop(
        destinationView,
        movedItem,
        event
      )
      this.over = false
      // if hover open then collapse if drop complete
      if (this.$parent.$parent.$parent.$parent.hoverVisible) { this.$parent.$parent.$parent.$parent.visible = false }
    },
    handleLeave (destinationView, movedItem, event) {
      this.over = false
    },

    routeToPage (item) {
      var idSegments = this.utils.splitdbid(this.collection['@dbid'])
      this.$router.push({
        name: 'list',
        params: {
          perspective: item._id,
          account: idSegments[0],
          collectionID: idSegments[1]
        }
      })
    }
  },
  computed: {
    param () {
      return this.utils.splitdbid(this.collection['@dbid'])
    },
    isActive () {
      return (
        this.perspective == this.item._id &&
        typeof this.$route.params.perspective !== 'undefined'
      )
    },
    dragOptions () {
      return {
        delayOnTouchOnly: true,
        touchStartThreshold: 4,
        delay: 200
      }
    }
  },
  watch: {}
}
</script>
<style scoped>
.over {
  background-color: #ccc;
}

.theme--light .list:not(.item-active):hover {
  background-color: rgba(21, 101, 192, 0.2) !important;
}
.theme--dark .list:not(.item-active):hover {
  background-color: rgba(239, 108, 0, 0.2) !important;
}
.theme--light .item-active {
  background-color: rgba(21, 101, 192, 0.8) !important;
}
.theme--dark .item-active {
  background-color: rgba(239, 108, 0, 0.8) !important;
}
.list {
  min-height: 30px !important;
  cursor: pointer;
  flex: 1;
  align-items: center;
  vertical-align: middle;
  display: flex;
}
.router-link{
	    text-decoration: none;
    color: unset;
}
.div-item{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em;
    max-height: 3em;
}
</style>
