<template>
  <v-list-item
    :key="'commentItem' + editItem._rev"
    @click.stop
    class="comment-section"
    :ripple="false"
  >
    <v-list-item-avatar
      color="orange accent-1"
      :title="userInfo.display"
      v-if="editItem.createdBy"
    >
      <AsyncAvatarImage
        :username="editItem.createdBy"
        :collection="collection"
      />
    </v-list-item-avatar>
    <v-list-item-avatar color="grey" v-else></v-list-item-avatar>
    <v-list-item-content v-if="editmode && currentUser">
      <v-row>
        <v-col cols="12" class="ma-0 py-0">
          <comment-text
            :currentUser="currentUser"
            v-model="editItem.text"
            @attachment="syncAttachment"
            :elementid="'commentText' + editItem._id"
          />
        </v-col>
        <v-col cols="12" class="text-end">
          <v-btn
            @click.stop="
              editmode = false;
              resetItem();
            "
            >Cancel</v-btn
          >
          <v-btn
            class="ml-2"
            color="primary"
            @click.stop="
              editmode = false;
              save();
            "
            >Update comment</v-btn
          >
        </v-col>
      </v-row>
    </v-list-item-content>
    <v-list-item-content v-else>
      <v-list-item-subtitle style="line-height: 30px" v-if="editItem.createdAt">
        <span class="mr-1 font-weight-bold username-text">{{
          editItem.createdBy
        }}</span
        >{{
          utils.formatTimefromNow(editItem.createdAt, "DD/MMM/YYYY hh:mm A")
        }}
        <v-btn
          icon
          x-small
          class="ml-2 hidebtn"
          title="Edit comment"
          @click.stop="editmode = true"
          v-if="authorization.comment"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          x-small
          title="Delete comment"
          v-if="authorization.comment"
          class="hidebtn"
          @click.stop="remove()"
        >
          <v-icon color="red">mdi-close-circle</v-icon>
        </v-btn>
      </v-list-item-subtitle>
      <v-list-item-title>
        <markdown-it-vue class="md-body" :content="editItem.text"
      /></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import AsyncAvatarImage from '@/components/AsyncAvatarImage.vue'
import Utility from '@/components/common/utils.js'
import { Auth } from '@/collections'
import CommentText from '@/components/common/markdownit/comment-text.vue'
import _attachment from '@/components/common/attachment.js'
export default {
  name: 'CommentItem',
  props: {
    item: Object,
    view: Object,
    collection: Object,
    authorization: Object
  },
  data: function () {
    return {
      utils: Utility,
      editmode: false,
      menu: false,
      editItem: null,
      userInfo: {},
      attachmentInfo: []
    }
  },
  async created () {
    this.resetItem()
    this.userInfo = await this.utils.getUserDetails(
      this.collection,
      this.editItem.createdBy
    )
  },
  methods: {
    remove () {
      const repo = this.view.dataSource.repo
      repo.removeItem(this.item._id, this) // promise intentionally ignored
    },
    async save () {
      await this.view.put(this.editItem)
      if (this.attachmentInfo.length > 0) {
        await _attachment.add(
          this.editItem,
          this.attachmentInfo,
          this.collection
        )
        this.attachmentInfo = []
      }
    },
    resetItem: function () {
      this.editItem = Object.assign({}, this.item)
    },
    syncAttachment (value) {
      for (var i = 0; i < value.length; i++) {
        this.attachmentInfo.push(value[i])
      }
    }
  },
  computed: {
    currentUser () {
      return Auth.get()
    }
  },
  components: { AsyncAvatarImage, CommentText }
}
</script>
<style scoped>
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal;
}
.v-list-item {
  align-items: unset;
}
.v-list-item__avatar {
  align-self: unset;
}
.hidebtn {
  display: none;
}
.comment-section:hover .hidebtn {
  display: inline-block;
}
.theme--dark.v-list-item .v-list-item__subtitle .username-text {
  color: #fff;
}
</style>
