import MiniSearch from 'minisearch'
import moment from 'moment'
import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'
import { SyncCoordinator } from '.'

let singletonOpenRepo = null

PouchDB.setMaxListeners(128) // REVIEW we are heavy users of event emitters
PouchDB.plugin(PouchDBFind)

/**
 * A Repo represents a Jot database as accessed by a paritcular user.
 *
 * - holds the current userID
 * - can get the user profile object, user's membership, and user's den info
 * - delegates to SyncManager to manages a set of subscribed/sync'd channels
 * - allows dependents to request to be informed when the Repo changes
 */

export default class Repo {
  /**
   * Opens a Repo (connection to the database as a particular user, or as anonymous).
   *
   * If username is passed, then password must be valid or open() fails.
   * If no username is passed then the Repo is opened in anonymous mode.
   *
   * @param {String} url          URL of the database sync gateway
   * @param {String?} userID      ID of the current user (stored)
   * @memberof Repo
   */

  constructor (url, userID = null) {
    this.isOpen = false
    this.userID = userID
    this.url = url
    this.repo = this // conform with datasource interface
  }

  /**
   * Open a Repo, and sync a list of channels.
   */

  async open (channels = []) {
    // enforce one-repo-singleton at this point (may change in future)
    if (singletonOpenRepo) {
      console.error('Tried to open more than one repo!')
      throw new Error('Only one Repo can be open at a time!')
    }
    singletonOpenRepo = this
    try {
      await this._attemptOpen(channels)
      return this
    } catch (err) {
      singletonOpenRepo = null
      throw err
    }
  }

  async _attemptOpen (channels) {
    // setup the repo to open
    this.deps = new Set()
    this.index = new MiniSearch({
      idField: '_id',
      fields: ['title', 'description', 'body'],
      storeFields: ['title', 'type', '@dbid']
    })

    this.remoteDB = new PouchDB(this.url)
    const urlHash = this.url.split('').reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0).toString(16)
    const localDbName = `db-${urlHash}-${this.userID || 'anonymous'}`
    this.db = new PouchDB(localDbName)

    // hack to work around occasional hang due to some race condition in pouchDB.
    // if the following await doesn't return then we reload the page
    const pouchRaceHackTimer = setTimeout(function () { window.location.reload() }, 1000)
    console.debug('setting 500ms reload timer to hack around pouch race')
    this.dbInfo = await this.db.info() // commented out as it seemed to hang unexpectedly
    console.debug('local db', this.dbInfo)
    clearTimeout(pouchRaceHackTimer)
    console.debug('cancelling timer (hack not needed)')

    try {
      this.remoteDBInfo = await this.remoteDB.info()
      console.debug('remote db', this.remoteDBInfo)
    } catch (err) {
      console.error("couldn't open remote db", err)
      console.error('auth is', this.auth)
      await this.db.close()
      await this.remoteDB.close()
      await this.close()
      throw err
    }

    console.log('indexing local db')
    const pouchIndexStartTime = Date.now()
    await this.ensureIndex(['type'])
    await this.ensureIndex(['container'])
    await this.ensureIndex(['@dbid'])
    await this.ensureIndex(['@dbid', 'type'])
    await this.ensureIndex(['@dbid', 'container'])

    const syncStartTime = Date.now()

    // setup sync coordinator and request initial channels
    this.sync = new SyncCoordinator(this.db, this.remoteDB)
    await this.sync.init(channels)

    // init the search index
    const fetchStartTime = Date.now()
    const result = await this.db.allDocs({ include_docs: true })
    const allDocs = result.rows.map(row => row.doc)
    const indexStartTime = Date.now()
    await this.index.addAllAsync(allDocs)

    console.log('property index time (ms)', syncStartTime - pouchIndexStartTime)
    console.log('initial sync time (ms)', fetchStartTime - syncStartTime)
    console.log('search fetch time (ms)', indexStartTime - fetchStartTime)
    console.log('search index time (ms)', Date.now() - indexStartTime)

    this.db.setMaxListeners(128)

    // start change handler for this channel
    this._changes = this.db.changes({
      since: 'now',
      include_docs: true,
      live: true
    })

    // distribute changes in this datasource to all deps (dependents)
    this._changes.on('change', (change) => {
      //      console.log("repo change:", change, this.deps)
      if (!change.deleted) {
        this.index.add(change.doc)
      }
      for (const dep of this.deps) {
        dep.onChange(change)
      }
    })

    this.isOpen = true
    return this
  }

  async ensureIndex (fields) {
    console.log('indexing', fields)
    try {
      await this.db.createIndex({ index: { fields: fields } })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Close the Repo, terminating any synchronization & changefeeds,
   * and closing all views.
   * @memberof Repo
   * @async
   */

  async close () {
    if (this !== singletonOpenRepo) throw new Error('close() called on wrong Repo!')
    if (!this.isOpen) throw new Error('close() called on closed Repo')

    if (this._changes) this._changes.cancel()
    for (const dep of this.deps) {
      dep.close()
    }
    if (this.sync) this.sync.term()
    try {
      await this.db.close()
    } catch (err) {
      // console.log('error on db close', err)
    }
    this.isOpen = false
    singletonOpenRepo = null
    console.log('closed Repo')
    return this
  }

  async get (itemID, ...options) {
    return this.db.get(itemID, ...options)
  }

  async put (item) {
    this.updateStamps(item)
    const result = await (item._id ? this.db.put(item) : this.db.post(item))
    item._id = result.id
    item._rev = result.rev
    return result
  }

  async putRaw (item) {
    const result = await this.db.put(item)
    console.debug('Putraw', item, result)
    return result
  }

  async contains (docID) {
    try {
      await this.db.get(docID)
      return true
    } catch (err) {
      return false
    }
  }

  async find (finder) {
    return this.db.find(finder)
  }

  /**
   * Attach something to an Item in this collection, modifying the item,
   * and updating its _rev.   Returns a promise that resolves to a
   * ChangeResult.
   *
   * @memberof Collection
   *
   * @param {*} item
   * @param {*} attachmentId  eg 'foo.jpg'
   * @param {*} attachment    Either Blob, Buffer, or Base64
   * @param {*} contentType   eg 'text/plain' or 'image/jpeg'
   *
   * @returns {Promise<Revision>} New Revision & New Revision
   */

  async putAttachment (item, attachmentId, attachment, contentType) {
    const result = await this.db.putAttachment(
      item._id,
      attachmentId,
      item._rev,
      attachment,
      contentType
    )

    // get the item again so we can update the attachment on the original
    const updatedItem = await this.db.get(item._id)
    const newAttachment = updatedItem._attachments[attachmentId]
    if (newAttachment) {
      if (!item._attachments) item._attachments = {}
      // REVIEW should this be Vue.set?
      item._attachments[attachmentId] = newAttachment
    }
    item._rev = result.rev
    return result.rev
  }

  /**
   * Get an attachment from an item or item id
   *
   * @param {string|object} itemOrId  Either an Item or and Item ID
   * @param {*} attachmentId eg "test.txt" or "thumbnail.img"
   * @returns {Promise<Blob|Buffer>} The attachment as Blob or Buffer
   * @memberof Collection
   */
  async getAttachment (itemOrId, attachmentId) {
    const id = (typeof (itemOrId) === 'object' ? itemOrId._id : itemOrId)
    return this.db.getAttachment(id, attachmentId)
  }

  /**
   * Remove the attachment from the item, modifying the item, and updating
   * its revision (_rev).   The attachment is removed from the item.
   *
   * @param {*} item
   * @param {*} attachmentId
   * @memberof Collection
   * @returns {Promise<Revision} OK and new Item Revision
   */
  async removeAttachment (item, attachmentId) {
    const result = await this.db.removeAttachment(
      item._id,
      attachmentId,
      item._rev
    )
    // REVIEW should following be Vue.delete?
    delete item._attachments[attachmentId]
    item._rev = result.rev
    return result.rev
  }

  /**
   * Update the stamps on an item <i> with the current time
   */
  updateStamps (i) {
    const t = moment().format()
    if (!i.createdAt) i.createdAt = t
    i.updatedAt = t
    if (this.userID) {
      if (!i.createdBy) i.createdBy = this.userID
      i.updatedBy = this.userID
    }
  }

  async children (id, fields) {
    if (typeof id !== 'string') throw new Error('id must be a string')
    let opts = { selector: { container: id } }
    if (fields) {
      opts.fields = fields
    }
    try {
      const result = await this.db.find(opts)
      if (result.warning) {
        console.warn(result.warning)
      }
      const docs = result.docs
      if (docs.length === 0) {
        return []
      }
      const children = (await Promise.all(docs.map(d => this.children(d._id, fields)))).flat()
      return docs.concat(children)
    } catch (err) {
      console.error(err)
      return []
    }
  }

  /**
   * Deletes an item recursively
   * If given vm uses the vm.$confirm to present confirmation dialog
   * If no confirmation wanted, MUST be called with vm = false
   *
   * Example:
   *
   * REVIEW - Bulk deletion of items with external attachments need to have space storage
   *          removed as well, or default to garbage collection
   *
   * @param {*} ds
   * @param {*} id
   * @param {*} opts
   * @returns  false if confirmation failed, or count of items acted upon
   */

  async removeItem (id, vm) {
    if (typeof id !== 'string') throw new Error('id must be a string')
    const item = await this.db.get(id)
    const children = await this.children(id, ['_id', '_rev'])

    // handle confirmation if the user gave us a vue vm
    if (vm) {
      const childPrompt = children.length > 0
        ? `<br/>(and ${children.length} contained items/comments/attachments)` : ''
      const prompt =
        `Deleting "${item.title}"${childPrompt}<br/><br/>
        <strong>Are you sure?</strong>`
      const response = await vm.$confirm(prompt, {
        title: '',
        buttonSaveText: 'Delete',
        buttonDiscardText: ''
      }
      )
      if (response !== 1) { // user cancelled
        return false
      }
    } else if (vm !== false) {
      throw new Error('must specify vm for confirmation or explict false')
    }

    // actually do the delete
    let items = children.concat(item)
    for (const i in items) {
      items[i]._deleted = true
    }
    await this.db.bulkDocs(items)
    console.debug(`deleted ${items.length} items`)
    return true
  }
}
