import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/about',
      name: 'about',
      component: function (resolve) {
        require(['@/components/About.vue'], resolve)
      }
    },
    {
      path: '/:profilename/_profile',
      name: 'profile',
      component: function (resolve) {
        require(['@/components/Profile.vue'], resolve)
      }
    },
    {
      path: '/:account?/:collectionID?/:perspective?/:mode?',
      name: 'list',
      component: function (resolve) {
        require(['@/components/List.vue'], resolve)
      }
    }

  ]
})
