<template>
  <v-app id="jot">
    <v-navigation-drawer
      ref="drawer"
      :width="navigation.width"
      v-model="navigation.shown"
      mobile-breakpoint="769"
      :disable-resize-watcher="true"
      :touchless="true"
      class="d-print-none"
      app
      v-outside-click="gotoOrganizerPanel"
      :temporary="false"
      height="100%"
    >
     <template v-slot:prepend>
      <v-row no-gutters class="ma-2">
        <v-col>
          <v-btn icon @click.stop="showHideDrawer()"
            ><v-icon>mdi-menu</v-icon></v-btn
          >
        </v-col>
        <v-col>
          <v-btn icon @click="tabIndex = 0">
            <v-icon>mdi-box-shadow</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <v-btn icon @click="tabIndex = 1">
            <v-icon id="searchbtn">mdi-magnify</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="utils.featureIsEnabled(currentUser)">
          <v-btn icon @click="tabIndex = 2">
            <v-badge
              color="green"
              :content="notifications.length"
              :value="notifications.length"
              overlap
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </v-col>
      </v-row>
     </template>

      <div class="tab0" v-show="tabIndex==0">
      <!--inbox -->
      <v-list nav dense shaped>
        <drop
          :class="selectedItem === 'inbox' ? 'allowed' : ''"
          @dragleave="selectedItem = ''"
          @dragover="handleDragover('inbox', ...arguments)"
          @drop="handleDrop('inbox', ...arguments)"
        >
          <v-list-item
            v-if="currentUser"
            exact
            :to="{
              name: 'list',
              params: { account: account, collectionID: collectionID, perspective: '_inbox' },
            }"
            @click="openCollection()"
            class="list"
          >
            <v-list-item-title class="font-normal">
              <v-icon small class="mr-1">mdi-inbox-arrow-up</v-icon>Inbox
            </v-list-item-title>
          </v-list-item>
        </drop>
      </v-list>
      <div v-if="collections">
      <v-icon class="ma-1 ml-4 my-3" small v-if="collections">mdi-box-shadow</v-icon>Areas
      <v-btn icon v-if="currentUser" class="float-right mr-5 float-btn"
              title="Create new area"
              small
              @click="newCollectionDialog = true">
      <v-icon color="primary">mdi-plus</v-icon>
      </v-btn>
      </div>
      <div v-if="collections">
      <LeftPanelOrganizer  v-for="(collectionType, index) in collections"
          :account="account" :repo="repo" :collectionID="collectionID"
          :key="collectionType['@dbid']" :ref="collectionType['@dbid']+'left'" :visible="false" :item="collectionType" @OnswitchCollection="switchCollection" @OnExpandCollection="collapseOtherCollection" :perspective="perspective" :addnew="true" @add="onAddNewView">
        <CollectionView  :ref="collectionType['@dbid']" :repo="repo"  :collection="collectionType" :user="currentUser" @revertDrag="revertDrag" :perspective="perspective"/>
      </LeftPanelOrganizer>
      </div>
      <div v-if="channels" class="mt-2">
      <v-icon class="ma-1 ml-4 my-3" small>mdi-menu</v-icon>Channels
              <v-btn
               v-if="currentUser"
                fab
                x-small
                title="Edit Channels"
                icon
                class="float-right mr-5"
                @click="deleteChannelDialog = true"
              >
              <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
      </div>
      <div v-if="channels">
      <LeftPanelOrganizer   v-for="(collectionType, index) in channels"
        :account="account" :repo="repo" :collectionID="collectionID"
        :key="collectionType['@dbid']+collectionType._id+'_channels'" :ref="collectionType['@dbid']+'left'" :visible="false" :item="collectionType" @OnswitchCollection="switchCollection" @OnExpandCollection="collapseOtherCollection" :perspective="perspective" :addnew="false">
        <CollectionView :ref="collectionType['@dbid']" :repo="repo"  :collection="collectionType" :user="currentUser"  @revertDrag="revertDrag" :perspective="perspective"/>
      </LeftPanelOrganizer>
      </div>
      </div>
      <div class="tab1" v-show="tabIndex==1">
        <searchTab :repo="repo" ref="searchTab"/>
      </div>
      <div class="tab2" v-show="tabIndex==2">
       <NotificationTab :notifications="notifications"/>
      </div>
      <v-list dense></v-list>
      <template v-slot:append>
        <v-row no-gutters class="my-1">
          <v-col class="align-self-center">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  :src="$vuetify.theme.dark?'/img/dark.png':'/img/light.png'"
                  width="35px"
                  class="ml-4"
                  style="cursor: pointer"
                  v-bind="attrs"
                  v-on="on"
                ></v-img>
              </template>
              <span>jot version {{ manifestJSON.version }}</span>
            </v-tooltip>
          </v-col>
          <v-col class="text-center align-self-center">
            <v-btn
              title="Theme color"
              class="d-inline"
              icon
              small
              @click="$vuetify.theme.dark = !$vuetify.theme.dark"
            >
              <v-icon>mdi-invert-colors</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-center align-self-center">
            <div v-if="currentUser" class="d-inline">
              <v-menu offset-y v-if="currentUser">
                <template v-slot:activator="{ on }">
                  <v-btn icon large v-on="on">
                    <v-avatar color="orange accent-1" size="32px">
                      <AsyncAvatarImage
                        :username="currentUser.name"
                        :collection="collection"
                      />
                    </v-avatar>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="" :to="'/' + currentUser.name + '/_profile'">
                    <v-list-item-title>{{ currentUser.display }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openUserSettings()">
                    <v-list-item-title>Settings</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="logout">
                    <v-list-item-title>Sign Out</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-avatar v-else color="orange accent-1" size="32px"></v-avatar>
            </div>
            <div v-else class="d-inline">
              <v-btn text x-small @click="signIn()"> Sign In </v-btn>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
    <!--main container -->
    <div class="main-page">
      <div
        class="home"
      >
        <div class="scrollable home-scrollable">
          <v-main>
            <v-container fluid>
              <v-btn style="position:absolute;z-index:3" class="mt-3 opac-btn menu-box" v-show="!navigation.shown" icon @click.stop="showHideDrawer()"><v-icon>mdi-menu</v-icon></v-btn>
              <router-view
                :currentUser="currentUser"
                :usersView="usersView"
                :repo="repo"
                :membershipsView="membershipsView"
                :collectionMembersView="collectionMembersView"
                :viewInbox="userInboxView"
                :errorView="false"
                :selectedView.sync="selectedView"
                :selectedLayout="defaultLayout"
                :perspective="perspective"
                :collection="collection"
                :collectionID="collectionID"
                :mode="mode"
                :account="account"
                :authorization="authorization"
                :layouts="layouts"
              />
            </v-container>
          </v-main>
        </div>
      </div>
    </div>

    <SignInDialog v-model="showSignInDialog" @authenticated="onAuthentication" />
    <SignUpDialog v-model="showSignUpDialog" @authenticated="onAuthentication"  @alert="showAlert" />
    <NewCollectionDialog v-model="newCollectionDialog" :currentUser="currentUser" :repo="repo" @createdCollection="onCollectionCreated"/>
    <EditCollectionDialog v-model="editCollectionDialog" @alert="showAlert" @delete="onDeleteCollection" :collectionObj="collectionObj" :currentUser="currentUser" :repo="repo"/>
    <DeleteCollectionDialog v-model="deleteCollectionDialog" @alert="showAlert" @deleted="onCollectionDeleted" :collectionObj="collectionObj" :currentUser="currentUser" :repo="repo"/>
    <NewViewDialog v-if="newViewCollection" :key="newViewCollection['@dbid']" v-model="showNewViewDialog" @created="onViewCreated" :collection="newViewCollection" :repo="repo"/>
    <SubscriptionDialog v-model="subscriptionCollectionDialog" :accouunt="account" :collectionID="collectionID" :subscriptionCollection="subscriptionCollection"/>
    <DeleteChannelsDialog v-model="deleteChannelDialog" :channels="channels" :membershipsView="membershipsView"/>
    <QuickCaptureDialog v-if="userInboxView" :authorization="authorization" v-model="showQuickCaptureDialog" :view="userInboxView" :collection="userDenCollection"/>
    <VersionSnackbar/>
    <!-- PWA modal  -->
    <PWADialog v-model="showPWAInstall" :OSType="OSType"/>
    <v-snackbar
      v-model="authSnackBar"
      :timeout="authErrortimeout"
      top
      class="snack"
    >
      {{ authError }}
      <v-spacer />
      <v-btn icon color="white" @click="authSnackBar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <!--   User settings -->
    <UserSettings
      ref="usersettings"
      v-if="currentUser"
      v-model="settingsDialog"
      :currentUser="currentUser"
      :collection="collection"
    />
    <!--  alert snackbar -->
    <v-snackbar color="grey darken-2" top v-model="alertBar">
      {{ alertText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          icon
          v-bind="attrs"
         @click="alertBar = false"
        >
         <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!--  collection alert snackbar -->
    <v-snackbar timeout="-1" color="grey darken-2" top v-model="alertBarCollection">
      {{ alertCollectionText }}
      <v-btn icon color="white" @click="alertBarCollection = false;closeAlertBarCollection()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-overlay :value="syncInProgress" :z-index="7">
        <v-progress-circular
          :color="$vuetify.theme.dark?'orange':'blue'"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <vtoast ref="vtoast"/>
  </v-app>
</template>

<script>
import { Item, Repo, Collection, View, Auth, GridAPI } from '@/collections'
import Utility from '@/components/common/utils.js'
import AsyncAvatarImage from '@/components/AsyncAvatarImage.vue'
import UserSettings from '@/components/UserSettings.vue'
import CollectionView from '@/components/CollectionView.vue'
import { OutsideClick } from '@/directives/OutsideClick.js'
import SignInDialog from '@/components/SignInDialog.vue'
import SignUpDialog from '@/components/SignUpDialog.vue'
import NewViewDialog from '@/components/NewViewDialog.vue'
import NewCollectionDialog from '@/components/NewCollectionDialog.vue'
import EditCollectionDialog from '@/components/EditCollectionDialog.vue'
import DeleteCollectionDialog from '@/components/DeleteCollectionDialog.vue'
import DeleteChannelsDialog from '@/components/DeleteChannelsDialog.vue'
import LeftPanelOrganizer from '@/components/LeftPanelOrganizer.vue'
import PWADialog from '@/components/PWADialog.vue'
import VersionSnackbar from '@/components/VersionSnackbar.vue'
import SubscriptionDialog from '@/components/SubscriptionDialog.vue'
import SearchTab from '@/components/SearchTab.vue'
import NotificationTab from '@/components/NotificationTab.vue'
import vtoast from '@/components/common/vtoast'
import QuickCaptureDialog from '@/components/QuickCaptureDialog.vue'
const manifestJSON = require('../public/manifest.json')

export default {
  data: () => ({
    collection: null,
    views: null,
    dark: false,
    collectionID: null,
    account: null,
    defaultCollection: 'jot_info',
    defaultPerspective: 'views',
    perspective: '',
    selectedLayoutText: '',
    mode: '',
    selectedView: {},
    showSignInDialog: false,
    showSignUpDialog: false,
    showNewViewDialog: false,
    showQuickCaptureDialog: false,
    newViewCollection: null,
    defaultLayoutProp: {
      text: 'list',
      value: 'list',
      properties: {
        display: {
          fields: {
            thumbnail: false,
            assignee: false,
            checkbox: true,
            description: false
          }
        }
      }
    },
    currentUser: null,
    repo: null,
    collectionsView: null,
    membershipsView: null,
    collectionMembersView: null,
    userDenCollection: null,
    usersView: null,
    manifestJSON: manifestJSON,
    alertText: '',
    alertBar: false,
    alertBarCollection: false,
    alertCollectionText: '',
    utils: Utility,
    authErrortimeout: 10000,
    authError: '',
    authSnackBar: false,
    authorization: {
      access: false,
      update: false,
      comment: false
    },
    subscriptionCollectionDialog: false,
    subscriptionCollection: null,
    selectedItem: '',
    newCollectionDialog: false,
    editCollectionDialog: false,
    deleteCollectionDialog: false,
    deleteChannelDialog: false,
    collectionObj: {},
    userInboxView: null,
    showPWAInstall: false,
    OSType: 'unknown',
    settingsDialog: false,
    tabIndex: 0,
    notifications: [],
    navigation: {
      shown: false,
      width: 250,
      minWidth: 250,
      maxWidth: 650,
      borderSize: 1
    },
    repoError: false,
    syncInProgress: false
  }),
  props: {},
  directives: {
    OutsideClick
  },
  components: {
    AsyncAvatarImage,
    UserSettings,
    CollectionView,
    SignInDialog,
    SignUpDialog,
    NewViewDialog,
    LeftPanelOrganizer,
    PWADialog,
    VersionSnackbar,
    SubscriptionDialog,
    NewCollectionDialog,
    EditCollectionDialog,
    DeleteCollectionDialog,
    DeleteChannelsDialog,
    SearchTab,
    NotificationTab,
    vtoast,
    QuickCaptureDialog
  },
  computed: {
    selectedCollectionName () {
      if (this.collectionsView) {
        var collection = null
        collection = this.collectionsView.items.find(
          (x) => x['@dbid'] == this.utils.getdbid(this.account, this.collectionID)
        )
        if (collection) {
          return collection.title
        }
      }
      return 'collection'
    },
    selectedChannelName () {
      if (this.membershipsView) {
        var collection = null
        collection = this.membershipsView.items.find(
          (x) => x['@dbid'] == this.utils.getdbid(this.account, this.collectionID)
        )
        if (collection) {
          return collection['@dbid']
        }
      }
      return 'Channels'
    },
    collections () {
      console.log('collections() collectionsView', this.collectionsView)
      return this.collectionsView ? this.collectionsView.items : null
    },
    channels () {
      console.log('collections() membershipsView', this.membershipsView)
      if (this.membershipsView) {
        // add jot info by default
        var currentUser = this.currentUser
        var info_membership = {
          '@dbid': 'jot_info',
          'account': 'jot',
          'createdBy': currentUser.name,
          'name': currentUser.name,
          'status': 'bookmark',
          'title': 'info',
          'type': 'membership',
          'updatedBy': currentUser.name,
          '_id': '.membership.' + currentUser.name + '.jot_hello_static'
        }
        var membershipItems = this.membershipsView.items.filter(x => x.status == 'member' || x.status == 'bookmark')
        var staticHelloExists = membershipItems.some(x => x._id === info_membership._id)
        if (!staticHelloExists) {
          membershipItems.unshift(info_membership)
        }
        return membershipItems
      }

      return null
    },
    stockViews () {
      return View.stockViews
    },
    layouts: function () {
      return (
        this.selectedView &&
        this.selectedView.layouts &&
        this.selectedView.layouts.length > 0 &&
        this.selectedView.layouts.map((o) => ({
          text: o.type,
          value: o.type,
          properties: o.properties
        }))
      )
    },
    defaultLayout: function () {
      if (
        this.selectedView &&
        this.selectedView.default &&
        this.selectedLayoutText === ''
      ) {
        // return default layout properties
        var foundView = this.foundLayoutProp(this.selectedView.default)
        if (foundView) {
          return foundView
        } else {
          // if no default layout set to list
          return this.defaultLayoutProp
        }
      } else if (this.selectedLayoutText === '') {
        // if no default layout set to list
        return this.defaultLayoutProp
      } else {
        return this.foundLayoutProp(this.selectedLayoutText)
      }
    },
    isCollectionOpen () {
      if (this.collection !== null && this.collection.isOpen) return 1
      return 0
    },
    direction () {
      return this.navigation.shown === false ? 'Open' : 'Closed'
    }
  },

  async created () {
    console.log('App created')

    document.addEventListener('click', this.documentClick)
    console.log(this.getMobileOperatingSystem())
    console.log(this.isPwa())
    try {
      await this.authinit()
    } catch (err) {
      console.trace('error in auth init', err)
    }
    let drawerState = localStorage.getItem('drawerState')
    this.navigation.shown = drawerState ? JSON.parse(drawerState) : false
    this.$root.vtoast = this.$refs.vtoast
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.keyListener)
  },
  mounted () {
    // navigation drawer
    this.setBorderWidth()
    this.setEvents()
    // this code check system theme and change app theme accordingly
    this.$vuetify.theme.dark = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches
    document.addEventListener('keydown', this.keyListener)
    document.ontouchmove = function (event) {
      event.preventDefault()
    }
    this.removeIOSRubberEffect(document.querySelector('.scrollable'))
  },
  methods: {
    // key shortcut "/" for hide show navigation drawer
    keyListener (e) {
      if (e.key === '/' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault() // present browser event trigger
        this.showHideDrawer()
      } else if (e.key === 'Escape') {
        this.tabIndex = 0
      } else if (e.key === '/' && typeof e.target.type === 'undefined') {
        this.tabIndex = 1
        this.$refs.searchTab.resetSearch()
      } else if (e.key === 'i' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault() // present browser event trigger
        this.showQuickCaptureDialog = true
      }
    },
    isPwa () {
      return ['fullscreen', 'standalone', 'minimal-ui'].some(
        (displayMode) =>
          window.matchMedia('(display-mode: ' + displayMode + ')').matches
      )
    },
    getMobileOperatingSystem () {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }

      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
                  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
                  !window.MSStream
      if (isIOS) {
        return 'iOS'
      }

      return 'unknown'
    },
    removeIOSRubberEffect (element) {
      element.addEventListener('touchstart', function (event) {
        var top = element.scrollTop
        var totalScroll = element.scrollHeight
        var currentScroll = top + element.offsetHeight
        if (top === 0) {
          element.scrollTop = 1
        } else if (currentScroll === totalScroll) {
          element.scrollTop = top - 1
        }
      })
    },
    /**
     * found view propertiers in layouts
     */
    foundLayoutProp (layout) {
      if (typeof this.layouts !== 'undefined' && this.layouts.length > 0) { return this.layouts.find((a) => a.text == layout) }
      return this.defaultLayoutProp
    },
    async switchCollection (collectionDoc) {
      console.log('switchCollection', collectionDoc)
      if (collectionDoc.hasOwnProperty('@dbid')) {
        if (this.collection.collectionID != collectionDoc['@dbid'] && this.collection.isOpen)// if it is not same collection then close old collection
        { await this.collection.close() }
        this.setCollection(collectionDoc['@dbid'])
        if (this.collection.collectionID == collectionDoc['@dbid'])// if it is same collection then repon so it load default page
        { await this.openCollection() }
        this.routePage(this.account, this.collectionID, this.perspective == 'views' && this.perspective !== '_inbox' ? this.perspective : '')
      }
      localStorage.removeItem('selected')
    },

    // wait until the repository is done
    async repoStatus (status = 'ready', waiter = 'unknown') {
      console.log('---here')
      if (!this.repo) {
        console.debug(waiter, 'is waiting for repo status to be', status)
        while (!this.repo) {
          //          console.debug("Repo open waiting...");
          if (this.repoError) {
            console.debug('Repo open error exit while loop...')
            break
          }
          await new Promise((resolve) => setTimeout(resolve, 100))
        }
        console.debug(waiter, 'resuming -- repo is now', status)
      }
    },

    /*
      very important function
      opens a collection
      it initialtes views ,authorization access
    */
    async openCollection () {
      const spinnerTimeout = setTimeout(() => {
        this.syncInProgress = true
      }, 1000)
      // wait for repository to initialize before opening collections
      await this.repoStatus('ready', 'openCollection')
      // show sign in dialog if nvt param is there and user is not logged in
      if (this.$route.query.nvt && this.account && this.collectionID && !this.currentUser) {
        this.showSignUpDialog = true
        return
      }

      var self = this
      self.views = null
      const collectionID = self.account + '_' + self.collectionID
      console.debug('openCollection', collectionID, this.repo)
      // close collection before create new one
      if (self.collection !== null && self.collection.isOpen) {
        await self.collection.close()
      }
      try {
        // it exists so open the collection
        self.collection = new Collection(this.repo, collectionID)
        window.collection = self.collection // DEBUG
        console.debug('Opening Collection...', self.collection)
        const open = await self.collection.open()
        console.debug('Is Open Collection', open.isOpen)
        self.authorization.update = !!self.collection.authorizes('update')
        self.authorization.access = !!self.collection.authorizes('access')
        self.authorization.comment = !!self.collection.authorizes('comment')
        // reroute to default page
        console.log('collection info', this.collection.info)
        if (this.collection.isOpen) {
          this.views = await new View(this.collection).open('views')
          this.selectedView = this.views.itemMap[this.perspective]
        }
        // if hit the url with perspective then get item info
        // Someone tries to open url with perspective, so on change of perspective
        if (this.perspective && this.perspective !== 'views' && this.perspective !== '_inbox') {
          var item = await this.repo.get(this.perspective)
          console.log('getting item', item)
        }
        console.log('selectedView ', this.selectedView)
        if (this.collection.info && this.perspective !== '_inbox') {
          console.log('Old perspective', this.perspective)
          // if get item info then do not change perspective
          // if no perspective and no item info then open default perspective
          if (this.collection.info.hasOwnProperty('defaultPage') &&
            (this.perspective !== this.collection.info.defaultPage && !this.selectedView) &&
              (!item || (item && this.collection.info['@dbid'] != item['@dbid']))) {
            console.debug('Switch to Collection default page ' + this.collection.info.defaultPage)
            this.perspective = this.collection.info.defaultPage
            this.defaultPerspective = this.perspective
          } else if (!this.selectedView && (!this.$route.params.perspective || this.perspective == 'views') || (item && this.collection.info['@dbid'] != item['@dbid'])) {
            // if no default perspectivethen open views instead
            this.perspective = 'views'
            this.defaultPerspective = 'views'
          }
          console.log('New perspective', this.perspective)
          this.collectionMembersView = await new View(this.repo).open({
            of: { type: 'membership', '@dbid': this.collection.info['@dbid'] }
          })
          // open selected perspective
          console.debug('collectionMembersView', this.collectionMembersView)
        }
      } catch (err) {
        console.log("Couldn't open collection", self.collection, 'as user', self.account, err)
        if (!self.account && err.status == 403) {
          this.alertText = `Access to ${collectionID} requires you to Sign In.`
          this.alertBar = true
          this.signIn()
        } else {
          this.alertCollectionText = `You are not authorized to access ${collectionID}`
          this.alertBarCollection = true
        }
      } finally {
        clearTimeout(spinnerTimeout)
        this.syncInProgress = false
      }
    },
    closeAlertBarCollection () {
      localStorage.removeItem('lastVisitedUrl')
      this.$router.push('/')
      this.authorization = {
        update: false,
        comment: false,
        access: false
      }
    },
    switchLayout (layout) {
      localStorage.removeItem('selected')
      this.$router.push({
        name: 'list',
        params: {
          account: this.account,
          collectionID: this.collectionID,
          perspective: this.perspective
        },
        query: { layout: layout.text }
      })
    },
    onAddNewView (item) {
      this.showNewViewDialog = true
      this.newViewCollection = item
    },
    // New view created redirect to it
    onViewCreated (view) {
      this.selectedView = {}
      var selectedCollection = this.utils.splitdbid(this.newViewCollection['@dbid'])
      this.$router.push({
        name: 'list',
        params: {
          account: selectedCollection[0],
          collectionID: selectedCollection[1],
          perspective: view.id
        }
      })
      this.$refs[this.newViewCollection['@dbid'] + 'left'][0].visible = true
      this.collapseOtherCollection(this.newViewCollection)
      this.newViewCollection = null
      localStorage.removeItem('selected')
    },
    collapseOtherCollection (collection) {
      for (var i = 0; i < this.collections.length; i++) {
        var element = this.collections[i]
        this.$refs[element['@dbid'] + 'left'][0].visible = element['@dbid'] === collection['@dbid']
      }
      for (var i = 0; i < this.channels.length; i++) {
        var element = this.channels[i]
        this.$refs[element['@dbid'] + 'left'][0].visible = element['@dbid'] === collection['@dbid']
      }
    },
    /**
     * Called when authentication is successful / complete to initialize Jot.
     */
    async onAuthentication () {
      this.removeQueryString()
      window.location.reload()
    },

    /**
     * Sets up a variety of information related to the apparent current user.
     *
     * this.repo          repository (Repo) opened with current cedentials or anonymous
     * this.currentUser   user profile document for current user, or null for anonymous
     * this.membershipsView   a View of the current membership documents for this user
     * this.collectionsView   a View of the collections this user owns
     * this.userDenCollection
     * this.usersView
     *
     * Uses the current "local storage" auth info (via Auth.get()) to (re)-initialize repo.
     * close and terminate and current repo if one existed.  Set currentUser to the
     * user object for the currently logged in account, or none if not logged in.
     *
     * Because re-opening a repo is an expensive operation, this makes sure we really
     * need to re-open it.  If it is open and the id is the current id, then we
     * don't re-open it.
     *
     * Note that this function does a forced sync of the basic info for a user and their
     * memberships/owned repositories, so it can take a WHILE on a slow collection.
     *
     */

    async authinit () {
      var self = this
      //  helper to clear current auth state
      function clearAuthState () {
        self.repo = null
        self.currentUser = null
        self.membershipsView = null
        self.collectionsView = null
        self.userDenCollection = null
        self.userInboxView = null
        self.usersView = null
        self.notifications = []
      }

      // attempt contact the api to see if we can tell who the current user is bsaed on
      // cookie.
      const result = await GridAPI.v1('GET', '/access/session')
      console.log('server session lookup found ', result)

      var authSpec

      if (result.status != 200) {
        console.log('no session found, got response ', result.status, 'assuming anonymous')
        Auth.clear()
      } else {
        authSpec = JSON.parse(result.responseText)
        console.log('session found', authSpec)
        Auth.set(authSpec)
      }

      console.log('authinit authspec=', authSpec)
      if (!authSpec) {
        if (this.repo && this.repo.isOpen && this.repo.userID === null) {
          console.warn('authinit asked to re-open anonymous, ignoring')
          return
        }
        console.debug('authinit setting up anonmous repo and null currentUser')
        if (this.repo) {
          let oldRepo = this.repo
          clearAuthState()
          await oldRepo.close()
        }
        this.repo = await new Repo('https://sync.jot.it/jot1').open()
        this.navigation.shown = false
        this.getNotifications()
        return
      }

      let userID = authSpec.name
      console.debug('authinit userID', userID)
      if (this.repo && this.repo.isOpen) {
        if (this.repo.userID === userID) {
          console.warn('authinit called with same user - ignoring')
          return
        }
        console.debug('closing existing repo for user:', userID)
        let oldRepo = this.repo
        clearAuthState()
        await oldRepo.close()
      }

      // setup a new repo object in 'repo', not assigning to this.repo until
      // everything is setup and all async completes.
      const initialChannels = [
        '.ua/' + userID,
        '.ma/' + userID,
        '.cl/' + Collection.denCollectionIDFor(userID),
        '!'
      ]
      console.debug('auth opening repo for user', userID)
      let repo = new Repo('https://sync.jot.it/jot1', userID)
      try {
        await repo.open(initialChannels)
        this.repoError = false
      } catch (err) {
        this.repoError = true
        console.log('Error opening repo for user:', userID, err)
        throw err
      }
      console.debug(
        'authinit opened repo',
        repo,
        'with channels',
        initialChannels
      )
      // setup a bunch of context for the current user / repo
      let currentUser = await repo.get(`org.couchdb.user:${userID}`)
      console.debug('authinit currentUser', currentUser)
      let membershipsView = await new View(repo).open({
        of: { type: 'membership', name: userID }
      })
      console.debug('authinit membershipsView', membershipsView)

      let collectionsView = await new View(repo).open({
        of: { type: 'collection', account: userID }
      })
      console.debug('authinit collectionsView', collectionsView)

      let userDenCollection = await new Collection(repo, '$den').open()
      console.debug('authinit userDenCollection', userDenCollection)

      let userInboxView = await new View(userDenCollection).open({
        of: { container: 'inbox' },
        orderable: 'inbox'
      })
      console.debug('authinit userInboxView', userInboxView)

      let usersView = await new View(repo).open({
        of: { type: 'user' }
      })
      console.debug('authinit usersView', usersView)

      console.debug('authinit setting final state for repo', repo)

      // no exceptions and we're done with async, so setup 'this' auth state
      this.repo = repo
      this.currentUser = currentUser
      this.membershipsView = membershipsView
      this.collectionsView = collectionsView
      this.userDenCollection = userDenCollection
      this.userInboxView = userInboxView
      this.usersView = usersView
      this.navigation.shown = !!currentUser
      this.getNotifications()
    },
    async getNotifications () {
      // notification
      let notificationData = await this.repo.db.changes({
        limit: 50,
        descending: true,
        include_docs: true
      })

      console.debug('Notifications', notificationData)
      this.notifications = notificationData.results
    },

    async logout () {
      const result = await GridAPI.v1('DELETE', '/access/session')
      console.log('DELETE session (using api)', result)
      if (result.status != 200) {
        console.error('DELETE returned unexpected status, leaving session', result.status)
        return
      }
      // DELETE of session appeared to work, so log user out
      this.removeQueryString()
      localStorage.removeItem('lastVisitedUrl')
      window.location.href = '/'
    },

    // edit title for existing collection in menu
    openSubscriptionCollection (collection) {
      this.subscriptionCollectionDialog = true
      // edit collection title
      this.subscriptionCollection = collection
    },
    handleDragover (destinationView, movedItem, event) {
      if (typeof movedItem === 'undefined') return
      if (destinationView === 'inbox') {
        this.selectedItem = 'inbox'
      } else {
        this.selectedItem = destinationView._id
      }
    },

    async handleDrop (destItem, movedItem, event) {
      if (typeof movedItem === 'undefined') return
      if (!movedItem.source) return
      const sourceItem = movedItem.source
      const sourceCollectionID = sourceItem['@dbid']
      const destCollectionID = (destItem === 'inbox'
        ? this.userInboxView.collection.collectionID : destItem['@dbid'])

      console.debug('Moved Item', movedItem)
      console.debug('Dest Item', destItem)
      console.debug('Event', event)

      // dropping a view on organizer bar from workspace is NYI
      if (sourceItem.type == 'view') {
        this.alertBar = true
        this.alertText = 'Not implemented yet -- drag views in sidewbar instead'
        return
      }

      // drop on inbox is special, because we already have the view instatiated
      if (destItem === 'inbox' && sourceCollectionID === destCollectionID) {
        this.alertBar = true
        this.alertText = 'These items are already in the Inbox'
        return
      }

      // Always OK to drop in same collection (no confirm or child move)
      if (sourceCollectionID == destCollectionID) {
        const destView = await new View(this.collection).open(destItem)
        await destView.drop(sourceItem)
        await destView.close()
        return
      }

      // Don't allow dropping on non-container views (yet at least)
      if (!(destItem === 'inbox' || Item.isContainer(destItem))) {
        this.alertBar = true
        this.alertText = "Can't drop on non-container view in different collection (at least not yet)"
        return
      }

      // Move item into a container in a different collection
      const children = await this.repo.children(sourceItem._id)
      const childPrompt = children.length > 0
        ? ` (and ${children.length} contained items)` : ''
      const prompt =
        `Moving this item ${childPrompt} to a different collection 
        (that may have different permissions).
        <br/><br/>
        <strong>Are you sure?</strong>`
      const res = await this.$confirm(prompt, {
        title: `Move ${children.length + 1} Items`,
        buttonSaveText: `Move Items`,
        buttonDiscardText: ''
      })
      if (res != 1) { // user said no
        return
      }
      const itemsToMove = children.concat(sourceItem)
      let i
      for (i in itemsToMove) {
        itemsToMove[i]['@dbid'] = destCollectionID
        itemsToMove[i].container = destItem === 'inbox' ? destItem : destItem._id
      }
      await this.repo.db.bulkDocs(itemsToMove)
      console.debug('moved items using bulkDocs')
      this.selectedItem = ''
    },
    async openEditCollection (collection) {
      let collectionDoc = await this.repo.get(
        Collection.docIDFor(collection['@dbid'])
      )
      this.collectionObj.collectionurl =
        window.location.origin + '/' + collectionDoc['@dbid'].replace('_', '/')
      this.collectionObj.title = collectionDoc.title
      this.collectionObj.id = collectionDoc['@dbid']
      this.collectionObj.isPrivate = collectionDoc.private
      this.editCollectionDialog = true
    },
    showAlert (alert) {
      this.alertBar = true
      this.alertText = alert
    },
    onCollectionCreated (collection) {
      this.switchCollection(collection)
    },
    onDeleteCollection () {
      this.deleteCollectionDialog = true
    },
    onCollectionDeleted () {
      this.editCollectionDialog = false
      this.deleteCollectionDialog = false
    },
    openUserSettings () {
      this.settingsDialog = true
    },
    gotoOrganizerPanel () {
      this.tabIndex = 0
    },
    gotoExpandItem (item, key) {
      console.debug('item selected', item)
      let idSegments = this.utils.splitdbid(item['@dbid'])
      this.routePage(idSegments[0], idSegments[1], item[key])
      this.$refs[item['@dbid'] + 'left'][0].visible = true
      this.tabIndex = 0
    },
    gotoItem (item, key) {
      console.debug('item selected', item)
      let idSegments = this.utils.splitdbid(item['@dbid'])
      this.routePage(idSegments[0], idSegments[1], item[key])
    },
    setCollection (dbid) {
      var idSegments = this.utils.splitdbid(dbid)
      this.account = idSegments[0]
      this.collectionID = idSegments[1]
    },
    routePage (account, collectionID, perspective = '') {
      var param = { account: account, collectionID: collectionID, perspective: perspective }
      if (perspective == '') {
        delete param.perspective
      }
      this.$router.push({
        name: 'list',
        params: param
      })
    },
    // navigation drawer
    setBorderWidth () {
      let i = this.$refs.drawer.$el.querySelector(
        '.v-navigation-drawer__border'
      )
      i.style.width = this.navigation.borderSize + 'px'
      i.style.cursor = 'ew-resize'
    },
    setEvents () {
      var self = this
      const minSize = this.navigation.borderSize
      const el = this.$refs.drawer.$el
      const drawerBorder = el.querySelector('.v-navigation-drawer__border')
      const vm = this
      const direction = el.classList.contains('v-navigation-drawer--right')
        ? 'right'
        : 'left'

      function resize (e) {
        document.body.style.cursor = 'ew-resize'
        let f =
          direction === 'right'
            ? document.body.scrollWidth - e.clientX
            : e.clientX
        if (f <= self.navigation.minWidth || f > self.navigation.maxWidth) return
        el.style.width = f + 'px'
      }

      drawerBorder.addEventListener(
        'mousedown',
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = 'initial'
            document.addEventListener('mousemove', resize, false)
          }
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.style.transition = ''
          this.navigation.width = el.style.width
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    },
    signIn () {
      this.showSignInDialog = true
    },
    revertDrag (element) {
      var collectionFromRef = this.$refs[element['@dbid']][0]
      var viewitems = collectionFromRef.viewItems
      var oldindex = collectionFromRef.oldIndex
      viewitems.splice(oldindex, 0, element)
    },
    showHideDrawer () {
      this.navigation.shown = !this.navigation.shown
      localStorage.setItem('drawerState', this.navigation.shown)
    },
    removeQueryString () {
      const query = Object.assign({}, this.$route.query)
      delete query.nvt
      this.$router.replace({ query })
    }
  },
  watch: {
    '$vuetify.theme.dark': function (newVal, oldVal) {
      console.log(newVal)
      if (newVal) {
        document.getElementsByTagName('body')[0].setAttribute('data-theme', 'dark')
      } else {
        document.getElementsByTagName('body')[0].setAttribute('data-theme', 'light')
      }
    },
    // Watch on change in url first param collectionID  means collection title,
    // if it changed then close old collection and open new collection
    collectionID (newVal, oldVal) {
      if (newVal != null && newVal !== oldVal) {
        this.openCollection()
      }
    },
    perspective (newVal, oldVal) {
      if (newVal != null && newVal !== oldVal) {
        this.selectedView = this.views && this.views.itemMap[this.perspective] || {}
      }
    },
    $route (to, from) {
      this.alertBarCollection = false
      this.alertCollectionText = ''
      var path = to.fullPath
      // if (window.matchMedia("(display-mode: standalone)").matches) {
      if (path == '/') {
        let lastVisitedUrl = localStorage.getItem('lastVisitedUrl')
        if (lastVisitedUrl !== null && lastVisitedUrl != '/') {
          this.$router.push(lastVisitedUrl)
          return
        }
        // show pwa dialog only once
        this.OSType = this.getMobileOperatingSystem()
        let pwaDialog = localStorage.getItem('pwaDialog')
        if (!this.isPwa() && this.OSType !== 'unknown' && pwaDialog === null) {
          this.showPWAInstall = true
          localStorage.setItem('pwaDialog', true)
        }
      }
      localStorage.setItem('lastVisitedUrl', to.fullPath)
      if (to.params.account !== undefined) {
        this.account = to.params.account
      }
      if (to.params.collectionID !== undefined) {
        this.collectionID = to.params.collectionID
      } else {
        this.setCollection(this.defaultCollection)
      }
      if (to.params.perspective !== undefined) {
        this.perspective = to.params.perspective
      } else {
        // if no perspective set to url and collection already opened then open default page ot jusy blank, openCollection() handels which page needs to open
        var defaultPage = this.collection !== null && this.collection.info && this.collection.info.hasOwnProperty('defaultPage') ? this.collection.info.defaultPage : ''
        this.perspective = defaultPage
      }

      if (to.params.mode !== undefined && to.params.mode == 'new' && this.perspective != 'views') {
        this.mode = to.params.mode
      } else if (
        to.params.mode !== undefined &&
        to.params.mode !== 'new' &&
        this.perspective === '_inbox'
      ) {
        this.mode = to.params.mode
      } else {
        this.mode = ''
      }

      if (to.query.layout) {
        this.selectedLayoutText = to.query.layout
      } else {
        this.selectedLayoutText = ''
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
.float-search {
  position: fixed;
  top: 47px;
  left: 0px;
  width: 100vw;
  background: #fff;
}
.item-icon {
  cursor: move;
}
.ghost-card {
  opacity: 0.1;
  background-color: rgb(141, 142, 145);
}
.sortable-ghost {
  opacity: 0.1;
  cursor: help !important;
}
.theme--dark.v-list-item:focus::before {
  opacity: 0 !important;
}
.theme--light.sortable-chosen {
  opacity: 0.1;
  background-color: rgb(204, 204, 204);
}
.theme--dark.sortable-chosen {
  opacity: 0.1;
  background-color: rgb(61, 61, 61);
}

.viewlist.theme--light.v-list-item:hover:before {
  opacity: 0;
}
.viewlist.theme--dark.v-list-item:hover:before {
  opacity: 0;
}
.allowed {
  border-color: #aaa;
  background: #ccc;
}
.collection .v-list-item .v-list-item__title {
  line-height: 3 !important;
}
.collection {
  max-height: 90vh;
  overflow-y: auto;
}
.v-progress-linear {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
.float-btn{
  margin-top:5px;
}
.menu-box{
  position: absolute;
  top:-5px;
}
</style>
