<template>
  <v-dialog
    eager
    :scrollable="false"
    @input="change"
    value="2"
    :max-width="width"
    :persistent="persistent"
    @keydown.esc="change(1)"
  >
    <v-row no-gutters class="carousel">
      <v-card tile style="min-height:350px;width:100%">
        <GalleryPlaceholder
          :item="item"
          height="auto"
          thumbnail
          :thumbSize="{ width: 500, height: 350 }"
          :key="'imagepreviewPlaceholder_' + item._id"
          :itemKey="'imagepreviewPlaceholderItem_' + item._rev"
        ></GalleryPlaceholder>
      </v-card>
    </v-row>
    <v-card tile no-gutters>
      <div>
        <v-divider></v-divider>
        <InlineComment
          :authorization="authorization"
          :collection="view.collection"
          ref="comment"
          :item="item"
          :key="'imagepreview_comment' + item._rev"
          v-if="item._id"
          class="pt-2 px-2"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import InlineComment from '@/components/InlineComment.vue'
import GalleryPlaceholder from '@/components/GalleryPlaceholder.vue'
import {
  VCard,
  VCardActions,
  VCardText,
  VDialog,
  VIcon,
  VToolbar,
  VToolbarTitle,
  VSpacer,
  VBtn
} from 'vuetify/lib'
export default {
  components: {
    VCard,
    VCardActions,
    VCardText,
    VDialog,
    VIcon,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VBtn,
    InlineComment,
    GalleryPlaceholder
  },
  props: {
    persistent: Boolean,
    title: {
      type: String
    },
    width: {
      type: Number,
      default: 500
    },
    view: {
      type: Object
    },
    item: {
      type: Object
    },
    collection: {
      type: Object
    },
    authorization: {
      type: Object
    }
  },
  data () {
    return {
      value: false
    }
  },
  mounted () {
    document.addEventListener('keyup', this.onEnterPressed)
  },
  destroyed () {
    document.removeEventListener('keyup', this.onEnterPressed)
  },
  methods: {
    onEnterPressed (e) {
      if (e.keyCode === 13) {
        e.stopPropagation()
        this.choose(1)
      }
    },
    choose (value) {
      this.$emit('result', value)
      this.value = value
      this.$destroy()
    },
    change (res) {
      this.$destroy()
    }
  }
}
</script>
