<template>
  <div>
    <div v-if="sizeCheck">
      <v-radio-group
        v-model="fileOption.option"
        row
        @change="updateSelection"
        class="fileoption"
      >
        <v-radio
          :label="fileinfo('Small', fileOption.small)"
          value="small"
        ></v-radio>
        <v-radio
          :label="fileinfo('Medium', fileOption.medium)"
          value="medium"
        ></v-radio>
        <v-radio
          :label="fileinfo('Original', fileOption.file)"
          value="original"
        ></v-radio>
      </v-radio-group>
    </div>
    <div v-else>
      <span class="mr-2">{{ fileinfo("Small", fileOption.small) }}</span>
      <span class="mr-2">{{ fileinfo("Medium", fileOption.medium) }}</span>
      <span class="mr-2">{{ fileinfo("Original", fileOption.file) }}</span>
    </div>
  </div>
</template>
<script>
import imageFileToBase64 from '@/components/common/imgBase64'
import Utils from '@/components/common/utils.js'
export default {
  name: 'FileOption',
  data: function () {
    return {
      utils: Utils,
      fileOption: {
        option: 'original',
        small: null,
        medium: null,
        file: null
      }
    }
  },
  props: {
    sizeCheck: {
      type: Boolean,
      default: true
    },
    fileData: File,
    index: Number
  },
  mounted () {
    this.generateFileOPtions()
  },
  computed: {},
  methods: {
    generateFileOPtions () {
      this.fileOption.file = this.fileData
      this.getOptimizedImageFile(this.fileData, 1200, 0.6, 'medium')
    },
    updateSelection () {
      this.$emit('option', this.fileOption.option, this.index)
    },
    getOptimizedImageFile (file, width, quality, size) {
      console.debug(
        "Generate optimized image file name '" +
          file.name +
          "' quality '" +
          quality +
          "' size '" +
          size +
          "'"
      )

      var self = this

      imageFileToBase64(file, width, 0, quality).then(function (thumbnail) {
        self.utils
          .urltoFile(thumbnail, file.name, file.type)
          .then(function (file) {
            self.fileOption[size] = file
          })
      })
    },
    fileinfo (text, data) {
      if (data && typeof data.size !== 'undefined') {
        return text + ' (' + this.utils.filesize(data.size) + ')'
      }
      return text + ' (...)'
    }
  },
  components: {},
  watch: {
    'fileOption.small': function (newVal, oldVal) {
      if (newVal !== null) this.$emit('small', newVal, this.index)
    },
    'fileOption.medium': function (newVal, oldVal) {
      if (newVal !== null) {
        this.$emit('medium', newVal, this.index)
        this.getOptimizedImageFile(newVal, 600, 0.5, 'small')
      }
    }
  }
}
</script>
<style scoped>
.fileoption >>> label {
  font-size: 14px !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .fileoption.v-input--selection-controls {
    margin-top: 5px !important;
  }
}
</style>
