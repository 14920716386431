<template>
  <v-dialog v-model="show" persistent max-width="350">
    <v-card v-if="phase == 'confirm'">
      <v-card-title class="title"
        >Sign Up<v-spacer />
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div>You should have just received an access sent via {{ via }}.</div>
        <div>Enter that code to complete signing in.</div>
      </v-card-text>
      <v-container class="px-4">
        <v-row no-gutters class="px-2">
          <v-col cols="12">
            <v-text-field
              required
              v-model="user.username"
              label="Username"
              :error-messages="userErrors['username']"
              autofocus
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              required
              v-model="user.display"
              label="Display name"
              :error-messages="userErrors['display']"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="user.code"
              label="Access code"
              type="number"
              required
              :error-messages="userErrors['code']"
              autocomplete="one-time-code"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel">Cancel</v-btn>
        <v-btn class="mr-34" color="primary" @click="confirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { GridAPI, Auth } from '@/collections'

export default {
  props: {
    value: Boolean
  },
  computed: {
    show: {
      get () {
        if (this.value) {
          this.checkInvtationCode()
        }
        return this.value
      },
      set (value) {
        if (value) {
          this.phase = 'nvt'
        }
        this.$emit('input', value)
      }
    }
  },

  data: () => ({
    userErrors: { username: '', display: '', code: '' },
    user: { username: '', display: '', code: '' },
    errors: '',
    requestCode: '',
    via: '',
    phase: 'nvt'
  }),
  methods: {
    async checkInvtationCode () {
      if (this.$route.query.nvt) {
        var nvt = this.$route.query.nvt
        const req = {
          invitation: nvt,
          origin: window.location.origin
        }
        const result = await GridAPI.v1('POST', '/access/accept', req)
        console.log(result)
        if (result.status != 201) {
          const errorMap = {
            403: 'the provided code is expired or invalid',
            429: 'too many attempts, please try later'
          }
          var error = errorMap[result.status] || result.statusText
          this.$emit('alert', error)
          this.cancel()
        } else {
          const resp = JSON.parse(result.responseText)
          this.requestCode = resp.request
          this.via = resp.via
          this.phase = 'confirm'
        }
      } else {
        this.$emit('alert', 'No invitaion found')
        this.cancel()
      }
    },
    async confirm () {
      this.errors = ''
      this.userErrors = { username: '', display: '', code: '' }
      if (this.user.username == '' || !isValidUserName(this.user.username)) {
        this.userErrors.username = 'Invalid username'
        return
      } else if (await this.checkUsernameExists(this.user.username)) {
        this.userErrors.username = 'Username already taken'
      } else if (this.user.display == '') {
        this.userErrors.display = 'Display name is required'
        return
      } else if (this.user.code == '') {
        this.userErrors.code = 'Code is required'
        return
      }
      const req = {
        code: this.user.code,
        request: this.requestCode,
        user: this.user.username,
        display: this.user.display
      }
      const result = await GridAPI.v1('POST', '/access/join', req)
      console.log(result)
      if (result.status != 201) {
        const errorMap = {
          403: 'the provided code is expired or invalid',
          429: 'too many attempts, please try later'
        }
        this.userErrors.code = errorMap[result.status] || result.statusText
      } else {
        const resp = JSON.parse(result.responseText)
        console.log(resp)
        Auth.set({ name: this.user.username })
        this.$emit('authenticated')
        this.show = false
        this.reset()
      }
    },
    async checkUsernameExists (username) {
      const result = await GridAPI.v1('GET', '/users/' + username)
      console.log(result)
      if (result.status == 200) {
        return true
      }
      return false
    },
    cancel () {
      localStorage.removeItem('lastVisitedUrl')
      this.$router.push('/')
      this.show = false
      this.reset()
    },
    reset () {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    removeQueryString () {
      const query = Object.assign({}, this.$route.query)
      delete query.nvt
      this.$router.replace({ query })
    }
  },

  created () {
    const escHandler = (e) => {
      if (e.key === 'Escape' && this.show) {
        this.cancel()
      }
    }
    document.addEventListener('keydown', escHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escHandler)
    })
  }
}

function isValidUserName (s) {
  // /^[a-zA-Z0-9]+$/ -- doesn't enforce starting
  return matchesRe(s, /^[a-z][a-z0-9]+$/)
}

function matchesRe (s, re) {
  return re.test(String(s).toLowerCase())
}
</script>
